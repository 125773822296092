import React, {useState} from "react";
import ArticleList from "./ArticleList";
import Downloads from "./Downloads";
import Translator from "./Translator";

export default function ResultTab({className, ...props}) {
	const [openTab, setOpenTab] = useState(1);
	
	return (
		<div className={className}>
			<div className="flex flex-wrap">
				<div className="w-full">
					<ul className="flex mb-12 list-none flex-wrap flex-row border-b border-b-disabled-grey" role="tablist">
						<li className="mr-14 last:mr-0">
							<a className={"font-medium text-32 block -mb-[1px] " + (openTab === 1 ? "text-white border-b border-b-white" : "text-disabled-grey")}
								data-toggle="tab" href="#link1" role="tablist"
								onClick={e => {
									e.preventDefault();
									setOpenTab(1);
								}}
							>
								<Translator id="overview.tabs.downloads" />
							</a>
						</li>
						<li className="mr-14 last:mr-0">
							<a className={"font-medium text-32 block -mb-[1px] " + (openTab === 2 ? "text-white border-b border-b-white" : "text-disabled-grey")}
								data-toggle="tab" href="#link2" role="tablist"
								onClick={e => {
									e.preventDefault();
									setOpenTab(2);
								}}
							>
								<Translator id="overview.tabs.articles" />
							</a>
						</li>
					</ul>
					<div className="relative flex w-full mb-6">
						<div className="flex-auto">
							<div className="tab-content tab-space">
								<div className={openTab === 1 ? "block" : "hidden"} id="link1">
									<Downloads />
								</div>
								<div className={openTab === 2 ? "block" : "hidden"} id="link2">
									<ArticleList />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}