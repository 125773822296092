import React from "react";
import styled from "styled-components";

export default function Corner({x, y, height, width, border, color, rotation, elementLength, cornerOrientation, article, isSimple=false, showArticleNumber=false, ...props}) {
	return (
		<g transform={"translate(" + x + ", " + y + ")"} width={width} height={width}>
			<g transform={"rotate(" + rotation + ")"}>
				{cornerOrientation === 'R' &&
					<>
						<polygon
							stroke={border}
							fill={color}
							points={
								"0,0 " +
								width + ",0 " +
								width + "," + width + " "
								+ (width - height) + "," + width + " "
								+ (width - height) + "," + height + " "
								+ "0," + height
							}
						/>
						<g>
							{/* Check to disable article numbers on one leg of the corner element in the simplified view of the pdf */}
							{!isSimple && 
							<g>
								<line x1={0} 		y1={-height}	 	x2={0} 		y2={-height * 2} 	stroke="#000000" strokeWidth="1" />
								<line x1={0} 		y1={-height * 1.5} 	x2={width} 	y2={-height * 1.5} 	stroke="#000000" strokeWidth="1" />
								<line x1={width} 	y1={-height} 		x2={width} 	y2={-height * 2} 	stroke="#000000" strokeWidth="1" />
							
								{rotation === 180 &&
									// Orientation + Position now fixed
									<Label transform={"rotate(180)"} x={-width * (isSimple ? 0.80 : .5)} y={height * 3}>{showArticleNumber ? article : elementLength}</Label>
								}
								{rotation !== 180 &&
									// Orientation + Position now fixed
									<Label x={width * (isSimple ? 0.20 : .5)} y={-height * 3}>{showArticleNumber ? article : elementLength}</Label>
								}
							</g>
							}
							
							<line x1={width + height} 			y1={0}	 	x2={width + 2 * height} 	y2={0} 		stroke="#000000" strokeWidth="1" />
							<line x1={width + 1.5 * height}		y1={0} 		x2={width + 1.5 * height} 	y2={width} 	stroke="#000000" strokeWidth="1" />
							<line x1={width + height} 			y1={width} 	x2={width + 2 * height} 	y2={width} 	stroke="#000000" strokeWidth="1" />
							
							{rotation !== 90 &&
								// Orientation + Position now fixed
								<Label transform={"rotate(90)"} x={width * (isSimple ? 0.20 : .5)} y={-width - 3 * height}>{showArticleNumber ? article : elementLength}</Label>
							}
							{rotation === 90 &&
								// Orientation + Position now fixed
								<Label transform={"rotate(-90)"} x={-width * (isSimple ? 0.80 : .5)} y={width + 3 * height}>{showArticleNumber ? article : elementLength}</Label>
							}
						</g>
					</>
				}
				
				{cornerOrientation === 'L' &&
					<>
						<polygon
							stroke={border}
							fill={color}
							points={
								"0,0 "
								+ (width - height) + ",0 "
								+ (width - height) + "," + (-width + height) + " "
								+ width + "," + (-width + height) + " "
								+ width + "," + height + " "
								+ "0," + height
							}
						/>
						<g>
							<line x1={0} 		y1={2 * height}	 	x2={0} 		y2={height * 3} 	stroke="#000000" strokeWidth="1" />
							<line x1={0} 		y1={height * 2.5} 	x2={width} 	y2={height * 2.5} 	stroke="#000000" strokeWidth="1" />
							<line x1={width} 	y1={2 * height} 	x2={width} 	y2={height * 3} 	stroke="#000000" strokeWidth="1" />
							
							{rotation === 180 &&
								<Label transform={"rotate(180)"} x={-width * (isSimple ? 0.80 : .5)} y={height * 3}>{showArticleNumber ? article : elementLength}</Label>
							}
							{rotation !== 180 &&
								<Label x={width * (isSimple ? 0.20 : .5)} y={isSimple ? height * 4 :height * 3.5}>{showArticleNumber ? article : elementLength}</Label>
							}
							{/* Check to disable article numbers on one leg of the corner element in the simplified view of the pdf */}
							{!isSimple && 
							<g>
								<line x1={width + height} 			y1={height}	 			x2={width + 2 * height} 	y2={height} 			stroke="#000000" strokeWidth="1" />
								<line x1={width + 1.5 * height}		y1={height} 			x2={width + 1.5 * height} 	y2={-width + height} 	stroke="#000000" strokeWidth="1" />
								<line x1={width + height} 			y1={-width + height} 	x2={width + 2 * height} 	y2={-width + height} 	stroke="#000000" strokeWidth="1" />

								{rotation !== 90 &&
									<Label transform={"rotate(90)"} x={-width * (isSimple ? 0.80 : .5) + height} y={-width - 2.5 * height}>{showArticleNumber ? article : elementLength}</Label>
								}
								{rotation === 90 &&
									// Orientation + Position now fixed
									<Label transform={"rotate(-90)"} x={width * (isSimple ? 0.20 : .5) - height} y={width + 2.5 * height}>{showArticleNumber ? article : elementLength}</Label>
								}
							</g>
							}
						</g>
					</>
				}
			</g>
		</g>
	)
}

const Label = styled.text`
    fill: #000;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    text-anchor: middle;
    dominant-baseline: hanging;
`;