import {UNSAFE_NavigationContext, useNavigate} from "react-router-dom";
import {useContext, useEffect} from "react";
import {setOpenConfiguratorPage2} from "../features/products/productsSlice";
import {useDispatch} from "react-redux";

export const useBackListener = () => {
	const navigator = useContext(UNSAFE_NavigationContext).navigator;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	
	useEffect(() => {
		const listener = ({ location, action }) => {
			if (action === "POP") {
				dispatch(setOpenConfiguratorPage2(false));
				navigate("/", { replace: true });
			}
		};
		
		const unlisten = navigator.listen(listener);
		return unlisten;
	}, [navigator]);
};