import Line from '../Configuration/Line';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkInputs, getMinValueOfLine } from '../../services/service';
import LinePopup from './LinePopup';
import LineInput from './LineInput';
import { selectUserSelectedDiffusor } from '../../features/products/productsSlice';
import { selectRulesetID } from '../../features/configuration/configurationSlice';

export default function OForm() {
    const dispatch = useDispatch();

    const diffusorType = useSelector(selectUserSelectedDiffusor);
    const ruleId = useSelector(selectRulesetID);

    //Line 1
    const [style1, setStyle1] = useState('popup');
    const [inputValLine1, setInputValLine1] = useState('');
    const [showLine1Popup, setShowLine1Popup] = useState(false);
    const [smallerLine1, setSmallerLine1] = useState(null);
    const [lengthLine1, setLengthLine1] = useState(null);
    const [biggerLine1, setBiggerLine1] = useState(null);
    const [line1IsSet, setLine1IsSet] = useState(false);
    const inputRefLine1 = useRef(null);
    const focusInputLine1 = () => inputRefLine1.current.focus();
    const minLine1 = getMinValueOfLine(1, 'O', diffusorType, ruleId);

    //Line 2
    const [style2, setStyle2] = useState('popup');
    const [inputValLine2, setInputValLine2] = useState('');
    const [showLine2Popup, setShowLine2Popup] = useState(false);
    const [smallerLine2, setSmallerLine2] = useState(null);
    const [lengthLine2, setLengthLine2] = useState(null);
    const [biggerLine2, setBiggerLine2] = useState(null);
    const [line2IsSet, setLine2IsSet] = useState(false);
    const inputRefLine2 = useRef(null);
    const focusInputLine2 = () => inputRefLine2.current.focus();
    const minLine2 = getMinValueOfLine(2, 'O', diffusorType, ruleId);

    useEffect(() => {
        checkInputs(dispatch, 4, line1IsSet, line2IsSet, true, inputValLine1, inputValLine2, inputValLine1, inputValLine2);
    }, [line1IsSet, line2IsSet]);

    return (
        <>
            <g>
                <Line x={160} y={0} height={10} width={300} border={'#000000'} color={'#FFFFFF'} rotation={90} elementLength={500} withLabel={false} />
                <LineInput
                    lineIsSet={line1IsSet}
                    x={245}
                    y={-100}
                    focus={true}
                    inputRef={inputRefLine1}
                    inputVal={inputValLine1}
                    inputStyle={style1}
                    setStyle={setStyle1}
                    setInputVal={setInputValLine1}
                    setShowLine={setShowLine1Popup}
                    minLineVal={minLine1}
                    setSmallerLine={setSmallerLine1}
                    setLineLength={setLengthLine1}
                    setBiggerLine={setBiggerLine1}
                    focusInput={focusInputLine1}
                    setLineIsSet={setLine1IsSet}
                />
            </g>

            <g>
                <Line x={160} y={290} height={10} width={300} border={'#000000'} color={'#FFFFFF'} rotation={0} elementLength={300} withLabel={false} />
                <LineInput
                    lineIsSet={line2IsSet}
                    x={489}
                    y={125}
                    inputRef={inputRefLine2}
                    inputVal={inputValLine2}
                    inputStyle={style2}
                    setStyle={setStyle2}
                    setInputVal={setInputValLine2}
                    setShowLine={setShowLine2Popup}
                    minLineVal={minLine2}
                    setSmallerLine={setSmallerLine2}
                    setLineLength={setLengthLine2}
                    setBiggerLine={setBiggerLine2}
                    focusInput={focusInputLine2}
                    setLineIsSet={setLine2IsSet}
                />
            </g>

            <g>
                <Line x={460} y={300} height={10} width={300} border={'#000000'} color={'#FFFFFF'} rotation={-90} elementLength={300} withLabel={false} />
            </g>

            <g>
                <Line x={160} y={0} height={10} width={300} border={'#000000'} color={'#FFFFFF'} rotation={0} elementLength={300} withLabel={false} />
            </g>

            <g>
                {showLine1Popup && (
                    <LinePopup
                        setShowLine={setShowLine1Popup}
                        lineNumber={1}
                        smallerLine={smallerLine1}
                        lengthLine={lengthLine1}
                        biggerLine={biggerLine1}
                        setStyle={setStyle1}
                        setInputVal={setInputValLine1}
                        setLineIsSet={setLine1IsSet}
                        lineIsSet={line1IsSet}
                        x={138}
                        y={-225}
                    />
                )}
                {showLine2Popup && (
                    <LinePopup
                        setShowLine={setShowLine2Popup}
                        lineNumber={2}
                        smallerLine={smallerLine2}
                        lengthLine={lengthLine2}
                        biggerLine={biggerLine2}
                        setStyle={setStyle2}
                        setInputVal={setInputValLine2}
                        setLineIsSet={setLine2IsSet}
                        lineIsSet={line2IsSet}
                        x={382}
                        y={2}
                    />
                )}
            </g>
        </>
    );
}
