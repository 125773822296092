import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectIsCHF, selectUserSelectedForm } from '../../features/products/productsSlice';
import { selectCountryCode } from '../../features/configuration/configurationSlice';
import { addThousandsSeparator, SWISSCODE } from '../../services/service';

/**
 * Line is the element line used in canvas view they are rendered in a normal and a simple SVG view.
 It consists out of the rectangle a labe which shows either length or article number(installation map) and a line next to the label that visually clarifies the extend of each element.
 The simple view is used in the installation map but always hidden in the browser view.
 Because of the behaviour of DOMPDF & also because of the different font size of the simple view the labels need different offsets & factors
 *
 * @export
 * @param {*} {article=null, x, y, height, width, border, color, rotation, elementLength, withLabel = true, lineNumber, isSimple=false, actualElementLength = false,...props}
 * @return {*}
 */
export default function Line({
    article = null,
    x,
    y,
    height,
    width,
    border,
    color,
    rotation,
    elementLength,
    withLabel = true,
    lineNumber,
    isSimple = false,
    actualElementLength = false,
    ...props
}) {
    const form = useSelector(selectUserSelectedForm);

    // Country specific formatting
    const countryCode = useSelector(selectCountryCode);
    let isLanguageCH = useSelector(selectIsCHF);
    let thousandSeparator = isLanguageCH ? "'" : '.';
    let decimalSeparator = isLanguageCH ? ',' : ',';

    /**
     * Sets the string of the label to the line element
     * @param {Object} data
     * @returns
     */
    function createLabel(data) {
        // In the case of the installation map SVGs an article number is part of data
        if (data.article !== null) {
            // This fits barely in the label space if each element has length of 300
            return data.article;
        } else {
            return addThousandsSeparator(data.length, thousandSeparator);
        }
    }

    return (
        <g transform={'translate(' + x + ', ' + y + ')'} width={width} height={width}>
            <g transform={'rotate(' + rotation + ')'}>
                <rect x={0} y={0} width={width} height={height} stroke={border} fill={color} />

                {withLabel && (form === 'O' || (form === 'Z' && lineNumber == 1) || (form === 'S' && lineNumber == 3)) && (
                    <g>
                        <line x1={0} y1={-height} x2={0} y2={-height * 2} stroke="#000000" strokeWidth="1" />
                        <line x1={0} y1={-height * 1.5} x2={width} y2={-height * 1.5} stroke="#000000" strokeWidth="1" />
                        <line x1={width} y1={-height} x2={width} y2={-height * 2} stroke="#000000" strokeWidth="1" />

                        {rotation === 180 && (
                            <Label transform={'rotate(180)'} x={-width * (isSimple ? 0.85 : 0.5)} y={height * 3}>
                                {createLabel({ article: article, length: actualElementLength === false ? elementLength : actualElementLength })}
                            </Label>
                        )}
                        {rotation !== 180 && (
                            <Label x={width * (isSimple ? 0.15 : 0.5)} y={-height * (isSimple ? 3 : 5)}>
                                {createLabel({ article: article, length: actualElementLength === false ? elementLength : actualElementLength })}
                            </Label>
                        )}
                    </g>
                )}

                {withLabel && form !== 'O' && !(form === 'Z' && lineNumber == 1) && !(form === 'S' && lineNumber == 3) && (
                    <g>
                        <line x1={0} y1={2 * height} x2={0} y2={height * 3} stroke="#000000" strokeWidth="1" />
                        <line x1={0} y1={2.5 * height} x2={width} y2={2.5 * height} stroke="#000000" strokeWidth="1" />
                        <line x1={width} y1={2 * height} x2={width} y2={height * 3} stroke="#000000" strokeWidth="1" />

                        {rotation !== 180 && (
                            <Label className="length-{actualElementLength}" x={width * (isSimple ? 0.15 : 0.5)} y={isSimple ? height * 4 : height * 3.5}>
                                {createLabel({ article: article, length: actualElementLength === false ? elementLength : actualElementLength })}
                            </Label>
                        )}
                    </g>
                )}
            </g>
        </g>
    );
}

const Label = styled.text`
    fill: #000;
    font-size: 32px;
    line-height: 14px;
    text-align: center;
    text-anchor: middle;
    dominant-baseline: hanging;
`;
