import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import LinePopup from "../LineInput/LinePopup";
import {useDispatch, useSelector} from "react-redux";
import {selectUserLength, selectUserSelectedDiffusor, setLineValues, setUserLengths} from "../../features/products/productsSlice";
import LineInput from "../LineInput/LineInput";
import {selectForm, selectRulesetID, setConfigurationLineLengths} from "../../features/configuration/configurationSlice";
import {getMinValueOfLine} from "../../services/service";

export default function LineEdit({transform, x, y, lineNumber}) {
	const dispatch = useDispatch();
	const userLengths = useSelector(selectUserLength);
	const form = useSelector(selectForm);
	const ruleId = useSelector(selectRulesetID);
	const inputRef = useRef(null)
	const focusInput = () => inputRef.current.focus();
	const [showInput, setShowInput] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	const [style, setStyle] = useState("popup");
	const [inputVal, setInputVal] = useState('');
	const [smallerLine, setSmallerLine] = useState(null);
	const [lengthLine, setLengthLine] = useState(null);
	const [biggerLine, setBiggerLine] = useState(null);
	const [lineIsSet, setLineIsSet] = useState(false);
	const diffusorType = useSelector(selectUserSelectedDiffusor);
	const minLine = getMinValueOfLine(lineNumber, form, diffusorType, ruleId);

	useEffect(() => {
		setInputVal(userLengths['line' + lineNumber]);
	}, [userLengths]);
	
	
	useEffect(() => {
		if (inputVal && lineIsSet) {
			let newUserLenghts = structuredClone(userLengths);
			newUserLenghts['line' + lineNumber] = inputVal.toString();
			if (form === 'O') {
				newUserLenghts['line' + (parseInt(lineNumber) + 2)] = inputVal.toString();
			}
			dispatch(setLineValues(null));
			dispatch(setUserLengths(newUserLenghts));
			dispatch(setConfigurationLineLengths(newUserLenghts));
			setLineIsSet(false);
			setShowPopup(false);
			setShowInput(false);
		}
	}, [inputVal, lineIsSet]);
	
	
	return (
		<>
			<foreignObject transform={transform} x={x} y={y} height={24} width={24}>
				<Icon onClick={() => {
					setShowInput(true);
					setTimeout(() => {
						focusInput();
					}, 100);
					
				}}>
					<FontAwesomeIcon icon={["far", "edit"]} />
				</Icon>
			</foreignObject>
			{showInput &&
				<LineInput transform={transform} lineIsSet={lineIsSet} x={x - 55} y={y - 9} focus={false}
						   inputRef={inputRef} inputVal={inputVal} setInputVal={setInputVal}
						   setShowLine={setShowPopup} minLineVal={minLine} setSmallerLine={setSmallerLine}
						   setLineLength={setLengthLine} setBiggerLine={setBiggerLine} focusInput={focusInput}
						   setLineIsSet={setLineIsSet}/>
			}
			{showPopup &&
				<LinePopup transform={transform} setShowLine={setShowPopup} lineNumber={lineNumber} smallerLine={smallerLine}
						   lengthLine={lengthLine} biggerLine={biggerLine} setInputVal={setInputVal} setStyle={setStyle}
						   setLineIsSet={setLineIsSet} lineIsSet={lineIsSet} x={x - 160} y={y - 140}/>
			}
		</>
		
	);
}

const Icon = styled.span`
    font-size: 14px;
    line-height: 27px;
    text-align: center;
    background: #eaeaea;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: block;
    cursor: pointer;
`;