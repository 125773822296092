import { current } from '@reduxjs/toolkit';
import {
    addAdditionalPowerWiring,
    calculateDrawingCoordinates,
    calculateOperatingDevices,
    calculateOverallLumenAndWatt,
    calculatePrices,
    calculateProfileDrawingCoordinates,
    displayValues,
    getCorrectProfileLengths,
    mapElementsToProducts,
    reverseLine,
    splitLineInSameElements,
} from './productsSlice';
import { ROTATION_OF_LINE_ELEMENTS } from '../../services/service';

const CHANNEL_S_600_MASTER_EXTENSION_MAXIMUM = 900; // Because of the wiring 900 is not feasable because the cable is actually at the start of the master --> 900-600 =300
export const CS_RECESSED_CORNER_LEG = 145;
export const CS_STANDARD_CORNER_LEG = 135;

let currentLine = 1;

/**
 * Calculates Channel S
 * @param state
 */
export function doCalculationChannelS(state) {
    //get state values
    let userLengths = [state.userLengths.line1, state.userLengths.line2, state.userLengths.line3, state.userLengths.line4];
    let linesCount = current(state).linesCount;
    if (!linesCount) {
        linesCount = userLengths.filter((item) => item != null).length;
    }
    let isRectangle = linesCount === 4;

    let rulesetConstants = current(state).constants;
    let lineLengths = JSON.parse(current(state).lineLengths);
    let lineLengthsBoost = JSON.parse(current(state).lineLengthsBoost);
    let lineLengthsAsymmetric = JSON.parse(current(state).lineLengthsAsymmetric);

    //defining arrays for calculating elements
    let elements = [];

    let masterNotExpandable = lineLengths.filter((item) => item.type === 'master' && item.expandable === false);
    let master = lineLengths.filter((item) => item.type === 'master' && item.expandable === true);
    let slave = lineLengths.filter((item) => item.type === 'slave');
    let corner = lineLengths.filter((item) => item.type === 'corner');

    if (state.userSelectedDiffusor === 'boost') {
        masterNotExpandable = lineLengthsBoost.filter((item) => item.type === 'master' && item.expandable === false);
        master = lineLengthsBoost.filter((item) => item.type === 'master' && item.expandable === true);
        slave = lineLengthsBoost.filter((item) => item.type === 'slave');
    } else if (state.userSelectedDiffusor === 'asymmetric') {
        masterNotExpandable = lineLengthsAsymmetric.filter((item) => item.type === 'master' && item.expandable === false);
        master = lineLengthsAsymmetric.filter((item) => item.type === 'master' && item.expandable === true);
        slave = lineLengthsAsymmetric.filter((item) => item.type === 'slave');
    }

    //sort arrays after line length desc
    masterNotExpandable.sort((a, b) => b.length - a.length);
    master.sort((a, b) => b.length - a.length);
    slave.sort((a, b) => b.length - a.length);

    //track how many lines are calculated
    let count = 0;

    //resetting profileLengths & diffusorLengths
    state.profileLengths = null;
    state.diffusorLengths = null;

    if (linesCount === 1) {
        //calculate just a single line with no corners
        // if (! (state.userSelectedDiffusor === 'asymmetric' || state.userSelectedDiffusor === 'boost')) {
        calculateSingleLine(state, parseInt(userLengths[count]), rulesetConstants, masterNotExpandable, master, slave, elements);
        // }
        // else {
        // 	calculateSingleLineCHSVariant(state, parseInt(userLengths[count]), rulesetConstants, masterNotExpandable, master, slave, elements);
        // }
    } else if (linesCount === 4) {
        //rechteck
        //calculating starting line..
        // calculateStartingLineOfRectangle(state, parseInt(userLengths[count]), rulesetConstants, masterNotExpandable, master, slave, corner, elements);
        count = 0;

        for (let i = count + 1; i <= linesCount; i++) {
            //... then adding lines which start with a corner and end with a corner ...
            calculateLineWithStartingCornerAndEndCorner(
                state,
                parseInt(userLengths[count]),
                rulesetConstants,
                masterNotExpandable,
                master,
                slave,
                corner,
                elements
            );
            count++;
        }
        //and add a 100mm slave at the end to finish the rectangle
        // elements.unshift(slave.filter(item => item.length === 100)[0]);

        //optimize every line for biggest lengths
        elements = optimizeLines(masterNotExpandable, master, slave, elements, corner, state);
    } else {
        //calculate every possible form

        //starting with a line with a corner at the end...
        calculateLineWithOneCorner(state, parseInt(userLengths[count]), rulesetConstants, masterNotExpandable, master, slave, corner, elements);
        count++;

        //Only if the form is a L
        if (linesCount === 2) {
            calculateEndLineWithStartingCorner(state, parseInt(userLengths[count]), rulesetConstants, masterNotExpandable, master, slave, corner, elements);
        } else {
            for (let i = count + 1; i < linesCount; i++) {
                //... then adding lines which start with a corner and end with a corner ...
                calculateLineWithStartingCornerAndEndCorner(
                    state,
                    parseInt(userLengths[count]),
                    rulesetConstants,
                    masterNotExpandable,
                    master,
                    slave,
                    corner,
                    elements
                );
                count++;
            }

            //... and end with a line, that starts with a corner and ends normally
            calculateEndLineWithStartingCorner(state, parseInt(userLengths[count]), rulesetConstants, masterNotExpandable, master, slave, corner, elements);
        }

        //optimize every line for biggest lengths
        elements = optimizeLines(masterNotExpandable, master, slave, elements, corner, state);
    }

    //save calculated elements to state
    state.lineElements = JSON.stringify(elements);

    //reset lineCount
    currentLine = 1;

    //resetting price
    state.productPriceEur = 0;
    state.productPriceChf = 0;

    mapElementsToProducts(state, elements);
    mapProfilesToProducts(state);
    mapEQPToProducts(state);
    mapSystemEquipmentToProducts(state);
    mapSystemComponentsToProducts(state);
    state.isSimplifiedDrawing = true;
    if (current(state).isSimplifiedDrawing) {
        calculateProfileDrawingCoordinates(state, isRectangle);
    }
    state.isSimplifiedDrawing = false;
    calculateProfileDrawingCoordinates(state, isRectangle);
    calculateOverallLumenAndWatt(state);
    calculateOperatingDevices(state, elements);
    state.isSimplifiedDrawing = true;
    if (current(state).isSimplifiedDrawing) {
        calculateDrawingCoordinates(state, elements, isRectangle);
    }
    state.isSimplifiedDrawing = false;
    calculateDrawingCoordinates(state, elements, isRectangle);
}

/**
 * Calculates (needed) values like length of profile, recess, & so on
 * @param state
 * @param userLength
 * @param rulesetConstants
 * @returns {number}
 */
function calculateValues(state, userLength, rulesetConstants) {
    //calculating needed values
    let profileLength = userLength - 2 * rulesetConstants.endwallthickness;
    let recess = Math.ceil((profileLength / 1000) * 0.4 + userLength + rulesetConstants.cutoutcoefficient);
    let luminousSurface = userLength - 2 * rulesetConstants.endwallexpansioncompensator;
    let diffusorLength = luminousSurface + rulesetConstants.diffusorcoefficient;
    let lightLength = Math.floor(luminousSurface / 50) * 50;

    //output debugging things
    let debuggingValues = displayValues('L1', userLength, recess, profileLength, luminousSurface, diffusorLength, lightLength, state);

    let currentLineValues = JSON.parse(state.lineValues) || [];
    currentLineValues.push(JSON.stringify(debuggingValues));
    state.lineValues = JSON.stringify(currentLineValues);

    //return lightLength to update variable in parent function
    return lightLength;
}

/**
 * Calculates a Single Line (I) without any Corner
 * @param state
 * @param userLength
 * @param rulesetConstants
 * @param masterNotExpandable
 * @param master
 * @param slave
 * @param elements
 * @param lightLength
 */
function calculateSingleLine(state, userLength, rulesetConstants, masterNotExpandable, master, slave, elements, lightLength = null) {
    // If function called with an already calculated lightLength and other lengths, don't calculate them again -> else called for I-Form -> calculate anew
    if (lightLength === null) {
        //Calculate lightLength and saving debugging values
        lightLength = calculateValues(state, userLength, rulesetConstants);
    }

    let calcVal = calculateElements(elements, masterNotExpandable, master, slave, lightLength, state); // lightLength was passed throughout all called functions as calcVal
    // This will be always the case for small enough configurations of L,U,S,Z forms with a tiny first line
    // In those cases the slave elements were never calculated because they didn't have a expandable master besides the necessary 600 master
    if (calcVal > 0 && !(state.userSelectedDiffusor === 'asymmetric' || state.userSelectedDiffusor === 'boost')) {
        calculateSlaveElements(elements, slave, lightLength, state);
    }
}

/**
 * Calculates a Single Line that starts with a Corner
 * @param state
 * @param userLength
 * @param rulesetConstants
 * @param masterNotExpandable
 * @param master
 * @param slave
 * @param elements
 */
function calculateSingleLineAfterCorner(state, userLength, rulesetConstants, masterNotExpandable, master, slave, elements) {
    calculateElementsAfterCorner(elements, masterNotExpandable, master, slave, userLength, state);
}

/**
 * Calculates a Single Line that starts normally and ends with a Corner
 * @param state
 * @param userLength
 * @param rulesetConstants
 * @param masterNotExpandable
 * @param master
 * @param slave
 * @param corner
 * @param elements
 * @param reverse
 */
function calculateLineWithOneCorner(state, userLength, rulesetConstants, masterNotExpandable, master, slave, corner, elements, reverse = true) {
    let cornerLength = state.userSelectedInstallation === 'recessed' ? 100 + 45 : 100 + 35; //100mm corner + 35mm profile

    /*
		Vorgehen:
		- endlänge berechnen
			- user länge - 25mm abstand am anfang - 135mm ecke
			- abrunden
			- noch das 600mm master element abziehen
		- mit endlänge lässt sich eine "normale linie" berechnen
		- danach wird in das elements-array ein 600er master und eine ecke reingeschoben
		- done
	 */

    //calculating needed values
    let profileLength = Math.floor(userLength - rulesetConstants.endwallthickness - cornerLength);
    let recess = Math.ceil((profileLength / 1000) * 0.4 + userLength + rulesetConstants.cutoutcoefficient);
    let luminousSurface = userLength - rulesetConstants.endwallexpansioncompensator;
    let diffusorLength = Math.ceil(luminousSurface + rulesetConstants.diffusorcoefficient);
    //luminousSurface - ecke -> und dann abrunden
    let lightLength = Math.floor((luminousSurface - cornerLength) / 50) * 50;

    //output debugging things
    let debuggingValues = displayValues('L' + currentLine++, userLength, recess, profileLength, luminousSurface, diffusorLength, lightLength, state);

    let currentLineValues = JSON.parse(state.lineValues) || [];
    currentLineValues.push(JSON.stringify(debuggingValues));
    state.lineValues = JSON.stringify(currentLineValues);

    //600mm Master Element abziehen
    lightLength = lightLength - 600;

    //creating a new array for the elements to invert it afterwards (berechnungsvorschlag von regent)
    let tempArray = [];

    //calculate a single line
    calculateSingleLine(state, lightLength, rulesetConstants, masterNotExpandable, master, slave, tempArray, lightLength);

    //inverting tempArray to get Slaves to the beginning and adding it to our final elements-array
    if (reverse) {
        tempArray.reverse();
    }
    tempArray.map((element) => elements.push(element));

    //add 600mm master before corner
    let master600mm = master.filter((item) => item.type === 'master' && item.expandable === true && item.length === 600)[0];
    elements.push(master600mm);

    //adding corner
    let cornerRotation = ROTATION_OF_LINE_ELEMENTS[state.userSelectedForm][currentLine - 2] === 'R' ? 'right' : 'left';
    let cornerElement = corner.filter((item) => item.type === 'corner' && item.rotation === cornerRotation)[0];
    elements.push(cornerElement);
}

/**
 * Calculates a Single Line that starts AND ends with a Corner
 * @param state
 * @param userLength
 * @param rulesetConstants
 * @param masterNotExpandable
 * @param master
 * @param slave
 * @param corner
 * @param elements
 */
function calculateLineWithStartingCornerAndEndCorner(state, userLength, rulesetConstants, masterNotExpandable, master, slave, corner, elements) {
    let cornerLength = state.userSelectedInstallation === 'recessed' ? 100 + 45 : 100 + 35; //100mm corner + 35mm profile

    /*
		Vorgehen
		- endlänge berechnen
			- user länge - 2x * 135mm ecke - 5mm extra zwischen zwei ecken
			- abrunden
			- noch das 600mm master element abziehen
		- mit endlänge lässt sich eine "normale linie" berechnen
			- beim rest (slaves) muss geschaut werden, ob er innerhalb der 1200mm erweiterbarkeit des 600mm master liegt
				- falls ja, werden die elemente als erstes in das elements-array gesteckt
		- danach wird in das elements-array ein 600er master und eine ecke reingeschoben
		- done
	 */

    //calculating needed values
    let profileLength = userLength - 2 * cornerLength;
    let recess = Math.ceil((profileLength / 1000) * 0.4 + userLength + rulesetConstants.cutoutcoefficient);
    let luminousSurface = userLength; // Must fit in the calculated profile size
    let diffusorLength = Math.ceil(luminousSurface - luminousSurface / 2000);
    //luminousSurface - 2x * 135mm ecke - 5mm extra zwischen zwei ecken -> danach abrunden
    let lightLength = Math.floor((luminousSurface - 2 * cornerLength - rulesetConstants.spacingbetweentwocorners) / 50) * 50;

    //output debugging things
    let debuggingValues = displayValues('L' + currentLine++, userLength, recess, profileLength, luminousSurface, diffusorLength, lightLength, state);

    let currentLineValues = JSON.parse(state.lineValues) || [];
    currentLineValues.push(JSON.stringify(debuggingValues));
    state.lineValues = JSON.stringify(currentLineValues);

    //At least a 600mm Master element must be before the edge abziehen
    lightLength -= 600;

    //There must at least be a 100mm Slave Element at the beginning
    lightLength -= 100;

    //calculate a single line
    calculateSingleLineAfterCorner(state, lightLength, rulesetConstants, masterNotExpandable, master, slave, elements);

    //add 600mm master before corner
    let master600mm = master.filter((item) => item.type === 'master' && item.expandable === true && item.length === 600)[0];
    elements.push(master600mm);

    //adding corner
    let cornerRotation = ROTATION_OF_LINE_ELEMENTS[state.userSelectedForm][currentLine - 2] === 'R' ? 'right' : 'left';
    let cornerElement = corner.filter((item) => item.type === 'corner' && item.rotation === cornerRotation)[0];
    elements.push(cornerElement);
}

/**
 * Calculates a Single Line that starts with a Corner and ends normally
 * @param state
 * @param userLength
 * @param rulesetConstants
 * @param masterNotExpandable
 * @param master
 * @param slave
 * @param corner
 * @param elements
 */
function calculateEndLineWithStartingCorner(state, userLength, rulesetConstants, masterNotExpandable, master, slave, corner, elements) {
    let cornerLength = state.userSelectedInstallation === 'recessed' ? 100 + 45 : 100 + 35; //100mm corner + 35mm profile

    /*
		Vorgehen
		- endlänge berechnen
			- user länge - 135mm ecke - 25mm abstand am ende
			- abrunden
		- mit endlänge lässt sich eine "normale linie" berechnen
			- beim rest (slaves) muss geschaut werden, ob er innerhalb der 1200mm erweiterbarkeit des 600mm master liegt
				- falls ja, werden die elemente als erstes in das elements-array gesteckt
		- done
	 */

    //calculating needed values
    let profileLength = Math.floor(userLength - cornerLength - rulesetConstants.endwallthickness);
    let recess = Math.ceil((profileLength / 1000) * 0.4 + userLength + rulesetConstants.cutoutcoefficient);
    let luminousSurface = userLength - rulesetConstants.endwallexpansioncompensator;
    let diffusorLength = Math.ceil(luminousSurface + rulesetConstants.diffusorcoefficient);
    //luminousSurface - 135mm ecke -> dann abrunden
    let lightLength = Math.floor((luminousSurface - cornerLength) / 50) * 50;

    //output debugging things
    let debuggingValues = displayValues('L' + currentLine++, userLength, recess, profileLength, luminousSurface, diffusorLength, lightLength, state);

    let currentLineValues = JSON.parse(state.lineValues) || [];
    currentLineValues.push(JSON.stringify(debuggingValues));
    state.lineValues = JSON.stringify(currentLineValues);

    //100mm Slave am Anfang abziehen
    lightLength -= 100;

    //calculate a single line
    calculateSingleLineAfterCorner(state, lightLength, rulesetConstants, masterNotExpandable, master, slave, elements);
}

/**
 * Optimizes every line for biggest length of elements
 * @param masterNotExpandable
 * @param master
 * @param slave
 * @param elements
 * @param corner
 */
function optimizeLines(masterNotExpandable, master, slave, elements, corner, state) {
    let form = current(state).userSelectedForm; // Currently selected form of this configuration
    let lineOptimiziationStart = form === 'O' ? 0 : 1; // Which lines are optimized, all forms but O optimize only after the starting line
    //array with all our lines
    let lines = [];
    //temp array for every line
    let tempLine = [];
    //output array for the optimized lines
    let output = [];
    //temp array for optimized lines
    let tempOptimizedLine = [];

    //splitting our elements after every corner
    // eslint-disable-next-line array-callback-return
    elements.map((item) => {
        tempLine.push(item);

        if (item.type === 'corner') {
            lines.push(tempLine);
            tempLine = [];
        }
    });
    lines.push(tempLine);

    //Add the first line because it is already optimized BUT O-Form is skipped because its first line must be optimized first
    if (form !== 'O') {
        lines[0].map((element) => output.push(element));
    }

    //go through all lines except the first one
    for (let i = lineOptimiziationStart; i < lines.length; i++) {
        if (lines[i].length === 0) {
            continue;
        }
        let splittedLine = splitLineInSameElements(lines[i]);

        splittedLine.forEach((elementsArray) => {
            let elementType = '';
            let elementExpandable = '';
            let combinedElementsLength = 0;
            let lastElement = '';

            for (let y = 0; y < elementsArray.length; y++) {
                let element = elementsArray[y];
                elementType = element.type;
                elementExpandable = element.expandable;
                combinedElementsLength += element.length;
                lastElement = element;
            }

            if (elementType === 'slave') {
                calculateSlaveElements(tempOptimizedLine, slave, combinedElementsLength, state);
            } else if (elementType === 'master' && elementExpandable === false) {
                calculateMasterElementsNotExpandable(tempOptimizedLine, masterNotExpandable, combinedElementsLength);
            } else if (elementType === 'master' && elementExpandable === true) {
                // Bug this would only push the last element and shorten the line
                // tempOptimizedLine.push(lastElement);
                elementsArray.forEach((expandableMaster) => {
                    tempOptimizedLine.push(expandableMaster);
                });
            } else if (elementType === 'corner') {
                tempOptimizedLine.push(lastElement);
            }
        });
        // Resort slaves to always have expandable at the beginning and combine unextendable
        let slaveIndex = []; // Always contains start index and exclusive end index of slave in a row
        let slavePairs = []; // Contains array of slave indices pairs --> all slaves row in this line
        let isSlave = false;
        // Get subarray of line that contains slaves next to each other
        tempOptimizedLine.forEach((element, i) => {
            if (element.type === 'slave' && isSlave !== true) {
                isSlave = true;
                slaveIndex.push(i);
            }
            // Or condition checks if element is at end of configuration
            else if ((element.type !== 'slave') | (i === tempOptimizedLine.length - 1) && isSlave === true) {
                // On hitting a none slave the row is finished
                if (slaveIndex[0] !== i - 1 || i === tempOptimizedLine.length - 1) {
                    // If last element in configuration have to point index to element after current element
                    i === tempOptimizedLine.length - 1 && element.type === 'slave' ? slaveIndex.push(i + 1) : slaveIndex.push(i);
                    slavePairs.push(slaveIndex); // Completed pair is pushed
                }
                slaveIndex = []; // Empty index pair
                isSlave = false;
            }
        });

        // Take all range pairs and slice continous slaves out
        // Replace them by recalculating the used products
        // Sort the new slaves with the expandable ones coming first
        slavePairs.forEach((rangePair, i) => {
            if (tempOptimizedLine[rangePair[0]].type === 'slave' && tempOptimizedLine[rangePair[1] - 1].type === 'slave') {
                let newSlavesCalculation = [];
                let combinedElementsLength = 0;
                tempOptimizedLine.slice(rangePair[0], rangePair[1]).forEach((element) => {
                    combinedElementsLength += element.length;
                });
                calculateSlaveElements(newSlavesCalculation, slave, combinedElementsLength, state); // Recalculate used slaves
                let toInput = newSlavesCalculation.sort((a, b) => {
                    return a.expandable === true ? -1 : 1;
                });
                tempOptimizedLine.splice(rangePair[0], rangePair[1] - rangePair[0], ...toInput);
            }
        });
    }

    //add optimized lines to output
    tempOptimizedLine.map((element) => output.push(element));

    //let temp = optimizeLinesExtended(masterNotExpandable, master, slave, output, corner);

    //overriding existing elements
    return output;
}

/**
 * Calculates elements (not expandable masters, expandable masters & slaves)
 * @param elements
 * @param masterNotExpandable
 * @param master
 * @param slave
 * @param calcVal
 * @param state
 */
function calculateElements(elements, masterNotExpandable, master, slave, calcVal, state) {
    calcVal = calculateMasterElementsNotExpandable(elements, masterNotExpandable, calcVal);
    calcVal = calculateMasterElementsExpandable(elements, master, calcVal, slave, state);
    return calcVal;
}

/**
 * Calculates not expandable master elements
 * @param elements
 * @param masterNotExpandable
 * @param calcVal
 * @returns {*}
 */
function calculateMasterElementsNotExpandable(elements, masterNotExpandable, calcVal) {
    //defining break condition
    let breakMasterNotExpandable = false;

    //Alle nicht erweiterbaren durchgehen
    do {
        for (let i = 0; i < masterNotExpandable.length; i++) {
            //Wenn Rest größer 1.2m, dann werden so viele einzelne Master geadded, wie es geht
            if (calcVal - masterNotExpandable[i].length > 1200 || calcVal - masterNotExpandable[i].length === 0) {
                elements.push(masterNotExpandable[i]);
                calcVal -= masterNotExpandable[i].length;
                break;
            } else {
                if (i === masterNotExpandable.length - 1) {
                    breakMasterNotExpandable = true;
                    break;
                }
            }
        }
    } while (!breakMasterNotExpandable);

    //return calcVal to update variable in parent function
    return calcVal;
}

/**
 * Calculates expandable master elements
 * @param elements
 * @param master
 * @param calcVal
 * @param slave
 */
function calculateMasterElementsExpandable(elements, master, calcVal, slave, state) {
    //Danach wird geschaut, welche erweiterbaren Master-Elemente reinpassen
    if (calcVal > 0) {
        for (let i = 0; i < master.length; i++) {
            let minLength = master[i].length + master[i].expandableMin;
            let maxLength = master[i].length + master[i].expandableMax;

            if (minLength <= calcVal && calcVal <= maxLength) {
                calcVal -= master[i].length;
                elements.push(master[i]);

                calcVal = calculateSlaveElements(elements, slave, calcVal, state);

                // If there is a rest reset the calculation and try with the next master again
                // if(calcVal > 0) {
                // 	if(state.userSelectedDiffusor === "asymmetric"){
                // 		console.error("calculateMasterElementsExpandable:\t calcVal is " + calcVal);
                // 		calcVal += master[i].length;
                // 		elements.length = 0;
                // 		continue;
                // 	}
                // }

                break;
            }
        }
    }
    return calcVal;
}

/**
 * Calculates slave elements
 * @param elements array of elements: updated elements so far
 * @param slave array of allowed slave elements
 * @param calcVal int: combined length elements
 * @returns {*}
 */
function calculateSlaveElements(elements, slave, calcVal, state) {
    let breakFor = false;
    let currentDiffusor = state.userSelectedDiffusor;

    while (!breakFor) {
        //Den Rest füllen wir jetzt mit Slave-Elemente auf
        for (let y = 0; y < slave.length; y++) {
            if (calcVal - slave[y].length >= 0) {
                elements.push(slave[y]);
                calcVal -= slave[y].length;
                //to check biggest elements first
                break;
            }

            if (calcVal === 0) {
                breakFor = true;
                break;
            }

            if (calcVal < 0) {
                // TODO: This must be fixed because I changed a corner from 135 to being according to installation type this condition is sometimes met for example with the smallest possible L
                // Problem of calculateLineWithOneCorner and others first line which was 100 + 35
                console.log('There was an issue: calcVal is:' + calcVal);
                breakFor = true;
                break;
            }

            if (currentDiffusor === 'boost' || currentDiffusor === 'asymmetric') {
                if (calcVal < slave[slave.length - 1].length) {
                    breakFor = true;
                    break;
                }
            }
        }
    }

    //return calcVal to update variable in parent function
    return calcVal;
}

/*
Calculation WITH Corner
 */
/**
 * Calculates Elements AFTER a corner
 * @param elements
 * @param masterNotExpandable
 * @param master
 * @param slave
 * @param calcVal
 * @param state
 */
function calculateElementsAfterCorner(elements, masterNotExpandable, master, slave, calcVal, state) {
    //adding the 100mm slave in the beginning
    let slave100mm = slave.filter((item) => item.type === 'slave' && item.length === 100)[0];
    elements.push(slave100mm);

    if (calcVal <= CHANNEL_S_600_MASTER_EXTENSION_MAXIMUM) {
        //wenn der wert kleiner gleich CHANNEL_S_600_MASTER_EXTENSION_MAXIMUM ist, sind wir noch in der erweiterbarkeit des 600mm master elements
        //können aber kein master element mehr reinbekommen (zu wenig platz)
        //Beispiel: calVal = CHANNEL_S_600_MASTER_EXTENSION_MAXIMUM -> CHANNEL_S_600_MASTER_EXTENSION_MAXIMUM + 100 (Slave nach der Ecke) + 200 (die ecke) -> 1200 & das ist kleiner gleich die max verlängerbarkeit von 1200
        calculateSlaveElements(elements, slave, calcVal, state);
    } else if (CHANNEL_S_600_MASTER_EXTENSION_MAXIMUM < calcVal && calcVal < 1200) {
        //wenn unsere Länge zwischen CHANNEL_S_600_MASTER_EXTENSION_MAXIMUM und 1200 ist, muss ein 600mm Master reingeklatscht werden. Dieser muss um min 300 verlängert werden
        //davor können Slaves geadded werden, für den Fall dass die Ecke davor ist
        let master600mm = master.filter((item) => item.type === 'master' && item.length === 600)[0];
        let slave300mm = slave.filter((item) => item.type === 'slave' && item.length === 300)[0];

        calcVal -= CHANNEL_S_600_MASTER_EXTENSION_MAXIMUM;
        if (calcVal > 0) {
            calculateSlaveElements(elements, slave, calcVal, state);
        }

        elements.push(master600mm);
        elements.push(slave300mm);
    } else {
        //creating a new array for the elements to invert it afterwards (berechnungsvorschlag von regent)
        let tempArray = [];
        calcVal = calculateMasterElementsNotExpandable(tempArray, masterNotExpandable, calcVal);

        //funktion returned einen wert der entweder 100 oder mindestens 1350 ist
        //dann müssen die master & slave elemente durchgegangen werden wie gehabt
        calculateMasterElementsExpandable(tempArray, master, calcVal, slave, state);

        //inverting tempArray to get Slaves, if possible, to the beginning
        tempArray = reverseLine(tempArray);

        //adding our tempArray to our elements
        tempArray.map((element) => elements.push(element));
    }
}

/**
 * Maps profiles to products
 * @param state
 */
function mapProfilesToProducts(state) {
    let profiles = JSON.parse(state.profiles);
    let profileLengths = getCorrectProfileLengths(state);
    let form = state.userSelectedForm;
    let installation = state.userSelectedInstallation;

    //convert object into array to use filter method
    if (typeof profiles === 'object') {
        let profilesIndexes = [];
        Object.keys(profiles).forEach((key, index) => {
            profilesIndexes.push(key);
        });

        let temp = [];
        for (let i = 0; i < profilesIndexes.length; i++) {
            temp.push(profiles[profilesIndexes[i]]);
        }
        profiles = temp;
    }

    installation = installation === 'recessed' ? 'CR' : installation === 'ceiling' ? 'CW' : 'CW';
    //splitting into different placements
    let ssysProfiles = profiles.filter((item) => item.placement === 'SSYS');
    let intendProfiles = profiles.filter((item) => item.placement === 'INTEND');
    let centProfiles = profiles.filter((item) => item.placement === 'CENT' && !item.article_code.includes('.V XSA'));
    let zuschnittProfiles = profiles.filter((item) => item.placement === 'CENT' && item.article_code.includes('.V XSA'));

    //getting all profile lengths for calculation
    let ssysProfileLengths = [];
    let intendProfilesLengths = [];
    let centProfilesLengths = [];
    let zuschnittProfilesLengths = [];

    ssysProfiles.map((item) => ssysProfileLengths.push(item.length));
    intendProfiles.map((item) => intendProfilesLengths.push(item.length));
    centProfiles.map((item) => centProfilesLengths.push(item.length));
    zuschnittProfiles.map((item) => zuschnittProfilesLengths.push(item.length));

    //removing duplicates
    ssysProfileLengths = [...new Set(ssysProfileLengths)];
    intendProfilesLengths = [...new Set(intendProfilesLengths)];
    centProfilesLengths = [...new Set(centProfilesLengths)];
    zuschnittProfilesLengths = [...new Set(zuschnittProfilesLengths)];

    let biggestIntendProfile = intendProfilesLengths[intendProfilesLengths.length - 1];
    let biggestCentProfile = centProfilesLengths[centProfilesLengths.length - 1];

    if (installation === '') return;

    profiles.sort((a, b) => b.length - a.length);

    let profileProducts = [];
    profileLengths.forEach(function (profile) {
        let length = profile.profile;

        if (form === 'I') {
            if (ssysProfileLengths.includes(length)) {
                profileProducts.push({
                    product: ssysProfiles.filter((item) => item.installation === installation && item.length === length)[0],
                    cut: length,
                    line: profile.line,
                });
            } else if (length <= 4500) {
                let neededZuschnittLength = zuschnittProfilesLengths.filter((item) => item >= length)[0];
                profileProducts.push({
                    product: zuschnittProfiles.filter((item) => item.installation === installation && item.length === neededZuschnittLength)[0],
                    cut: length,
                    line: profile.line,
                });
            }
            // 500 is needed to ensure Beginning[4500][X][4500]Ending: X > 500
            else if (length <= 9050 + 500) {
                let rest = length;
                calculateProfiles(rest, intendProfilesLengths, profileProducts, intendProfiles, installation, profile.line, zuschnittProfiles);
            } else {
                let rest = length;
                let biggestIntendProfile = intendProfilesLengths[intendProfilesLengths.length - 1];
                rest -= 2 * biggestIntendProfile;
                //biggest intend at start
                profileProducts.push({
                    product: intendProfiles.filter((item) => item.installation === installation && item.length === biggestIntendProfile)[0],
                    cut: null,
                    line: profile.line,
                });

                //fill with cent elements
                calculateProfiles(rest, centProfilesLengths, profileProducts, centProfiles, installation, profile.line, zuschnittProfiles);

                //biggest intend at end
                profileProducts.push({
                    product: intendProfiles.filter((item) => item.installation === installation && item.length === biggestIntendProfile)[0],
                    cut: null,
                    line: profile.line,
                });
            }
        } else if (form === 'L') {
            if (profile.line === 'L1') {
                calculateProfilesStart(
                    length,
                    biggestIntendProfile,
                    profileProducts,
                    intendProfiles,
                    intendProfilesLengths,
                    centProfiles,
                    centProfilesLengths,
                    installation,
                    profile.line,
                    zuschnittProfiles
                );
            } else {
                calculateProfilesEnd(
                    length,
                    biggestIntendProfile,
                    profileProducts,
                    intendProfiles,
                    intendProfilesLengths,
                    centProfiles,
                    centProfilesLengths,
                    installation,
                    profile.line,
                    zuschnittProfiles
                );
            }
        } else if (form === 'S' || form === 'Z' || form === 'U') {
            if (profile.line === 'L1') {
                calculateProfilesStart(
                    length,
                    biggestIntendProfile,
                    profileProducts,
                    intendProfiles,
                    intendProfilesLengths,
                    centProfiles,
                    centProfilesLengths,
                    installation,
                    profile.line,
                    zuschnittProfiles
                );
            } else if (profile.line === 'L2') {
                calculateProfilesBetween(
                    length,
                    biggestCentProfile,
                    profileProducts,
                    centProfiles,
                    centProfilesLengths,
                    installation,
                    profile.line,
                    zuschnittProfiles
                );
            } else {
                calculateProfilesEnd(
                    length,
                    biggestIntendProfile,
                    profileProducts,
                    intendProfiles,
                    intendProfilesLengths,
                    centProfiles,
                    centProfilesLengths,
                    installation,
                    profile.line,
                    zuschnittProfiles
                );
            }
        } else if (form === 'O') {
            calculateProfilesBetween(
                length,
                biggestCentProfile,
                profileProducts,
                centProfiles,
                centProfilesLengths,
                installation,
                profile.line,
                zuschnittProfiles
            );
        }
    });

    state.userProfiles = JSON.stringify(profileProducts);
    calculatePrices(state, profileProducts);
}

/**
 * Maps Equipment to Products
	2003.1609   Befestigungs-Set                CHAN-S EQP FASTEN-KIT
	2004.0587   Schlitzscheiben-Set 2           PURLT EQP WSH-SLO-KIT 2PCS
	2004.0588   Schlitzscheiben-Set 5           PURLT EQP WSH-SLO-KIT 5PCS
	2003.5473   Befestigungsfeder               CHAN-S EQP MNTSPRING
	2004.6119   Wandhalter-Set                  CHAN-S SYS EQP WM-KIT 105
	2004.6121   Wandhalter-Set                  CHAN-S SYS EQP WM-KIT 50
	2001.0619   Drahtseilabhängungs-Set 1,5m    CHAN-S SYS WIREROPESUS-KIT-1500
	2001.0618   Drahtseilabhängungs-Set 3m      CHAN-S SYS WIREROPESUS-KIT-3000
	2001.8467   Baldachin-Set Universal         UNVERSL EQP CANOPY-KIT-132x56x25-RAL9016
 * @param state
 */
function mapEQPToProducts(state) {
    //products from ruleset
    let equipment = JSON.parse(state.equipment);
    let operatingDevices = state.operatingDevices;
    let profileLengths = getCorrectProfileLengths(state);
    let totalProfileLength = 0;
    let protection = state.userSelectedProtection;
    let form = state.userSelectedForm;
    let diffusor = state.userSelectedDiffusor;
    let cornerCount = JSON.parse(state.lineElements).filter((item) => item.type === 'corner').length;
    let installation = state.userSelectedInstallation;
    let overallWatt = state.overallWatt;
    let profilesCount = JSON.parse(state.userProfiles).length;

    //calculating total profile length
    profileLengths.map((profile) => (totalProfileLength += profile.profile));

    //calculated products for user
    let userEquipment = [];

    //Nur bei Deckeneinbau: Befestigungs-Set, alle 1.5 Meter.
    if (installation === 'recessed') {
        for (let i = 0; i < Math.floor(totalProfileLength / 1500); i++) {
            userEquipment.push(equipment.filter((item) => item.article_code === 'CHAN-S EQP FASTEN-KIT')[0]);
        }
    }
    // Nur bei Anbau
    if (installation === 'ceiling') {
        // STANDARD: Schlitzscheiben-Set
        if (true) {
            let neededSuspensions = Math.ceil(totalProfileLength / 1500);
            while (neededSuspensions > 0) {
                if (neededSuspensions >= 5) {
                    userEquipment.push(equipment.filter((item) => item.article_code === 'PURLT EQP WSH-SLO-KIT 5PCS')[0]);
                    neededSuspensions -= 5;
                } else {
                    userEquipment.push(equipment.filter((item) => item.article_code === 'PURLT EQP WSH-SLO-KIT 2PCS')[0]);
                    neededSuspensions -= 2;
                }
            }
            // let totalLengthForSchlitzscheiben = totalProfileLength;
            // while (totalLengthForSchlitzscheiben > 0) {
            // 	if (totalLengthForSchlitzscheiben < 2000) {
            // 		userEquipment.push(equipment.filter(item => item.article_code === 'PURLT EQP WSH-SLO-KIT 2PCS')[0]);
            // 		totalLengthForSchlitzscheiben = 0;
            // 	}
            // 	else if (totalLengthForSchlitzscheiben >= 2000 && totalLengthForSchlitzscheiben <= 4500) {
            // 		userEquipment.push(equipment.filter(item => item.article_code === 'PURLT EQP WSH-SLO-KIT 2PCS')[0]);
            // 		userEquipment.push(equipment.filter(item => item.article_code === 'PURLT EQP WSH-SLO-KIT 2PCS')[0]);
            // 		totalLengthForSchlitzscheiben = 0;
            // 	}
            // 	else {
            // 		userEquipment.push(equipment.filter(item => item.article_code === 'PURLT EQP WSH-SLO-KIT 5PCS')[0]);
            // 		totalLengthForSchlitzscheiben -= 4500;
            // 	}
            // }
        }
        // OPTION 1 : not accessible yet:
        //Befestigungsfeder Channel S, alle 1.5 Meter. Nur Decken/Wandanbau mit Unebenheit
        else if (false) {
            for (let i = 0; i < Math.floor(totalProfileLength / 1500); i++) {
                userEquipment.push(equipment.filter((item) => item.article_code === 'CHAN-S EQP MNTSPRING')[0]);
            }
        }
    }

    // Pendulum needs 1x Baldachin-Set (for now)
    if (installation === 'pendulum') {
        //Baldachin Set
        let numberBaldachinSets = 1;
        // let numberBaldachinSets = Math.ceil(operatingDevices/10);	//OLD: nach 10 operatingDevices neue Einspeisung
        for (let i = 0; i < numberBaldachinSets; i++) {
            userEquipment.push(equipment.filter((item) => item.article_code === 'UNVERSL EQP CANOPY-KIT-132x56x25-RAL9016')[0]);
        }
    }

    //Netzverdrahtung für Eckverbinder Channel S no. 2005.2409
    for (let i = 0; i < cornerCount; i++) {
        // if(!(form === "O" && i === 3)) {
        userEquipment.push(equipment.filter((item) => item.article_code === 'CHAN-S EQP L-CON POWWIRING-6Px1500')[0]);
        // }
    }
    userEquipment = userEquipment.concat(addAdditionalPowerWiring(state.lineElements, equipment));

    // Add corner to the profile - Eckverbinder Channel S
    for (let i = 0; i < cornerCount; i++) {
        if (installation === 'ceiling' || installation === 'pendulum') {
            userEquipment.push(equipment.filter((item) => item.article_code === 'CHAN-S EQP L-CON-CW HOR ELOX')[0]);
        } else if (installation === 'recessed') {
            userEquipment.push(equipment.filter((item) => item.article_code === 'CHAN-S EQP L-CON-CR HOR ELOX')[0]);
        }
    }

    //Dichtungs-Set Channel S
    //nur IP40 und 1 pro Endkappenset (Form O hat keine)
    if (protection === 'IP40' && form !== 'O') {
        userEquipment.push(equipment.filter((item) => item.article_code === 'CHAN-S EQP GASKET-KIT IP40')[0]);
    }
    //IP54 + RUN + pro Profil
    if (protection === 'IP54' && diffusor.toLowerCase() === 'run') {
        for (let i = 0; i < profilesCount; i++) {
            userEquipment.push(equipment.filter((item) => item.article_code === 'CHAN-S EQP GASKET-KIT IP54')[0]);
        }
    }

    //Zuschneidehilfe Channel S, 1x // besides boost which does not have diffusor to cut
    if (diffusor.toLowerCase() !== 'boost') {
        userEquipment.push(equipment.filter((item) => item.article_code === 'CHAN-S EQP CUTT AID')[0]);
    }

    // Always include disassembly tool Channel S & Purelite (Slim)
    userEquipment.push(equipment.filter((item) => item.article_code === 'PURLT CHAN-S EQP TOOL YE')[0]);

    //Sensor-Set Channel S
    //andere varianten werden nicht benötigt!
    //Werden allgemein erstmal nicht benötigt laut Johannes
    //1 + nach 15 Betriebsgeräten oder nach 1000W
    /*let sensorsCalculatedWithOperatingDevices = Math.floor(operatingDevices/15) + 1;
	let sensorsCalculatedWithOverallWatt = Math.floor(overallWatt/1000) + 1;
	let neededSensors = sensorsCalculatedWithOverallWatt > sensorsCalculatedWithOperatingDevices ? sensorsCalculatedWithOverallWatt : sensorsCalculatedWithOperatingDevices;
	for (let i = 0; i < neededSensors; i++) {
		userEquipment.push(equipment.filter(item => item.article_code === 'CHAN-S EQP SENS-KIT FROM 1500')[0]);
	}*/
    //userEquipment.push(equipment.filter(item => item.article_code === 'CHAN-S EQP SENS-KIT TO 1500')[0]);
    //userEquipment.push(equipment.filter(item => item.article_code === 'CHAN-S EQP SENS-KIT DALI')[0]);
    //Fernbedienung, 1x
    //Wird nicht benötigt!
    //userEquipment.push(equipment.filter(item => item.article_code === 'CHAN-S EQP RC')[0]);

    //Kabelhalter-Set Channel S
    //Wird nicht benötigt!
    //userEquipment.push(equipment.filter(item => item.article_code === 'CHAN-S EQP CBLCLIP-KIT PC 12PCS INTEND')[0]);

    state.userEQP = JSON.stringify(userEquipment);
    calculatePrices(state, userEquipment);
}

/**
 * Maps SystemComponents to Products
 * @param state
 */
function mapSystemComponentsToProducts(state) {
    let installation = state.userSelectedInstallation;
    if (installation !== 'pendulum') return;

    let systemComponents = JSON.parse(state.systemComponents);
    let userSystemComponents = [];
    let profileLengths = getCorrectProfileLengths(state);
    let totalProfileLength = 0;
    let pendelLength = state.userSelectedPendelLength;

    //calculating total profile length
    profileLengths.map((profile) => (totalProfileLength += profile.profile));

    // Calculating needed suspensions (1 every 1.5m) & each comes in sets of 2 (-> divide by 2 and round up)
    let neededSuspensions = Math.ceil(totalProfileLength / 1500 / 2);

    for (let i = 0; i < neededSuspensions; i++) {
        // eslint-disable-next-line eqeqeq
        if (pendelLength == 1500) {
            userSystemComponents.push(systemComponents.filter((item) => item.article_code === 'CHAN-S SYS WIREROPESUS-KIT-1500')[0]);
        }
        // eslint-disable-next-line eqeqeq
        else if (pendelLength == 3000) {
            userSystemComponents.push(systemComponents.filter((item) => item.article_code === 'CHAN-S SYS WIREROPESUS-KIT-3000')[0]);
        }
    }

    state.userSystemComponents = JSON.stringify(userSystemComponents);
    calculatePrices(state, userSystemComponents);
}

/**
 * Maps SystemEquipment to Products
 * @param state
 */
function mapSystemEquipmentToProducts(state) {
    let systemEquipment = JSON.parse(state.systemEquipment);
    let userSystemEquipment = [];
    let diffusor = state.userSelectedDiffusor;
    let userDiffusors = [];
    let diffusorLengths = JSON.parse(state.diffusorLengths);
    let form = state.userSelectedForm;
    let installation = state.userSelectedInstallation;
    let totalDiffusorLength = 0;
    let protection = state.userSelectedProtection;
    let operatingDevices = state.operatingDevices;
    let profileLengths = getCorrectProfileLengths(state);
    let totalProfileLength = 0;
    let profiles = JSON.parse(state.userProfiles);
    let lineElements = JSON.parse(state.lineElements);

    //convert object into array to use filter method
    if (typeof systemEquipment === 'object') {
        let systemEquipmentIndexes = [];
        Object.keys(systemEquipment).forEach((key, index) => {
            systemEquipmentIndexes.push(key);
        });

        let temp = [];
        for (let i = 0; i < systemEquipmentIndexes.length; i++) {
            temp.push(systemEquipment[systemEquipmentIndexes[i]]);
        }
        systemEquipment = temp;
    }

    //adding the last 2 lines to form O
    if (form === 'O') {
        diffusorLengths.push({ line: 'L3', length: diffusorLengths[0].length });
        diffusorLengths.push({ line: 'L4', length: diffusorLengths[1].length });
    }

    //calculating total profile & diffusor length
    profileLengths.map((profile) => (totalProfileLength += profile.profile));
    diffusorLengths.map((dif) => (totalDiffusorLength += dif.length));

    //Dilatations-Set Channel S Office, alle 25m 1x
    //Wird nicht benötigt!
    /*if (diffusor.toLowerCase() === 'boost') {
		let neededDilatationSets = Math.floor(totalDiffusorLength / 25000);
		for (let i = 0; i < neededDilatationSets; i++) {
			userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-SO SYS EQP DIL-KIT PC')[0])
		}
	}*/

    //Blindabdeckungs-Set Channel S
    //Wird nicht benötigt!
    //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-S SYS EQP BLDCOVER-KIT-3000 ELOX')[0])
    //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-S SYS EQP BLDCOVER-KIT-2400 ELOX')[0])
    //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-S SYS EQP BLDCOVER-KIT-1500 ELOX')[0])
    //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-S SYS EQP BLDCOVER-KIT-1200 ELOX')[0])
    //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-S SYS EQP BLDCOVER-KIT-600 ELOX')[0])
    //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-S SYS EQP BLDCOVER-KIT-300 ELOX')[0])

    //Stirnseiten-Set Channel S, 1x pro Konfiguration, außer Form O
    if (form !== 'O') {
        if (protection === 'IP20' || protection === 'IP40') {
            // JS: Das IP 40 Set ist lediglich zusätzlich zu bestellen und ersetzt nicht die bestehende IP20 Endkappe (2003.2622)
            if (installation === 'recessed') {
                userSystemEquipment.push(systemEquipment.filter((item) => item.article_code === 'CHAN-S SYS EQP FRONTEND-KIT-IP20-CT')[0]);
            } else {
                userSystemEquipment.push(systemEquipment.filter((item) => item.article_code === 'CHAN-S SYS EQP FRONTEND-KIT-IP20')[0]);
            }
        } else if (protection === 'IP54') {
            if (installation === 'recessed') {
                userSystemEquipment.push(systemEquipment.filter((item) => item.article_code === 'CHAN-S SYS EQP FRONTEND-KIT-IP54-CT')[0]);
            } else {
                userSystemEquipment.push(systemEquipment.filter((item) => item.article_code === 'CHAN-S SYS EQP FRONTEND-KIT-IP54')[0]);
            }
        }
    }

    // Temporary solution to feed point
    let metFirstCorner = false;
    let doubleMasterBeforeCorner = false;
    let metMaster = false;
    let targetFeedPointIndex = 0;
    lineElements.forEach((element, index) => {
        if (element.type === 'master') {
            // Met pair of masters
            if (metMaster === true) {
                doubleMasterBeforeCorner = true;
            }
            metMaster = true;
        }
        // If reached first corner
        else if (element.type === 'corner' && metFirstCorner === false) {
            metFirstCorner = true;
            metMaster = false;
            doubleMasterBeforeCorner = false;
        } else {
            metMaster = false;
        }
    });

    // If feed point between two masters it must use Einspeisepunkt Mitte CENT else INTEND
    if (doubleMasterBeforeCorner) {
        userSystemEquipment.push(systemEquipment.filter((item) => item.article_code === 'CHAN-S SYS EQP CONCBL-KIT-CENT')[0]); // 2003.4755
    } else {
        userSystemEquipment.push(systemEquipment.filter((item) => item.article_code === 'CHAN-S SYS EQP CONCBL-KIT-INTEND')[0]); // 2003.4754
    }

    //Netzanschluss-Set Channel S, 1x + jeweils 1 nach 15 operatingDevices
    // for (let i = 0; i < (Math.floor(operatingDevices / 15) + 1); i++) {
    // 	if (!(form === 'I' && i === 0)) {
    // 		userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-S SYS EQP CONCBL-KIT-CENT')[0])	// 2003.4755
    // 	} else {
    // 		userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-S SYS EQP CONCBL-KIT-INTEND')[0])	// 2003.4754
    // 	}
    // 	//userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-S SYS EQP CONCBL-KIT-INTEND-6P EMGY SENS')[0])
    // 	//userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-S SYS EQP CONCBL-KIT-CENT-6P EMGY SENS')[0])
    // }

    if (installation === 'ceiling') {
        // CEILING-OPTION 2
        //Wandhalter-Set Channel S (CHAN-S SYS EQP WM-KIT 105 / CHAN-S SYS EQP WM-KIT 50) wird nicht benötigt
        //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-S SYS EQP WM-KIT 105')[0])
        if (false) {
            for (let i = 0; i < Math.floor(totalProfileLength / 1500); i++) {
                userSystemEquipment.push(systemEquipment.filter((item) => item.article_code === 'CHAN-S SYS EQP WM-KIT 50')[0]);
            }
        }
    }

    //Profilverbinder-Set
    //Gesamt Profile pro Schenkel - 1
    let lastLine = 'L1';
    let profileCountPerLine = 0;
    for (let i = 0; i < profiles.length; i++) {
        if (lastLine === profiles[i].line) {
            profileCountPerLine++;
        } else {
            for (let i = 0; i < profileCountPerLine - 1; i++) {
                userSystemEquipment.push(systemEquipment.filter((item) => item.article_code === 'CHAN-S SYS EQP LIN-CON-KIT')[0]);
            }
            profileCountPerLine = 1;
            lastLine = profiles[i].line;
        }
    }
    for (let i = 0; i < profileCountPerLine - 1; i++) {
        userSystemEquipment.push(systemEquipment.filter((item) => item.article_code === 'CHAN-S SYS EQP LIN-CON-KIT')[0]);
    }

    //Stromschienen-Set Channel S
    //nicht benötigt!
    //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-S SYS EQP INS-KIT GLOPULSE 600')[0])
    //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-S SYS EQP INS-KIT GLOPULSE 1200')[0])

    //Diffusor Channel S & Diffusor Channel S Office
    //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-SO SYS EQP DIF-19202 RUN+')[0])
    //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-SO SYS EQP DIF-9604 RUN+')[0])
    //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-SO SYS EQP DIF-4506 RUN+')[0])
    //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-SO SYS EQP DIF-3004 RUN+')[0])
    //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-SO SYS EQP DIF-3608 RUN+')[0])
    //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-SO SYS EQP DIF-2406 RUN+')[0])
    //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-S SYS EQP DIF-24002 RUN')[0])
    //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-S SYS EQP DIF-7204 RUN')[0])
    //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-S SYS EQP DIF-3610 RUN')[0])
    //userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'CHAN-S SYS EQP DIF-2410 RUN')[0])
    let diffusorRUN = systemEquipment.filter((item) => item.diffusor === 'RUN');
    let diffusorRUNPlus = systemEquipment.filter((item) => item.diffusor === 'RUN+');
    let orderedDiffusorLengths = diffusorLengths.sort((a, b) => b.length - a.length);

    diffusorRUN.sort((a, b) => {
        return a.length - b.length;
    });
    diffusorRUNPlus.sort((a, b) => {
        return a.length - b.length;
    });

    let neededDifs = [];
    let rest = [];

    for (let i = 0; i < orderedDiffusorLengths.length; i++) {
        let skipDiffusorAddition = false;

        //iterate over rest to check if current profile could fit inside rest of some other diffusor
        for (let r = 0; r < rest.length; r++) {
            if (rest[r] >= orderedDiffusorLengths[i].length) {
                rest[r] -= orderedDiffusorLengths[i].length;
                skipDiffusorAddition = true;
                break;
            }
        }

        if (diffusor.toLowerCase() === 'run+' && !skipDiffusorAddition) {
            for (let y = 0; y < diffusorRUNPlus.length; y++) {
                if (diffusorRUNPlus[y].length >= orderedDiffusorLengths[i].length) {
                    neededDifs.push(diffusorRUNPlus[y]);

                    rest.push(diffusorRUNPlus[y].length - orderedDiffusorLengths[i].length);
                    break;
                }
            }
        } else if (diffusor.toLowerCase() === 'run' && !skipDiffusorAddition) {
            for (let y = 0; y < diffusorRUN.length; y++) {
                if (diffusorRUN[y].length >= orderedDiffusorLengths[i].length) {
                    neededDifs.push(diffusorRUN[y]);

                    rest.push(diffusorRUN[y].length - orderedDiffusorLengths[i].length);
                    break;
                }
            }
        }
    }
    neededDifs.map((dif) => userDiffusors.push(dif));

    //console.log(systemEquipment);
    //console.log(userSystemEquipment);
    state.userSystemEquipment = JSON.stringify(userSystemEquipment);
    calculatePrices(state, userSystemEquipment);

    state.userDiffusors = JSON.stringify(userDiffusors);
    calculatePrices(state, userDiffusors);
}

// Profiles
/**
 * Calculates Profiles for Line 1 of L, S, Z, U
 * @param length
 * @param biggestIntendProfile
 * @param profileProducts
 * @param intendProfiles
 * @param intendProfilesLengths
 * @param centProfiles
 * @param centProfilesLengths
 * @param installation
 * @param line
 */
function calculateProfilesStart(
    length,
    biggestIntendProfile,
    profileProducts,
    intendProfiles,
    intendProfilesLengths,
    centProfiles,
    centProfilesLengths,
    installation,
    line,
    zuschnittProfiles
) {
    // Die Anbauversion (CW) 135mm wird auch für die Pendelversion genutzt.
    // --> only check for recessed (145mm) else 135mm for pendulum or ceiling
    //CR ist für den Einbau
    //CR = Ceiling Recessed
    //CW = Ceiling Wall

    let rest = length - (installation === 'CR' ? CS_RECESSED_CORNER_LEG : CS_STANDARD_CORNER_LEG);
    calculateProfiles(rest, centProfilesLengths, profileProducts, centProfiles, installation, line, zuschnittProfiles);
}

/**
 * Calculates Profiles for last Line of L, S, Z, U
 * @param length
 * @param biggestIntendProfile
 * @param profileProducts
 * @param intendProfiles
 * @param intendProfilesLengths
 * @param centProfiles
 * @param centProfilesLengths
 * @param installation
 * @param line
 */
function calculateProfilesEnd(
    length,
    biggestIntendProfile,
    profileProducts,
    intendProfiles,
    intendProfilesLengths,
    centProfiles,
    centProfilesLengths,
    installation,
    line,
    zuschnittProfiles
) {
    // Die Anbauversion (CW) 135mm wird auch für die Pendelversion genutzt.
    // --> only check for recessed (145mm) else 135mm for pendulum or ceiling
    //CR ist für den Einbau
    //CR = Ceiling Recessed
    //CW = Ceiling Wall

    let rest = length - (installation === 'CR' ? CS_RECESSED_CORNER_LEG : CS_STANDARD_CORNER_LEG);
    calculateProfiles(rest, centProfilesLengths, profileProducts, centProfiles, installation, line, zuschnittProfiles);
}

/**
 * Calculates Profiles for Line 2 of S, Z, U & all lines of O
 * @param length
 * @param biggestCentProfile
 * @param profileProducts
 * @param centProfiles
 * @param centProfilesLengths
 * @param installation
 * @param line
 */
function calculateProfilesBetween(length, biggestCentProfile, profileProducts, centProfiles, centProfilesLengths, installation, line, zuschnittProfiles) {
    // Die Anbauversion (CW) 135mm wird auch für die Pendelversion genutzt.
    // --> only check for recessed (145mm) else 135mm for pendulum or ceiling
    //CR ist für den Einbau
    //CR = Ceiling Recessed
    //CW = Ceiling Wall

    let rest = length - 2 * (installation === 'CR' ? CS_RECESSED_CORNER_LEG : CS_STANDARD_CORNER_LEG);
    calculateProfiles(rest, centProfilesLengths, profileProducts, centProfiles, installation, line, zuschnittProfiles);
}

/**
 * Calculates Profiles for var rest
 * @param rest the value that needs to be calculated
 * @param profileLengths all profileLengths
 * @param profileProducts array to push product to
 * @param profiles all profiles
 * @param installation installation
 * @param line
 */
function calculateProfiles(rest, profileLengths, profileProducts, profiles, installation, line, zuschnittProfiles) {
    while (rest > 0) {
        for (let i = profileLengths.length - 1; i >= 0; i--) {
            let currentValue = profileLengths[i];
            // All variable profile have the same size of 4500 CH-S
            if (rest < zuschnittProfiles[0].length && rest - currentValue > 500) {
                break;
            } else if (rest - currentValue >= 0 && rest - currentValue > 500) {
                profileProducts.push({
                    product: profiles.filter((item) => item.installation === installation && item.length === currentValue)[0],
                    cut: null,
                    line: line,
                });
                rest -= currentValue;
                break;
            }
        }

        if (rest > 0 && rest < profileLengths[profileLengths.length - 1]) {
            if (rest < 3001) {
                if (installation === 'CR') {
                    profileProducts.push({
                        product: zuschnittProfiles.filter((item) => item.installation === installation && item.matchcode === '97TPR.2X.V0-3000')[0],
                        cut: rest,
                        line: line,
                    });
                } else {
                    profileProducts.push({
                        product: zuschnittProfiles.filter((item) => item.installation === installation && item.matchcode === '97TPR.4X.V0-3000')[0],
                        cut: rest,
                        line: line,
                    });
                }
            } else {
                if (installation === 'CR') {
                    profileProducts.push({
                        product: zuschnittProfiles.filter((item) => item.installation === installation && item.matchcode === '97TPR.2X.V3001-4499')[0],
                        cut: rest,
                        line: line,
                    });
                } else {
                    profileProducts.push({
                        product: zuschnittProfiles.filter((item) => item.installation === installation && item.matchcode === '97TPR.4X.V3001-4499')[0],
                        cut: rest,
                        line: line,
                    });
                }
            }
            rest = 0;
        }
    }
}
