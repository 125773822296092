import Button from "./Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState} from "react";
import {useDispatch, useSelector, useStore} from "react-redux";
import {Link} from "react-router-dom";
import {
	setShowLoadingModal,
	selectLoadingFinished,
	setLoadingFinished, setShowDownloadModal, setShowConfigLoadingModal,
} from "../features/products/productsSlice";

export default function LoadingModal({action}) {
	const dispatch = useDispatch();
	const store = useStore();
	// const isLoadingFinished = true;
	const isLoadingFinished = useSelector(selectLoadingFinished);
	const [closeDownloadModal, setCloseDownloadModal] = useState(false);
	
	setTimeout(function () {
		setCloseDownloadModal(true);
	}, 40000);
	
	return (
		<div className="fixed inset-0 z-40 flex flex-wrap justify-center content-center">
		
				<div className="py-7 px-10 z-50 relative shadow-line-number w-[580px] bg-white text-black" >
					{isLoadingFinished === true && action === 'formSend' && <FontAwesomeIcon className="cursor-pointer text-22 absolute right-3 top-2" icon={['fal', 'fa-times']} onClick={() => closeLoadingModal()}/> }
					{closeDownloadModal && action === 'downloads' && <FontAwesomeIcon className="cursor-pointer text-22 absolute right-3 top-2" icon={['fal', 'fa-times']} onClick={() => closeLoadingModal()}/> }
					<div className="flex flex-col mx-auto">
						<div className="font-medium text-22">					
							{(action === "formSend" && isLoadingFinished !== true) && 'Ihre Anfrage für das Projekt wird gesendet...'}
							{(action === "formSend" && isLoadingFinished === true) && 'Ihre Anfrage für das Projekt wurde erfolgreich versendet.'}
							{(action === "downloads" && isLoadingFinished === true) && 'Ihr Download wurde generiert.'}
							{(action === "configLoad") && <p>Ihre Konfiguration wird geladen...<br />Bitte warten.</p>}
							{(action === "downloads" && isLoadingFinished !== true) && <p>Ihre Downloads werden generiert...<br />Dies kann bis zu 30 Sekunden dauern.</p>}
						</div>
						
						{action === 'formSend' &&
							<>
								{isLoadingFinished !== true && <div className="w-full mt-6 flex justify-center">
									<div className="lds-facebook w-full"><div></div><div></div><div></div></div>
								</div>}
							</>
						}
						
						{action !== 'formSend' &&
							isLoadingFinished !== true &&
							<div className="w-full mt-6 flex justify-center">
								<div className="lds-facebook w-full"><div></div><div></div><div></div></div>
							</div>
						}
						
					</div>
					<div className="text-right pt-6">
						{((isLoadingFinished && action === 'formSend') || closeDownloadModal) && 
							<Button className={"text-20 bg-black text-white border inline-block py-2 px-9 cursor-pointer transition-all duration-300 hover:brightness-75 border-black"} onClick={() => 
								closeLoadingModal()
							}>
								Schließen
							</Button>
						}
					</div>
				</div>
			{((isLoadingFinished !== true && action === 'formSend') || !closeDownloadModal) && <div className="popup z-30 fixed inset-0 flex-wrap justify-center bg-black bg-opacity-50" id="popup-darken"/>}
			{((isLoadingFinished === true && action === 'formSend') || closeDownloadModal) && <div className="popup z-40 fixed inset-0 flex-wrap justify-center bg-black bg-opacity-50" id="popup-darken" onClick={() => closeLoadingModal()}/>}
		</div>
	);

	function closeLoadingModal() {
		dispatch(setShowLoadingModal(false));
		dispatch(setLoadingFinished(false));
		dispatch(setShowDownloadModal(false));
		dispatch(setShowConfigLoadingModal(false));
	}
}