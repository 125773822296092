import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BASE_URL } from '../services/domainService';

export let translatedHint = BASE_URL.includes('laravel') || BASE_URL.includes('staging') ? '🇨🇭' : '';

export default function Translator(props) {
    let intl = useIntl();
    let translatedHint = '🇨🇭';
    if (BASE_URL.includes('laravel') || BASE_URL.includes('staging')) {
        switch (intl.locale) {
            case 'de':
                translatedHint = '🇨🇭';
                break;
            case 'fr':
                translatedHint = '🇫🇷';
                break;
            case 'en':
                translatedHint = '🇬🇧';
                break;
            case 'it':
                translatedHint = '🇮🇹';
                break;
            default:
                translatedHint = '🇨🇭';
                break;
        }
    } else {
        translatedHint = '';
    }
    return (
        <FormattedMessage values={{ status: translatedHint }} {...props}>
            {props.children}
        </FormattedMessage>
    );
}

/**
 * Translate using API outside of components
 * @param {*} intl
 * @param {string} id
 * @returns
 */
export function imperativeTranslateMessage(intl, id) {
    let translatedHint = '🇨🇭';
    if (BASE_URL.includes('laravel') || BASE_URL.includes('staging')) {
        switch (intl.locale) {
            case 'de':
                translatedHint = '🇨🇭';
                break;
            case 'fr':
                translatedHint = '🇫🇷';
                break;
            case 'en':
                translatedHint = '🇬🇧';
                break;
            case 'it':
                translatedHint = '🇮🇹';
                break;
            default:
                translatedHint = '🇨🇭';
                break;
        }
    } else {
        translatedHint = '';
    }
    return intl.formatMessage({ id }, { status: translatedHint });
}
