import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import Translator from './Translator';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrency, selectLanguage, setCurrency, setLanguage } from '../features/products/productsSlice';
import Button from './Button';
import { BASE_URL } from '../services/domainService';
/**
 *
 * @param {{active: boolean}} props
 * @returns
 */
const RadioButton = (props) => {
    const active = props.active;
    return (
        <div className="relative top-[2px]">
            {active ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                    <circle cx="12" cy="12" r="10" fill="none" stroke="black" strokeWidth="2" />

                    <circle cx="12" cy="12" r="6" fill="black" />
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                    <circle cx="12" cy="12" r="10" fill="none" stroke="black" strokeWidth="2" />
                </svg>
            )}
        </div>
    );
};

export const LanguagePopup = () => {
    const dispatch = useDispatch();
    const [active, setActive] = useState(false);
    const languageSelection = useSelector(selectLanguage);
    const currencySelection = useSelector(selectCurrency);
    return (
        <>
            <div className="flex py-3">
                <button
                    className="flex flex-row mr-3"
                    onClick={() => {
                        setActive(!active);
                    }}
                >
                    <div className="text-white uppercase">{languageSelection}</div>;
                    <span className="">
                        <FontAwesomeIcon className="relative align-sub top-[-2px] pt-1 text-white" icon={['far', 'globe']} />
                    </span>
                </button>
            </div>
            {!active ? (
                <div className="popup flex justify-self-center align-center text-left" id="popup"></div>
            ) : (
                <>
                    <div className="fixed z-40 inset-0 bg-black bg-opacity-50" onClick={() => setActive(false)}></div>
                    <div className="popup flex justify-self-center align-center text-left" id="popup">
                        <div className="fixed form-popup justify-center top-1/3 left-[40%] w-96 h-64 z-50 bg-white text-white" id="customCode">
                            <Button
                                className="absolute top-2 right-3 text-22 text-black cursor-pointer hover:block hover:text-gray-600"
                                onClick={() => {
                                    setActive(false);
                                }}
                            >
                                <FontAwesomeIcon icon={['fal', 'times']} />
                            </Button>{' '}
                            <div
                                className={
                                    'text-black grid gap-1 font-medium ' +
                                    (BASE_URL.includes('laravel') || BASE_URL.includes('staging') ? 'grid-cols-2' : 'grid-cols-1')
                                }
                            >
                                {BASE_URL.includes('laravel') || BASE_URL.includes('staging') ? (
                                    <div>
                                        <div className="p-4 uppercase text-sm">Sprache</div>
                                        <div className="bg-popup-gray grid grid-rows-4 p-4 text-base h-full">
                                            <Button className="flex gap-1 cursor-pointer" onClick={() => dispatch(setLanguage('de'))}>
                                                <RadioButton active={languageSelection === 'de'} />
                                                <div>Deutsch</div>
                                            </Button>
                                            <Button className="flex gap-1 cursor-pointer" onClick={() => dispatch(setLanguage('en'))}>
                                                <RadioButton active={languageSelection === 'en'} />
                                                <div>English</div>
                                            </Button>
                                            <Button className="flex gap-1 cursor-pointer" onClick={() => dispatch(setLanguage('fr'))}>
                                                <RadioButton active={languageSelection === 'fr'} />
                                                <div>Français</div>
                                            </Button>
                                            <Button className="flex gap-1 cursor-pointer" onClick={() => dispatch(setLanguage('it'))}>
                                                <RadioButton active={languageSelection === 'it'} />
                                                <div>Italiano</div>
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}
                                <div>
                                    <div className="p-4 uppercase text-sm">Währung</div>
                                    <div className="bg-popup-gray grid grid-rows-4 p-4 text-base h-full">
                                        <Button className="flex gap-1 cursor-pointer" onClick={() => dispatch(setCurrency('euro'))}>
                                            <RadioButton active={currencySelection === 'euro'} />
                                            <div>Euro</div>
                                        </Button>
                                        <Button className="flex gap-1 cursor-pointer" onClick={() => dispatch(setCurrency('chf'))}>
                                            <RadioButton active={currencySelection === 'chf'} />
                                            <div>CHF</div>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
