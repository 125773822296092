import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectDrawingCoordinates,
    selectRulesetName,
    setEquipment,
    setProfiles,
    setRulesetLightingSystemElements,
    setSystemComponents,
    setSystemEquipment,
} from '../features/products/productsSlice';
import { selectRulesetID } from '../features/configuration/configurationSlice';
import {
    loadChannelSLightingSystem,
    loadEquipment,
    loadFlowLightingSystem,
    loadProfiles,
    loadPureliteLightingSystem,
    loadPureliteSlimLightingSystem,
    loadSystemComponents,
    loadSystemEquipment,
} from '../services/service';
import SidebarCalculatedValues from '../components/SidebarCalculatedValues';
import SidebarConfiguration from '../components/SidebarConfiguration';
import InputCanvas from '../components/InputCanvas';
import { useBackListener } from '../services/useBackListener';

export default function InputScreen() {
    const dispatch = useDispatch();

    //from configuration
    const rulesetID = useSelector(selectRulesetID);
    const rulesetName = useSelector(selectRulesetName);

    //from products
    const drawingCoordinates = useSelector(selectDrawingCoordinates);

    //Listener for Browser Back Function
    useBackListener();

    useEffect(() => {
        if (rulesetName === 'Channel S') {
            loadChannelSLightingSystem({ id: rulesetID }).then((result) => {
                dispatch(setRulesetLightingSystemElements(result.data));
            });
        } else if (rulesetName === 'Purelite Slim') {
            loadPureliteSlimLightingSystem({ id: rulesetID }).then((result) => {
                dispatch(setRulesetLightingSystemElements(result.data));
            });
        } else if (rulesetName === 'Flow') {
            loadFlowLightingSystem({ id: rulesetID }).then((result) => {
                dispatch(setRulesetLightingSystemElements(result.data));
            });
        } else if (rulesetName === 'Purelite') {
            loadPureliteLightingSystem({ id: rulesetID }).then((result) => {
                dispatch(setRulesetLightingSystemElements(result.data));
            });
        }

        loadProfiles({ id: rulesetID }).then((result) => {
            dispatch(setProfiles(JSON.stringify(result.data)));
        });

        loadEquipment({ id: rulesetID }).then((result) => {
            dispatch(setEquipment(JSON.stringify(result.data)));
        });

        loadSystemComponents({ id: rulesetID }).then((result) => {
            dispatch(setSystemComponents(JSON.stringify(result.data)));
        });

        loadSystemEquipment({ id: rulesetID }).then((result) => {
            dispatch(setSystemEquipment(JSON.stringify(result.data)));
        });
    }, []);

    return (
        <div className="absolute inset-0 bg-white text-black flex flex-wrap">
            {drawingCoordinates && <SidebarConfiguration />}
            <InputCanvas className={drawingCoordinates ? '' : ' !w-full'} />
            {drawingCoordinates && <SidebarCalculatedValues />}
        </div>
    );
}
