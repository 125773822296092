import React from 'react';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import configurationReducer from '../features/configuration/configurationSlice';
import productsReducer from '../features/products/productsSlice';
import { PersistGate } from 'redux-persist/integration/react';
import { combineReducers } from 'redux';
import { Provider } from 'react-redux';
import App from './../App';
import createMigrate from 'redux-persist/es/createMigrate';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { migrations } from './migrations';

const rootReducer = combineReducers({ configuration: configurationReducer, products: productsReducer });

const persistConfig = {
    key: 'root',
    version: 4,
    storage,
    stateReconciler: autoMergeLevel2,
    migrate: createMigrate(migrations),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

let persistor = persistStore(store);

export const PersistedStore = (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
);
