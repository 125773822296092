import React, { useEffect, useState } from 'react';
import Configurator from './containers/Configurator';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Components from './containers/Components';
import './assets/fonts/stylesheet.css';
import Header from './components/Header';
import InputScreen from './containers/InputScreen';

//Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft, faTimes as faTimesLight } from '@fortawesome/pro-light-svg-icons';
import { faShieldHalved, faEye, faBoltLightning, faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import {
    faRotateRight,
    faLightbulbOn,
    faBatteryBolt,
    faBrightnessLow,
    faPersonRunning,
    faEdit,
    faTimes,
    faCircleDot,
    faPlus,
    faMinus,
    faPipe,
    faAngleLeft as faAngleLeftRegular,
    faSquareBolt,
    faGridDividers,
    faCopy,
    faXmark,
    faCheck,
    faPen,
    faDownload,
    faFile,
    faFilePdf,
    faLightCeiling,
    faFileArchive,
    faGlobe,
    faDiagramNested,
    faLightEmergencyOn,
} from '@fortawesome/pro-regular-svg-icons';
import Result from './containers/Result';
import { getGeolocationData } from './services/service';
import { useDispatch, useSelector } from 'react-redux';
import { setCountryCode } from './features/configuration/configurationSlice';
import { IntlProvider } from 'react-intl';
import german from './resources/de.json';
import english from './resources/en.json';
import french from './resources/fr.json';
import italian from './resources/it.json';
import Test from './containers/Test';
import { selectLanguage } from './features/products/productsSlice';
library.add(
    faCheckCircle,
    faAngleLeft,
    faRotateRight,
    faLightbulbOn,
    faShieldHalved,
    faBatteryBolt,
    faBrightnessLow,
    faPersonRunning,
    faEdit,
    faTimes,
    faTimesLight,
    faCircleDot,
    faPlus,
    faMinus,
    faPipe,
    faAngleLeftRegular,
    faSquareBolt,
    faGridDividers,
    faCopy,
    faXmark,
    faCheck,
    faPen,
    faEye,
    faBoltLightning,
    faDownload,
    faFile,
    faFilePdf,
    faLightCeiling,
    faFileArchive,
    faGlobe,
    faDiagramNested,
    faLightEmergencyOn
);

const containerHeight = {
    height: 'calc(100vh - 84px)', //Header Height
};

export default function App() {
    const dispatch = useDispatch();
    const [width, setWidth] = useState(window.innerWidth);

    const locale = useSelector(selectLanguage);
    const messages = {
        de: german,
        en: english,
        it: italian,
        fr: french,
    };

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = width < 1270;

    useEffect(() => {
        getGeolocationData().then((result) => {
            if (result !== undefined) {
                if (result?.data !== undefined) {
                    if (result.data.country_code !== undefined) {
                        dispatch(setCountryCode(result.data.country_code));
                    }
                }
            }
        });
    }, []);

    return (
        <main className="font-regular">
            <IntlProvider locale={locale} messages={messages[locale]}>
                <BrowserRouter>
                    <Header />
                    <div className="bg-black text-white relative overflow-y-scroll" style={containerHeight}>
                        {isMobile && (
                            <div className="h-full flex flex-wrap justify-center items-center px-4">
                                <h2 className="text-28">
                                    Der Konfigurator ist für eine Nutzung als Desktop-App ausgelegt.
                                    <br />
                                    Bitte benutzen Sie einen größeren Monitor.
                                </h2>
                            </div>
                        )}
                        {!isMobile && (
                            <div className="max-w-screen-xl py-8 mx-auto px-4">
                                <Routes>
                                    <Route path="/" element={<Configurator />} />
                                    <Route path="/components" element={<Components />} />
                                    <Route path="/inputscreen" element={<InputScreen />} />
                                    <Route path="/result" element={<Result />} />
                                    <Route path="/test" element={<Test />} />
                                </Routes>
                            </div>
                        )}
                    </div>
                </BrowserRouter>
            </IntlProvider>
        </main>
    );
}
