import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Backlink(props) {
	return (
		<span>
			<span>
				<FontAwesomeIcon icon={["fal", "angle-left"]} />
			</span>
			{props.label ? props.label : "Zurück"}
		</span>
	)
}