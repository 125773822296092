import NumberFormat from 'react-number-format';
import { showLengthPopup, SWISSCODE } from '../../services/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsCHF, selectMaxLengthConfiguration, selectProductsState, selectRuleset, selectRulesetName } from '../../features/products/productsSlice';
import { selectConfigurationDiffusor, selectCountryCode } from '../../features/configuration/configurationSlice';
import Translator from '../Translator';

export default function LineInput({
    transform,
    x,
    y,
    focus = false,
    inputRef,
    inputVal,
    setInputVal,
    setShowLine,
    minLineVal,
    setSmallerLine,
    setLineLength,
    setBiggerLine,
    focusInput,
    lineIsSet,
    setLineIsSet,
    inputStyle,
    setStyle,
}) {
    // Handle the maximum line length which is different according to product line and optics
    let maxLength = useSelector(selectMaxLengthConfiguration);
    let diffusor = useSelector(selectConfigurationDiffusor);
    const ruleset = useSelector(selectRulesetName);
    const state = useSelector(selectProductsState);
    // Country specific formatting
    const countryCode = useSelector(selectCountryCode);
    let isLanguageCH = useSelector(selectIsCHF);
    let thousandSeparator = isLanguageCH ? "'" : '.';
    let decimalSeparator = isLanguageCH ? ',' : ',';
    const invisiblePopupStyle = 'popup-invisible invisible';

    return (
        <>
            <foreignObject x={x} y={y} width={140} height={100} transform={transform}>
                <div className={inputStyle === undefined ? invisiblePopupStyle : inputStyle}>
                    <div className="text-center text-xs text-black">
                        <Translator id="canvas.input.advice" />
                    </div>
                </div>
                <div className="fixed w-full h-full">
                    <div xmlns="http://www.w3.org/1999/xhtml" className="relative">
                        <NumberFormat
                            getInputRef={inputRef}
                            className="text-black w-full py-2 pl-2 pr-8 text-14 shadow-line-input border border-line-input outline-none text-center focus:border-black"
                            value={inputVal}
                            placeholder="Länge in mm"
                            displayType={'input'}
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                            decimalScale={0}
                            suffix={' mm'}
                            onValueChange={(value) => {
                                setInputVal(value.value);
                                showLengthPopup(setShowLine, minLineVal, value.value, setSmallerLine, setLineLength, setBiggerLine, diffusor, ruleset, state);
                            }}
                            onClick={(values) => {
                                focusInput();
                                showLengthPopup(setShowLine, minLineVal, inputVal, setSmallerLine, setLineLength, setBiggerLine, diffusor, ruleset, state);
                            }}
                            autoFocus={focus}
                            isAllowed={({ floatValue }) => floatValue === undefined || floatValue <= 999999}
                            onBlur={() => {
                                setShowLine(false);
                            }}
                            onKeyPress={(e) => {
                                if (e.code === 'Enter' || (e.charCode === 13 && e.location === 3)) {
                                    let currentVal = inputVal;
                                    setInputVal(currentVal);
                                    showLengthPopup(
                                        setShowLine,
                                        minLineVal,
                                        currentVal,
                                        setSmallerLine,
                                        setLineLength,
                                        setBiggerLine,
                                        diffusor,
                                        ruleset,
                                        state
                                    );
                                    if (currentVal >= minLineVal && currentVal <= maxLength) {
                                        setLineIsSet(true);
                                        if (setStyle !== undefined) {
                                            setStyle(invisiblePopupStyle);
                                        }
                                    }
                                }
                            }}
                        />
                        <span className="absolute right-2 top-1/2 -translate-y-1/2 pointer-events-none">
                            {lineIsSet && <FontAwesomeIcon className="text-green-600 pt-1" icon={['fas', 'circle-check']} />}
                            {!lineIsSet && <FontAwesomeIcon className="text-line-input" icon={['far', 'pen']} />}
                        </span>
                    </div>
                </div>
            </foreignObject>
        </>
    );
}
