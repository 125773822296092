import React from "react";
import {Link} from "react-router-dom";
import Backlink from "../components/Backlink";
import Button from "../components/Button";
import Header from "../components/Header";
import Input from "../components/Input";
import Label from "../components/Label";
import LoadingIndicator from "../components/LoadingIndicator";
import TextArea from "../components/TextArea";
import TextLink from "../components/TextLink";


export default function Components() {
	
	return (
		<div>
			<Link to="/" className="text-2xl underline mt-5 block">Zurück</Link>
			
			<h2 className="text-5xl mt-5">Komponenten</h2>
			
			<div className="mt-8">
				<h3 className="text-2xl mt-10 font-bold">Backlink</h3>
				<Backlink label="Zurück"/>
				<br />
				<h3 className="text-2xl mt-10 font-bold">Button</h3>
				<Button className="button" onClick={() => console.log('click')}>Das ist ein Button</Button>
				<br/>
				<h3 className="text-2xl mt-10 font-bold">Header</h3>
				<Header />
				<br/>
				<h3 className="text-2xl mt-10 font-bold">Input</h3>
				<Input type="email" />
				<br/>
				<h3 className="text-2xl mt-10 font-bold">Label</h3>
				<Label>Das ist ein Label</Label>
				<br/>
				<h3 className="text-2xl mt-10 font-bold">LoadingIndicator</h3>
				<LoadingIndicator />
				<br/>
				<h3 className="text-2xl mt-10 font-bold">TextArea</h3>
				<TextArea placeholder="Bitte Text eingeben" className="border-2"/>
				<br />
				<h3 className="text-2xl mt-10 font-bold">TextLink</h3>
				<TextLink onClick={() => console.log('Klick!')}>Klick mich (-> Console)</TextLink><br/>
				<TextLink disabled onClick={() => console.log('Klick!')}>Klick mich (Duplikat - Disabled)</TextLink>
			</div>
		</div>
	)
}