import React from 'react';
import { useSelector } from 'react-redux';
import { selecSelectedVisibleLayer, selectRulesetName } from '../features/products/productsSlice';
import Translator from './Translator';

export default function Legend() {
    const activeLayer = useSelector(selecSelectedVisibleLayer);
    const currentRuleset = useSelector(selectRulesetName);

    return (
        <div className="text-black text-center absolute bottom-4 left-0 right-0 z-10">
            <span className="font-medium mr-5">
                <Translator id="canvas.legend.title" />
            </span>

            {activeLayer === 'optic' && (
                <span className="text-xs font-book mr-5">
                    {activeLayer && renderLegendSVG('#000000', '#FFFFFF')}
                    {activeLayer && renderLegendSVG('#000000', 'url(#hatching)')}
                    <Translator id="canvas.legend.profiles" />
                </span>
            )}
            {activeLayer === 'electric' && (
                <>
                    {currentRuleset === 'Channel S' && (
                        <>
                            <span className="text-xs font-book mr-5">
                                {activeLayer && renderLegendSVG('#000000', '#FFF303')}
                                <Translator id="canvas.legend.master.solo" />
                            </span>
                            <span className="text-xs font-book mr-5">
                                {activeLayer && renderLegendSVG('#000000', '#F63F3F')}
                                <Translator id="canvas.legend.master.expandable" />
                            </span>
                            <span className="text-xs font-book mr-5">
                                {activeLayer && renderLegendSVG('#000000', '#FEECC0')}
                                <Translator id="canvas.legend.slave.solo" />
                            </span>
                            <span className="text-xs font-book mr-5">
                                {activeLayer && renderLegendSVG('#000000', '#ABE444')}
                                <Translator id="canvas.legend.slave.expandable" />
                            </span>
                            <span className="text-xs font-book">
                                {activeLayer && renderLegendSVG('#000000', '#449BE4')}
                                <Translator id="canvas.legend.corner" />
                            </span>
                        </>
                    )}
                    {currentRuleset === 'Purelite Slim' || currentRuleset === 'Flow' ? (
                        <>
                            <span className="text-xs font-book mr-5">
                                {activeLayer && renderLegendSVG('#000000', '#F63F3F')}
                                <Translator id="canvas.legend.master" />
                            </span>
                            <span className="text-xs font-book mr-5">
                                {activeLayer && renderLegendSVG('#000000', '#ABE444')}
                                <Translator id="canvas.legend.slave" />
                            </span>
                        </>
                    ) : null}
                    {currentRuleset === 'Purelite' ? (
                        <>
                            <span className="text-xs font-book mr-5">
                                {activeLayer && renderLegendSVG('#000000', '#F63F3F')}
                                <Translator id="canvas.legend.master" />
                            </span>
                            <span className="text-xs font-book mr-5">
                                {activeLayer && renderLegendSVG('#000000', '#ABE444')}
                                <Translator id="canvas.legend.slave" />
                            </span>
                            <span className="text-xs font-book mr-5">
                                {activeLayer && renderLegendSVG('#000000', '#FFF303')}
                                <Translator id="canvas.legend.end" />
                            </span>
                        </>
                    ) : null}
                </>
            )}
        </div>
    );
}

function renderLegendSVG(stroke, fill) {
    return (
        <svg width={20} height={10} className="inline-block mr-1.5 align-text-top">
            <g strokeWidth="4">
                <rect x={0} y={0} width={20} height={10} stroke={stroke} fill={fill} />
            </g>
        </svg>
    );
}
