export const migrations = {
    2: (state) => {
        return {
            ...state,
            products: {
                ...state.products,
                language: 'de',
                currency: 'euro',
            },
        };
    },
    3: (state) => {
        return {
            ...state,
            configuration: {
                ...state.configuration,
                addWiring: false,
            },
        };
    },
    4: (state) => {
        return {
            ...state,
            configuration: {
                ...state.configuration,
                useEmergency: false,
            },
        };
    },
};
