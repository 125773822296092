import Line from "../Configuration/Line";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {checkInputs, getMinValueOfLine} from "../../services/service";
import LinePopup from "./LinePopup";
import LineInput from "./LineInput";
import { selectUserSelectedDiffusor } from "../../features/products/productsSlice";
import { selectRulesetID } from "../../features/configuration/configurationSlice";

export default function IForm() {
	
	const dispatch = useDispatch();
	
	//Line 1
	const [style1, setStyle1] = useState("popup");
	const [inputValLine1, setInputValLine1] = useState('');
	const [showLine1Popup, setShowLine1Popup] = useState(false);
	const [smallerLine1, setSmallerLine1] = useState(null);
	const [lengthLine1, setLengthLine1] = useState(null);
	const [biggerLine1, setBiggerLine1] = useState(null);
	const [line1IsSet, setLine1IsSet] = useState(false);
	const inputRefLine1 = useRef(null)
	const focusInputLine1 = () => inputRefLine1.current.focus();
	const diffusorType = useSelector(selectUserSelectedDiffusor);
	const ruleId = useSelector(selectRulesetID);
	const minLine1 = getMinValueOfLine(1, 'I', diffusorType, ruleId);
	
	useEffect(() => {
		checkInputs(dispatch, 1, line1IsSet, true, true, inputValLine1 );
	}, [line1IsSet]);
	
	return (
		<>
			<g>
				<Line x={0} y={0} height={10} width={500} border={'#000000'} color={'#FFFFFF'} rotation={0} elementLength={500} withLabel={false} />
				<LineInput lineIsSet={line1IsSet} x={185} y={30} focus={true} inputRef={inputRefLine1} inputStyle={style1} setStyle={setStyle1} inputVal={inputValLine1} setInputVal={setInputValLine1} setShowLine={setShowLine1Popup} minLineVal={minLine1} setSmallerLine={setSmallerLine1} setLineLength={setLengthLine1} setBiggerLine={setBiggerLine1} focusInput={focusInputLine1} setLineIsSet={setLine1IsSet}/>
			</g>
			
			<g>
				{showLine1Popup &&
					<LinePopup setShowLine={setShowLine1Popup} lineNumber={1} smallerLine={smallerLine1} lengthLine={lengthLine1} biggerLine={biggerLine1} setStyle={setStyle1} setInputVal={setInputValLine1} setLineIsSet={setLine1IsSet} lineIsSet={line1IsSet} x={76} y={-95} />
				}
			</g>
		</>
	);
}