import Line from "../Configuration/Line";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {checkInputs, getMinValueOfLine} from "../../services/service";
import LinePopup from "./LinePopup";
import LineInput from "./LineInput";
import { selectUserSelectedDiffusor } from "../../features/products/productsSlice";
import { selectRulesetID } from "../../features/configuration/configurationSlice";

export default function ZForm() {
	const dispatch = useDispatch();
	
	const diffusorType = useSelector(selectUserSelectedDiffusor);
	const ruleId = useSelector(selectRulesetID);

	//Line 1
	const [style1, setStyle1] = useState("popup");
	const [inputValLine1, setInputValLine1] = useState('');
	const [showLine1Popup, setShowLine1Popup] = useState(false);
	const [smallerLine1, setSmallerLine1] = useState(null);
	const [lengthLine1, setLengthLine1] = useState(null);
	const [biggerLine1, setBiggerLine1] = useState(null);
	const [line1IsSet, setLine1IsSet] = useState(false);
	const inputRefLine1 = useRef(null)
	const focusInputLine1 = () => inputRefLine1.current.focus();
	const minLine1 = getMinValueOfLine(1, 'Z', diffusorType, ruleId);
	
	//Line 2
	const [style2, setStyle2] = useState("popup");
	const [inputValLine2, setInputValLine2] = useState('');
	const [showLine2Popup, setShowLine2Popup] = useState(false);
	const [smallerLine2, setSmallerLine2] = useState(null);
	const [lengthLine2, setLengthLine2] = useState(null);
	const [biggerLine2, setBiggerLine2] = useState(null);
	const [line2IsSet, setLine2IsSet] = useState(false);
	const inputRefLine2 = useRef(null)
	const focusInputLine2 = () => inputRefLine2.current.focus();
	const minLine2 = getMinValueOfLine(2, 'Z', diffusorType, ruleId);
	
	//Line 3
	const [style3, setStyle3] = useState("popup");
	const [inputValLine3, setInputValLine3] = useState('');
	const [showLine3Popup, setShowLine3Popup] = useState(false);
	const [smallerLine3, setSmallerLine3] = useState(null);
	const [lengthLine3, setLengthLine3] = useState(null);
	const [biggerLine3, setBiggerLine3] = useState(null);
	const [line3IsSet, setLine3IsSet] = useState(false);
	const inputRefLine3 = useRef(null)
	const focusInputLine3 = () => inputRefLine3.current.focus();
	const minLine3 = getMinValueOfLine(3, 'Z', diffusorType, ruleId);
	
	useEffect(() => {
		checkInputs(dispatch, 3, line1IsSet, line2IsSet, line3IsSet, inputValLine1, inputValLine2, inputValLine3 );
	}, [line1IsSet, line2IsSet, line3IsSet]);
	
	return (
		<>
			<g>
				<Line x={0} y={0} height={10} width={300} border={'#000000'} color={'#FFFFFF'} rotation={0} elementLength={500} withLabel={false} />
				<LineInput lineIsSet={line1IsSet} x={85} y={30} focus={true} inputRef={inputRefLine1} inputStyle={style1} setStyle={setStyle1} inputVal={inputValLine1} setInputVal={setInputValLine1} setShowLine={setShowLine1Popup} minLineVal={minLine1} setSmallerLine={setSmallerLine1} setLineLength={setLengthLine1} setBiggerLine={setBiggerLine1} focusInput={focusInputLine1} setLineIsSet={setLine1IsSet}/>
			</g>
			
			<g>
				<Line x={300} y={10} height={10} width={500} border={'#000000'} color={'#FFFFFF'} rotation={90} elementLength={500} withLabel={false} />
				<LineInput lineIsSet={line2IsSet} x={330} y={250} inputRef={inputRefLine2} inputStyle={style2} setStyle={setStyle2} inputVal={inputValLine2} setInputVal={setInputValLine2} setShowLine={setShowLine2Popup} minLineVal={minLine2} setSmallerLine={setSmallerLine2} setLineLength={setLengthLine2} setBiggerLine={setBiggerLine2} focusInput={focusInputLine2} setLineIsSet={setLine2IsSet}/>
			</g>
			
			<g>
				<Line x={300} y={500} height={10} width={300} border={'#000000'} color={'#FFFFFF'} rotation={0} elementLength={300} withLabel={false} />
				<LineInput lineIsSet={line3IsSet} x={385} y={530} inputRef={inputRefLine3} inputStyle={style3} setStyle={setStyle3} inputVal={inputValLine3} setInputVal={setInputValLine3} setShowLine={setShowLine3Popup} minLineVal={minLine3} setSmallerLine={setSmallerLine3} setLineLength={setLengthLine3} setBiggerLine={setBiggerLine3} focusInput={focusInputLine3} setLineIsSet={setLine3IsSet}/>
			</g>
			
			<g>
				{showLine1Popup &&
					<LinePopup setShowLine={setShowLine1Popup} lineNumber={1} smallerLine={smallerLine1} lengthLine={lengthLine1} biggerLine={biggerLine1} setStyle={setStyle1} setInputVal={setInputValLine1} setLineIsSet={setLine1IsSet} lineIsSet={line1IsSet} x={-22} y={-95} />
				}
				{showLine2Popup &&
					<LinePopup setShowLine={setShowLine2Popup} lineNumber={2} smallerLine={smallerLine2} lengthLine={lengthLine2} biggerLine={biggerLine2} setStyle={setStyle2} setInputVal={setInputValLine2} setLineIsSet={setLine2IsSet} lineIsSet={line2IsSet} x={223} y={125} />
				}
				{showLine3Popup &&
					<LinePopup setShowLine={setShowLine3Popup} lineNumber={3} smallerLine={smallerLine3} lengthLine={lengthLine3} biggerLine={biggerLine3} setStyle={setStyle3} setInputVal={setInputValLine3} setLineIsSet={setLine3IsSet} lineIsSet={line3IsSet} x={278} y={405} />
				}
			</g>
		</>
	);
}