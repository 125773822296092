import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFeedPointPosition, setFeedPointPosition, setForm } from '../../features/configuration/configurationSlice';
import { selectUserSelectedForm } from '../../features/products/productsSlice';

/**
 /**
 * FeedPoint is a svg element used to signify where the power supply gets connect. It is selectable (at the moment only one at a time.)<br>
 There are two different renderings for the canvas svg and the installation map svg. The icon is offset in the installation map svg version. Because DOMPDF
 would render it falsely aligned if that wasn't the case.
 *
 * @export
 * @param {string | int} position
 * @param {int} x x coordinate of the upper left corner of each rectangular element
 * @param {int} y y coordinate of the upper left corner of each rectangular element
 * @param {int} width
 * @param {boolean} [isConfigurator=true] determines if FeedPoint should use the rendering styling of the canvas configurator (TRUE) or the installation map (FALSE)
 * @param {int} rotation is the drection the line goes that the element of the FeedPoint is a part of 0°:right 90°:down 180°:left 270°:up
 * @return {SVGElement}
 */
export default function FeedPoint({ position, x, y, width, isConfigurator = true, rotation, selected }) {
    const dispatch = useDispatch();
    const currentPosition = useSelector(selectFeedPointPosition);
    const currentForm = useSelector(selectUserSelectedForm);

    let configX = x;
    let configY = y;

    useEffect(() => {
        if (selected) {
            dispatch(setFeedPointPosition(position));
        }
    }, []);

    const iconSizeX = 37; // INT: Holds X size of colored rectangle behind icon
    const iconSizeY = 22; // INT: Holds Y size of colored rectangle behind icon
    const feedSizeX = isConfigurator ? 30 : 0; // Used to offset icon according to size (Necessary to modify differently based on location canvas/installation map)
    const feedSizeY = isConfigurator ? 30 : 0; // Used to offset icon according to size (Necessary to modify differently based on location canvas/installation map)

    if (position === 'start' || position === 'end') {
        // Offset at the start and end of a installation
        configX = x - 15;
        configY = y - 50;
    } else {
        switch (rotation) {
            case 180: // LEFT
                configX = x - feedSizeX / 2 - (isConfigurator ? 0 : iconSizeX / 2);
                configY = y + feedSizeY / 2;
                break;
            case 270: // UP
                configX = x + feedSizeX / 2 + (isConfigurator ? 0 : iconSizeX / 2);
                configY = y - feedSizeY / 2 - (isConfigurator ? 0 : iconSizeY / 2);
                break;
            case 90: // DOWN
                configX = x + feedSizeX / 2 + (isConfigurator ? 0 : iconSizeX / 2);
                configY = y - feedSizeY / 2 - (isConfigurator ? 0 : iconSizeY / 2);
                break;
            default: // Right case // 0°
                configX = x - feedSizeX / 2 - (isConfigurator ? 0 : iconSizeX / 2);
                configY = y + feedSizeY / 2 + (isConfigurator ? 0 : iconSizeY / 2);
                break;
        }
    }

    if ((currentForm === 'I' || currentForm === 'L' || currentForm === 'Z') && position === 'end') {
        configX = x - 15 + width;
    } else if (currentForm === 'S' && position === 'end') {
        configX = x - 15 + width;
        configY = y + 30;
    } else if (currentForm === 'Z' && position === 'start') {
        configX = x - 15;
        configY = y + 30;
    } else if ((currentForm === 'L' || currentForm === 'U') && position === 'start') {
        configX = x + 20;
        configY = y - 15;
    } else if (currentForm === 'U' && position === 'end') {
        configY = y - width - 15;
        configX = x - 50;
    } else if (currentForm === 'O' && position === 'start') {
        configY = y - 110;
    }
    if (isConfigurator) {
        return (
            <foreignObject x={configX} y={configY} width={30} height={30}>
                <div
                    className={
                        'w-[30px] h-[30px] flex flex-wrap justify-center items-center border  cursor-pointer ' +
                        (currentPosition === position
                            ? 'bg-yellow shadow-feed-point border-black'
                            : 'bg-feed-point-disabled-bg text-feed-point-disabled-text border-feed-point-disabled-border')
                    }
                    onClick={() => {
                        console.log('Update feed point to: ' + position);
                        dispatch(setFeedPointPosition(position));
                    }}
                >
                    <FontAwesomeIcon icon={['far', 'battery-bolt']} />
                </div>
            </foreignObject>
        );
    } else {
        // Simplifed View Render used in installation map
        return (
            <g transform={'translate(' + configX + ' ' + configY + ')'}>
                <g transform={'translate(-5 +2)'}>
                    <rect width={37} height={22} fill={'yellow'} stroke={'black'} rx={5} ry={5} />
                </g>
                <g transform={'translate(0 -' + 0 + ') scale(0.05)'}>
                    <FontAwesomeIcon icon={['far', 'battery-bolt']} />
                </g>
            </g>
        );
    }
}
