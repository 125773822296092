import { current } from "@reduxjs/toolkit";
import { calculateDrawingCoordinates, calculateOperatingDevices, calculateOverallLumenAndWatt, calculatePrices, calculateProfileDrawingCoordinates, displayValues, getCorrectProfileLengths, mapElementsToProducts } from "./productsSlice";
/**
 * Calculates Purelite Slim
 * @param state
 */
export function doCalculationPureliteSlim(state) {
	let userLength = state.userLengths.line1;
	let rulesetConstants = current(state).constants;
	let lineLengths = JSON.parse(current(state).lineLengths);


	//defining arrays for calculating elements
	let elements = [];

	let master = lineLengths.filter(item => item.type === 'master');
	let slave = lineLengths.filter(item => item.type === 'slave');

	//sort arrays after line length desc
	master.sort((a, b) => b.length - a.length);
	slave.sort((a, b) => b.length - a.length);

	//resetting profileLengths & diffusorLengths
	state.profileLengths = null;
	state.diffusorLengths = null;

	calculateSingleLinePureliteSlim(state, userLength, rulesetConstants, master, slave, elements);

	//save calculated elements to state
	state.lineElements = JSON.stringify(elements);

	//resetting price
	state.productPriceEur = 0;
	state.productPriceChf = 0;


	mapElementsToProducts(state, elements);
	mapProfilesToProductsPureliteSlim(state);

	mapEQPToProductsPureliteSlim(state);
	mapSystemEquipmentToProductsPureliteSlim(state);

	state.isSimplifiedDrawing = true
	if (current(state).isSimplifiedDrawing) {
		calculateProfileDrawingCoordinates(state, false);
	}
	state.isSimplifiedDrawing = false
	calculateProfileDrawingCoordinates(state, false);
	calculateOverallLumenAndWatt(state);
	calculateOperatingDevices(state, elements);
	state.isSimplifiedDrawing = true
	if (current(state).isSimplifiedDrawing) {
		calculateDrawingCoordinates(state, elements, false);
	}
	state.isSimplifiedDrawing = false
	calculateDrawingCoordinates(state, elements, false);
}

/**
 * Calculates Single Line for Purelite Slim
 * @param state
 * @param userLength
 * @param rulesetConstants
 * @param master
 * @param slave
 * @param elements
 * @param lightLength
 */
export function calculateSingleLinePureliteSlim(state, userLength, rulesetConstants, master, slave, elements, lightLength = null) {
	//if we have a calculated lightLength, don't calculate it again
	if (lightLength === null) {
		//Calculate lightLength and saving debugging values
		lightLength = calculateValuesPureliteSlim(state, userLength, rulesetConstants);
	}
	calculateElementsPureliteSlim(elements, master, slave, lightLength);
}

/**
 * Calculates all needed values for Purelite Slim
 * @param state
 * @param userLength
 * @param rulesetConstants
 * @returns {number}
 */
export function calculateValuesPureliteSlim(state, userLength, rulesetConstants) {
	userLength = parseFloat(userLength);
	//calculating needed values
	let profileLength = userLength - 2 * rulesetConstants.endwallthickness;
	let recess = Math.ceil(profileLength + rulesetConstants.cutoutcoefficient + rulesetConstants.expansion * profileLength);
	let diffusorLength = Math.ceil(userLength - userLength * rulesetConstants.expansion);

	let lightLength;

	if ((profileLength - Math.floor(profileLength / 100 * 4) / 4 * 100) < 4) {
		lightLength = Math.floor((profileLength - 4) / 100 * 4) / 4 * 100;
	}
	else {
		lightLength = Math.floor(profileLength / 100 * 4) / 4 * 100;
	}

	if (state.userSelectedPureliteSlimLighting === 'direktstrahlend') {
		let end = 3;
		profileLength = userLength - 2 * end;

		// Modifier defined in Purelite Slim_Konfigurator_V1.6
		let diffusor_modifier = 0;
		if (userLength / 1000 > 0 && userLength / 1000 < 5) {
			diffusor_modifier = 5;
		}
		else if (userLength / 1000 >= 5 && userLength / 1000 < 10) {
			diffusor_modifier = 7;
		}
		else if (userLength / 1000 >= 10 && userLength / 1000 < 15) {
			diffusor_modifier = 10;
		}
		else if (userLength / 1000 >= 15 && userLength / 1000 < 20) {
			diffusor_modifier = 12;
		}
		else if (userLength / 1000 >= 15 && userLength / 1000 < 40) {
			diffusor_modifier = 15;
		}

		lightLength = (profileLength - Math.floor(profileLength / 100 * 4) / 4 * 100) < 4 ?
			Math.floor((profileLength - 4) / 100 * 4) / 4 * 100 :
			Math.floor((profileLength) / 100 * 4) / 4 * 100;

		recess = Math.ceil(userLength + rulesetConstants.cutoutcoefficient + rulesetConstants.expansion * profileLength);
		diffusorLength = userLength - 2 * diffusor_modifier;
	}

	//output debugging things
	let debuggingValues = displayValues('L1', userLength, recess, profileLength, 0, diffusorLength, lightLength, state);

	let currentLineValues = JSON.parse(state.lineValues) || [];
	currentLineValues.push(JSON.stringify(debuggingValues));
	state.lineValues = JSON.stringify(currentLineValues);

	//return lightLength to update variable in parent export function
	return lightLength;
}

/**
 * Calculates Elements for Purelite Slim
 * @param elements
 * @param master
 * @param slave
 * @param calcVal
 */
export function calculateElementsPureliteSlim(elements, master, slave, calcVal) {
	calcVal = calculateMasterElementsPureliteSlim(elements, master, calcVal);
	calculateSlaveElementsPureliteSlim(elements, calcVal, slave);
}

/**
 * Calculates Master Elements for Purelite Slim
 * @param elements
 * @param master
 * @param calcVal
 * @returns {*}
 */
export function calculateMasterElementsPureliteSlim(elements, master, calcVal) {
	let breakWhile = false;
	while (!breakWhile) {
		for (let i = 0; i < master.length; i++) {
			if (calcVal - master[i].length === 0 || calcVal - master[i].length >= 75) {
				elements.push(master[i]);
				calcVal -= master[i].length;
				break;
			}
			else {
				// If light is to big for the remaining length and we are at the last element break
				if (i === master.length - 1) {
					breakWhile = true;
					break;
				}
			}
		}
	}

	return calcVal;
}

/**
 * Calculates Slave Elements for Purelite Slim
 * @param elements
 * @param calcVal
 * @param slave
 */
export function calculateSlaveElementsPureliteSlim(elements, calcVal, slave) {
	for (let i = 0; i < slave.length; i++) {
		if (calcVal - slave[i].length === 0) {
			elements.push(slave[i]);
			calcVal -= slave[i].length;
			break;
		}
	}


	//todo delete, only for debug
	if (calcVal > 0) {
		alert('Kommt ein Rest raus: ' + calcVal);
	}
}

/**
 * Maps Equipment to Products
 * @param state
 */
function mapEQPToProductsPureliteSlim(state) {
	//products from ruleset
	let equipment = JSON.parse(state.equipment);
	let installation = state.userSelectedInstallation;
	let lighting = state.userSelectedPureliteSlimLighting;
	let profileLengths = getCorrectProfileLengths(state);
	let totalProfileLength = 0;

	//calculating total profile length
	profileLengths.map(profile => totalProfileLength += profile.profile);

	//calculated products for user
	let userEquipment = [];

	//Stirnseiten-Set
	if (lighting === 'direktstrahlend') {
		if (installation === 'recessed') {
			userEquipment.push(equipment.filter(item => item.article_nr === '2004.4078')[0]);
		}
		else if (installation === 'ceiling') {
			userEquipment.push(equipment.filter(item => item.article_nr === '2004.4077')[0]);
		}
	}
	else {
		userEquipment.push(equipment.filter(item => item.article_code === 'PURLT S EQP FRONTEND-KIT')[0]);
	}

	//Einspeise-Set
	userEquipment.push(equipment.filter(item => item.article_code === 'PURLT S EQP INFD-SET')[0]);

	//Befestigungs-Set Purelite Slim D, FASTEN-KIT alle 1.5m
	if (installation === "recessed") {
		for (let i = 0; i < Math.floor(totalProfileLength / 1500); i++) {
			// IF selection uses CT // TODO CT/TL selection
			if (installation === 'recessed') {
				userEquipment.push(equipment.filter(item => item.article_nr === '2004.4082')[0]); // PURLT S EQP FASTEN-KIT CT
			}
			// If selection uses TL
			else if (installation === 'ceiling' || installation === 'pendulum') { // TODO selection
				userEquipment.push(equipment.filter(item => item.article_nr === '2004.4083')[0]); // PURLT S EQP FASTEN-KIT TL
			}
		}
	}


	if (installation === "ceiling") {
		// STANDARD: Schlitzscheiben-Set
		if (true) {
			let neededSuspensions = Math.ceil(totalProfileLength / 1500);
			while (neededSuspensions > 0) {
				if (neededSuspensions >= 5) {
					userEquipment.push(equipment.filter(item => item.article_code === 'PURLT EQP WSH-SLO-KIT 5PCS')[0]);
					neededSuspensions -= 5;
				}
				else {
					userEquipment.push(equipment.filter(item => item.article_code === 'PURLT EQP WSH-SLO-KIT 2PCS')[0]);
					neededSuspensions -= 2;
				}
			}
			// let totalLengthForSchlitzscheiben = totalProfileLength;
			// while (totalLengthForSchlitzscheiben > 0) {
			// 	if (totalLengthForSchlitzscheiben < 2000) {
			// 		userEquipment.push(equipment.filter(item => item.article_code === 'PURLT EQP WSH-SLO-KIT 2PCS')[0]);
			// 		totalLengthForSchlitzscheiben = 0;
			// 	}
			// 	else if (totalLengthForSchlitzscheiben >= 2000 && totalLengthForSchlitzscheiben <= 4500) {
			// 		userEquipment.push(equipment.filter(item => item.article_code === 'PURLT EQP WSH-SLO-KIT 2PCS')[0]);
			// 		userEquipment.push(equipment.filter(item => item.article_code === 'PURLT EQP WSH-SLO-KIT 2PCS')[0]);
			// 		totalLengthForSchlitzscheiben = 0;
			// 	}
			// 	else {
			// 		userEquipment.push(equipment.filter(item => item.article_code === 'PURLT EQP WSH-SLO-KIT 5PCS')[0]);
			// 		totalLengthForSchlitzscheiben -= 4500;
			// 	}
			// }
		}
		// OPTION 1 : Befestigungsfeder not accessible yet:
		//Befestigungsfeder Channel S, alle 1.5 Meter. Nur Decken/Wandanbau mit Unebenheit
		else if (false) {
			for (let i = 0; i < Math.floor(totalProfileLength / 1500); i++) {
				userEquipment.push(equipment.filter(item => item.article_code === 'PURLT S EQP MNTSPRING')[0]);
			}
		}
	}
	//Aufhängungs-Set
	//calculating needed suspensions (1 every 1.5m)
	if (installation === "pendulum") {
		let neededSuspensions = Math.ceil(totalProfileLength / 1500);
		for (let i = 0; i < neededSuspensions; i++) {
			if (i === 0) {
				userEquipment.push(equipment.filter(item => item.article_code === 'PURLT S EQP SUSP-KIT-1500-WITHCBL/5P')[0]);
			}
			else {
				userEquipment.push(equipment.filter(item => item.article_code === 'PURLT S EQP SUSP-KIT-1500')[0])
				i++; // Increment iterator because this article is a 2-Set
			}
		}

		// Pendulum needs 1x Baldachin-Set (for now)
		//Baldachin Set
		let numberBaldachinSets = 1;
		// let numberBaldachinSets = Math.ceil(operatingDevices/10);	//OLD: nach 10 operatingDevices neue Einspeisung
		for (let i = 0; i < 1; i++) {
			userEquipment.push(equipment.filter(item => item.article_code === 'UNVERSL EQP CANOPY-KIT-132x56x25-RAL9016')[0]);
		}
	}

	//Zuschneidehilfe, nur bei RUN
	if (lighting === 'direktstrahlend') {
		userEquipment.push(equipment.filter(item => item.article_code === 'PURLTD S EQP CUTT AID')[0]);
	}

	// Always include disassembly tool Channel S & Purelite (Slim)
	userEquipment.push(equipment.filter(item => item.article_code === 'PURLT CHAN-S EQP TOOL YE')[0]);

	state.userEQP = JSON.stringify(userEquipment);
	calculatePrices(state, userEquipment);
}

/**
 * Maps SystemComponents to Products
 * * @param state
 */
function mapSystemEquipmentToProductsPureliteSlim(state) {
	let systemEquipment = JSON.parse(state.systemEquipment);
	let diffusorLengths = JSON.parse(state.diffusorLengths);
	let profiles = JSON.parse(state.userProfiles);

	//convert object into array to use filter method
	if (typeof systemEquipment === 'object') {
		let systemEquipmentIndexes = [];
		Object.keys(systemEquipment).forEach((key, index) => {
			systemEquipmentIndexes.push(key);
		});

		let temp = [];
		for (let i = 0; i < systemEquipmentIndexes.length; i++) {
			temp.push(systemEquipment[systemEquipmentIndexes[i]]);
		}
		systemEquipment = temp;
	}

	let userSystemEquipment = [];
	let diffusor = state.userSelectedDiffusor.toLowerCase();
	let userDiffusors = [];

	let diffusorRUN = systemEquipment.filter(item => item.diffusor === 'RUN');
	let diffusorRUNPlus = systemEquipment.filter(item => item.diffusor === 'RUN+');
	let diffusorOpal = systemEquipment.filter(item => item.article_code.includes('PURLT S SYS EQP DIF CW'));

	diffusorRUN = diffusorRUN.sort((a, b) => a.length - b.length);
	diffusorRUNPlus = diffusorRUNPlus.sort((a, b) => a.length - b.length);
	diffusorOpal = diffusorOpal.sort((a, b) => a.length - b.length);

	let diffusorsForCalc;

	diffusorsForCalc = diffusor === 'run' ? diffusorRUN : diffusor === 'run+' ? diffusorRUNPlus : diffusorOpal;
	/** For room lighting: Johannes wants to mostly use diffusors with one edge cover -> 1EF and none with both like -> 2EF
	Because 2EF are only useful if they fit the installation perfectly. Therefore we disregard them.
	We should at least send 2x 1EF even for the smallest configuration and add 0EF if they are needed.
	 */
	let diffusors1EF = diffusorsForCalc.filter(item => item.article_code.includes("1EF"));
	let diffusorsOEF = diffusorsForCalc.filter(item => item.article_code.includes("OEF"));

	// Diffusor
	let neededDifs = [];
	let diffusorsForCalcOnlyLenghts = [];
	let diffusorLength1EF = [];
	let diffusorLengthOEF = [];

	diffusorsForCalc.map(item => diffusorsForCalcOnlyLenghts.push(item.length));
	diffusors1EF.map(item => diffusorLength1EF.push(item.length));
	diffusorsOEF.map(item => diffusorLengthOEF.push(item.length));

	// Different calculation based on selected ligting
	// --> Only room ligthing uses 1EF and OEF so differentiate between it and direct lighting
	var lighting = state.userSelectedPureliteSlimLighting;
	if (state.userSelectedPureliteSlimLighting === "direktstrahlend") {
		for (let i = 0; i < diffusorLengths.length; i++) {
			let neededLength = diffusorLengths[i].length;

			while (neededLength > 0) {
				if (neededLength > diffusorsForCalcOnlyLenghts[diffusorsForCalcOnlyLenghts.length - 1]) {
					neededDifs.push(diffusorsForCalc[diffusorsForCalcOnlyLenghts.length - 1]);
					neededLength -= diffusorsForCalcOnlyLenghts[diffusorsForCalcOnlyLenghts.length - 1];
				} else {
					for (let y = 0; y < diffusorsForCalc.length; y++) {
						if (diffusorsForCalc[y].length >= neededLength) {
							neededDifs.push(diffusorsForCalc[y]);
							neededLength -= diffusorsForCalc[y].length;
							break;
						}
					}
				}
			}
		}
	}
	else {
		for (let i = 0; i < diffusorLengths.length; i++) {

			let neededLength = diffusorLengths[i].length;
			let fittingIndex = diffusors1EF.length - 1;

			// First select the end pieces going from biggest to smallest, default is biggest
			for (let i = diffusors1EF.length - 1; i >= 0; i--) {
				if (2 * diffusors1EF[i].length >= neededLength) {
					fittingIndex = i;
				}
			}

			// Add the 1EF for beginning and end
			neededDifs.push(diffusors1EF[fittingIndex]);
			neededDifs.push(diffusors1EF[fittingIndex]);
			neededLength -= diffusors1EF[fittingIndex].length * 2;

			// Fill the middle part of an installation bigger than the two biggest 1EF together
			while (neededLength > 0) {
				if (neededLength > diffusorsOEF[diffusorsOEF.length - 1].length) {
					neededDifs.push(diffusorsOEF[diffusorsOEF.length - 1]);
					neededLength -= diffusorsOEF[diffusorsOEF.length - 1].length;
				} else {
					for (let y = 0; y < diffusorsOEF.length; y++) {
						if (diffusorsOEF[y].length >= neededLength) {
							neededDifs.push(diffusorsOEF[y]);
							neededLength -= diffusorsOEF[y].length;
							break;
						}
					}
				}
			}
		}
	}

	neededDifs.map(dif => userDiffusors.push(dif));

	//Profilverbinder-Set
	for (let i = 0; i < profiles.length - 1; i++) {
		userSystemEquipment.push(systemEquipment.filter(item => item.article_code === 'PURLT S SYS EQP LIN-CON-KIT')[0])
	}

	state.userSystemEquipment = JSON.stringify(userSystemEquipment);
	calculatePrices(state, userSystemEquipment);

	state.userDiffusors = JSON.stringify(userDiffusors);
	calculatePrices(state, userDiffusors);
}


/**
 * Maps profiles to products for purelite slim
 * @param state
 */
function mapProfilesToProductsPureliteSlim(state) {
	let profiles = JSON.parse(state.profiles);
	let profileLengths = getCorrectProfileLengths(state);
	let installation = state.userSelectedInstallation;
	let lighting = state.userSelectedPureliteSlimLighting;


	if (typeof profiles === 'object') {
		let profileIndexes = [];
		Object.keys(profiles).forEach((key, index) => {
			profileIndexes.push(key);
		});

		let temp = [];
		for (let i = 0; i < profileIndexes.length; i++) {
			temp.push(profiles[profileIndexes[i]]);
		}
		profiles = temp;
	}

	// Johannes wants recessed installation with edge cover (CT) and pendulum as well as ceiling without any (TL)
	switch (installation) {
		case "ceiling":
		case "pendulum":
			installation = 'TL';
			break;
		case "recessed":
			installation = 'CT';
			break;
		default:
			console.log("An unexpected installation type was selected. TYPE:" + installation)
			break;
	}

	profiles.sort((a, b) => b.length - a.length);

	let ctProfiles = profiles.filter(item => item.article_code.includes('CT') && !item.article_code.includes('.V'));
	let tlProfilesPureliteSlim = profiles.filter(item => item.article_code.includes('TL') && item.product_line === 'Purelite Slim' && !item.article_code.includes('.V'));
	let tlProfilesPureliteSlimD = profiles.filter(item => item.article_code.includes('TL') && item.product_line === 'Purelite Slim D' && !item.article_code.includes('.V'));

	let ctProfileLengths = [];
	let tlProfilesPureliteSlimLengths = [];
	let tlProfilesPureliteSlimDLengths = [];

	ctProfiles.map(item => ctProfileLengths.push(item.length));
	tlProfilesPureliteSlim.map(item => tlProfilesPureliteSlimLengths.push(item.length));
	tlProfilesPureliteSlimD.map(item => tlProfilesPureliteSlimDLengths.push(item.length));

	let ctZuschnittProfiles = profiles.filter(item => item.article_code.includes('CT') && item.article_code.includes('.V'));
	let tlSlimZuschnittProfiles = profiles.filter(item => item.article_code.includes('TL') && item.product_line === 'Purelite Slim' && item.article_code.includes('.V'));
	let tlSlimDZuschnittProfiles = profiles.filter(item => item.article_code.includes('TL') && item.product_line === 'Purelite Slim D' && item.article_code.includes('.V'));

	let profileProducts = [];

	profileLengths.forEach(function (profile) {
		let rest = profile.profile;
		let redoCalculation500Smaller = false;
		let add500 = false;

		let neededProfiles = installation === 'CT' ? ctProfiles : lighting === 'direktstrahlend' ? tlProfilesPureliteSlimD : tlProfilesPureliteSlim;
		let neededZuschnitt = installation === 'CT' ? ctZuschnittProfiles : lighting === 'direktstrahlend' ? tlSlimDZuschnittProfiles : tlSlimZuschnittProfiles;
		let neededProfileLengths = installation === 'CT' ? ctProfileLengths : lighting === 'direktstrahlend' ? tlProfilesPureliteSlimDLengths : tlProfilesPureliteSlimLengths;

		while (rest > 0) {
			for (let i = 0; i < neededProfileLengths.length; i++) {
				let currentValue = neededProfileLengths[i];
				if (rest - currentValue >= 0 && (rest - currentValue > 500)) {
					profileProducts.push({
						product: neededProfiles.filter(item => item.length === currentValue)[0],
						cut: null,
						line: profile.line
					});
					rest -= currentValue;
					break;
				} else if (rest - currentValue >= 0 && (rest - currentValue < 500)) {
					profileProducts.push({
						product: neededProfiles.filter(item => item.length === currentValue)[0],
						cut: null,
						line: profile.line
					});
					rest -= currentValue;
					break;
				}
			}
			if (rest < neededProfileLengths[neededProfileLengths.length - 1] && rest > 0) {
				if (rest > 2000) {
					profileProducts.push({
						product: neededZuschnitt.filter(item => item.matchcode.includes('V2001-4803'))[0],
						cut: add500 ? rest + 500 : rest,
						line: profile.line
					});
				}
				else {
					if (rest < 500) {
						redoCalculation500Smaller = true;
					} else {
						profileProducts.push({
							product: neededZuschnitt.filter(item => item.matchcode.includes('V0-2000'))[0],
							cut: add500 ? rest + 500 : rest,
							line: profile.line
						});
					}
				}
				if (redoCalculation500Smaller) {
					// Reset to 500 smaller and redo calculation
					rest = profile.profile - 500;
					profileProducts = [];
					redoCalculation500Smaller = false;
					add500 = true;
				}
				else {
					rest = 0;
				}
			}
		}
	});
	state.userProfiles = JSON.stringify(profileProducts);
	calculatePrices(state, profileProducts);
}
