import { createSlice, current } from '@reduxjs/toolkit';
import { ROTATION_OF_LINE_ELEMENTS } from '../../services/service';
import { calculateSingleLineCHSVariant } from './ChannelSVariants';
import { doCalculationFlow } from './flow';
import { doCalculationPurelite } from './purelite';

import german from '../../resources/de.json';
import english from '../../resources/en.json';
import french from '../../resources/fr.json';
import italian from '../../resources/it.json';
import { createIntl, createIntlCache } from 'react-intl';
import { imperativeTranslateMessage } from '../../components/Translator';
import { doCalculationPureliteSlim } from './pureliteSlim';
import { CS_RECESSED_CORNER_LEG, CS_STANDARD_CORNER_LEG, doCalculationChannelS } from './channels';
import { DEBUG } from '../../services/domainService';

const initialState = {
    ruleset: null, //gesamtes Ruleset
    rulesetName: null, //Name des aktuellen Rulesets
    shapes: null, //Formen aus dem Ruleset
    mountingOptions: null, //Montagearten aus dem Ruleset
    lineLengths: null, //Verfügbare Längen der Linien aus dem Ruleset
    lineLengthsBoost: null, //Verfügbare Längen der Linien aus dem Ruleset für die Channel S Boost
    lineLengthsAsymmetric: null, //Verfügbare Längen der Linien aus dem Ruleset für die Channel S Asymmetrisch
    userLengths: null, //die vom User eingegebenen Längen
    constants: null, //Konstanten aus dem Ruleset
    lineElements: null, //alle berechneten Elemente der Linien
    lineValues: null, //Werte aus der Sidebar rechts
    linesCount: null, //Anzahl der Linien
    rulesetLightingSystemElements: null, //Alle Lichtleisten aus dem Ruleset
    userProducts: null, //Alle vom User benötigten Produkte
    productPriceEur: null, //Produktpreis in €
    productPriceChf: null, //Produktpreis in CHF
    drawingCoordinates: null, //Koordinaten zum Zeichnen von der "Elektrischen" Ansicht
    drawingSimpleCoordinates: null, //Koordinaten zum Zeichnen der "Elektrischen" Ansicht in der Installationsmappe
    colors: null, //Farben aus dem Ruleset
    protections: null, //Schutzarten aus dem Ruleset
    diffusors: null, //Diffusoren aus dem Ruleset
    powers: null, //Leuchtenlichtströme aus dem Ruleset
    userSelectedColor: null, //Ausgewählte Farbe
    userSelectedPower: null, //Ausgewählter Leuchtenlichtstrom
    userSelectedInstallation: null, //Ausgewählte Montageart
    userSelectedDiffusor: null, //Ausgewählter Diffusor
    userSelectedForm: null, //Ausgewählte Form
    operatingDevices: null, //Berechnete Betriebsgeräte
    overallLumen: null, //Berechnete Lumen
    overallWatt: null, //Berechnete Watt
    openConfiguratorPage2: null, //Wird benutzt, um festzustellen, ob sich die zweite Seite (mit Form/Montageart/..) öffnen soll
    resultSVG: null, //SVG der elektrischen Ansicht
    profileSVG: null, //SVG der Profilansicht
    resultSimpleSVG: null, //SVG der elektrischen Ansicht
    profileSimpleSVG: null, //SVG der Profilansicht
    selectedVisibleLayer: 'optic', //Gewählte Ansicht im Canvas
    productCombinations: null, //Daten aus der Kombinationsartikelliste
    showSavingModal: false, //Wird benutzt, um das SavingModal anzuzeigen
    showLoadingModal: false, //Wird benutzt, um das LoadingModal anzuzeigen
    showRequestForm: false, //Wird benutzt, um die RequestForm anzuzeigen
    selectLoadingFinished: false, //Wird benutzt, um festzustellen, ob das Laden abgeschlossen ist
    profiles: null, //Geladene Profile
    profileLengths: null, //Berechnete Profillängen
    profileDrawingCoordinates: null, //Koordinaten zum Zeichnen von der Profilansicht
    profileDrawingSimpleCoordinates: null, //Koordinaten zum Zeichnen von der Profilansicht in der Installationsmappe
    userProfiles: null, //Profile, die für die aktuelle Konfiguration benötigt werden
    userEQP: null, //Equipment, das für die aktuelle Konfiguration benötigt wird
    equipment: null, //Jegliches Equipment
    systemComponents: null, //Jegliches SYS
    systemEquipment: null, //Jegliches SYS EQP
    userSystemComponents: null, //SYS, das für die aktuelle Konfiguration benötigt wird
    userSystemEquipment: null, //SYS EQP, das für die aktuelle Konfiguration benötigt wird
    diffusorLengths: null, //Berechnete Diffusorenlängen
    userSelectedProtection: null, //Protections
    userSelectedPendelLength: 1500, //Pendellänge
    userDiffusors: null, //Diffusoren, die für die aktuelle Konfiguration benötigt wird
    userSelectedPureliteSlimLighting: null, //Ausgewählte Lichttechnik für die PureliteSlim + Is needed in products & configuration therefore both must always be set and unset at the same time
    showConfigLoadingModal: false, //Wird benutzt, um das ConfigLoadingModal anzuzeigen
    showDownloadModal: false, //Wird benutzt, um das DownloadModal anzuzeigen
    isLoadingFinished: null,
    showAXModal: false, //Wird benutzt, um das AXModal anzuzeigen
    maxLengthConfiguration: 18000, //Gibt die maximal mgl. Länge je nach gewählter Linie und Diffusor vor
    isSimplifiedDrawing: false, //Used to set flag to render SVG in simplified form for the installation map where each element has the same length
    drewSimplified: false, //Used to set flag to render SVG in simplified form for the installation map where each element has the same length
    language: 'de', // Language of application text
    currency: 'euro', // Currency displayed for all products in application
    userWirings: false,
    userSelectedEmergency: false,
};

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setRuleset: (state, action) => {
            state.ruleset = action.payload;

            const json = JSON.parse(action.payload);
            state.shapes = json.shapes;

            state.mountingOptions = json.mountingOptions;

            state.lineLengths = JSON.stringify(json.lineLengths);

            if (json.lineLengthsBoost) {
                state.lineLengthsBoost = JSON.stringify(json.lineLengthsBoost);
            }
            if (json.lineLengthsAsymmetric) {
                state.lineLengthsAsymmetric = JSON.stringify(json.lineLengthsAsymmetric);
            }

            state.constants = json.constants;

            state.colors = JSON.stringify(json.colors);

            state.protections = json.protections;

            state.diffusors = json.diffusors;

            state.powers = JSON.stringify(json.powers);
        },
        setRulesetName: (state, action) => {
            state.rulesetName = action.payload;
        },
        setShapes: (state, action) => {
            state.shapes = action.payload;
        },
        setMountingOptions: (state, action) => {
            state.mountingOptions = action.payload;
        },
        setLineLengths: (state, action) => {
            state.lineLengths = action.payload;
        },
        setLineLengthsBoost: (state, action) => {
            state.lineLengthsBoost = action.payload;
        },
        setlineLengthsAsymmetric: (state, action) => {
            state.lineLengthsAsymmetric = action.payload;
        },
        setUserLengths: (state, action) => {
            state.userLengths = action.payload;
            if (state.userLengths !== null) {
                chooseCorrectCalculation(state);
            }
        },
        setConstants: (state, action) => {
            state.constants = action.payload;
        },
        setLineElements: (state, action) => {
            state.lineElements = action.payload;
        },
        setLineValues: (state, action) => {
            state.lineValues = action.payload;
        },
        setLinesCount: (state, action) => {
            state.linesCount = action.payload;
        },
        setRulesetLightingSystemElements: (state, action) => {
            state.rulesetLightingSystemElements = action.payload;
        },
        setUserProducts: (state, action) => {
            state.userProducts = action.payload;
        },
        setProductPriceEur: (state, action) => {
            state.productPriceEur = action.payload;
        },
        setProductPriceChf: (state, action) => {
            state.productPriceChf = action.payload;
        },
        setDrawingCoordinates: (state, action) => {
            state.drawingCoordinates = action.payload;
        },
        setDrawingSimpleCoordinates: (state, action) => {
            state.drawingSimpleCoordinates = action.payload;
        },
        setColors: (state, action) => {
            state.colors = action.payload;
        },
        setProtections: (state, action) => {
            state.protections = action.payload;
        },
        setDiffusors: (state, action) => {
            state.diffusors = action.payload;
        },
        setPowers: (state, action) => {
            state.powers = action.payload;
        },
        setUserWirings: (state, action) => {
            state.userWirings = action.payload;
            state.lineValues = null;
            if (state.userWirings !== null && state.userLengths !== null) {
                chooseCorrectCalculation(state);
            }
        },
        setUserSelectedEmergency: (state, action) => {
            state.userSelectedEmergency = action.payload;
            state.lineValues = null;
            if (state.userSelectedEmergency !== null && state.userLengths !== null) {
                chooseCorrectCalculation(state);
            }
        },
        setUserSelectedColor: (state, action) => {
            state.userSelectedColor = action.payload;
            state.lineValues = null;
            if (state.userSelectedColor !== null && state.userLengths !== null) {
                chooseCorrectCalculation(state);
            }
        },
        setUserSelectedPower: (state, action) => {
            state.userSelectedPower = action.payload;
            state.lineValues = null;
            if (state.userSelectedPower !== null && state.userLengths !== null) {
                chooseCorrectCalculation(state);
            }
        },
        setUserSelectedProtection: (state, action) => {
            state.userSelectedProtection = action.payload;
            state.lineValues = null;
            if (state.userSelectedProtection !== null && state.userLengths !== null) {
                chooseCorrectCalculation(state);
            }
        },
        setUserSelectedPendelLength: (state, action) => {
            state.userSelectedPendelLength = action.payload;
            state.lineValues = null;
            if (state.userSelectedPendelLength !== null && state.userLengths !== null) {
                chooseCorrectCalculation(state);
            }
        },
        setOperatingDevices: (state, action) => {
            state.operatingDevices = action.payload;
        },
        setOverallLumen: (state, action) => {
            state.overallLumen = action.payload;
        },
        setOverallWatt: (state, action) => {
            state.overallWatt = action.payload;
        },
        setOpenConfiguratorPage2: (state, action) => {
            state.openConfiguratorPage2 = action.payload;
        },
        setResultSVG: (state, action) => {
            state.resultSVG = action.payload;
        },
        setProfileSVG: (state, action) => {
            state.profileSVG = action.payload;
        },
        setResultSimpleSVG: (state, action) => {
            state.resultSimpleSVG = action.payload;
        },
        setProfileSimpleSVG: (state, action) => {
            state.profileSimpleSVG = action.payload;
        },
        setUserSelectedInstallation: (state, action) => {
            state.userSelectedInstallation = action.payload;
        },
        setUserSelectedForm: (state, action) => {
            state.userSelectedForm = action.payload;
        },
        setUserSelectedDiffusor: (state, action) => {
            state.userSelectedDiffusor = action.payload;
        },
        setSelectedVisibleLayer: (state, action) => {
            state.selectedVisibleLayer = action.payload;
            state.lineValues = null;
            if (state.userLengths !== null) {
                chooseCorrectCalculation(state);
            }
        },
        setProductCombinations: (state, action) => {
            state.productCombinations = action.payload;
        },
        setShowSavingModal: (state, action) => {
            state.showSavingModal = action.payload;
        },
        // Request Form
        setShowRequestForm: (state, action) => {
            state.showRequestForm = action.payload;
        },
        setShowLoadingModal: (state, action) => {
            state.showLoadingModal = action.payload;
        },
        setLoadingFinished: (state, action) => {
            state.isLoadingFinished = action.payload;
        },
        setProfiles: (state, action) => {
            state.profiles = action.payload;
        },
        setProfileLengths: (state, action) => {
            state.profileLengths = action.payload;
        },
        setProfileDrawingCoordinates: (state, action) => {
            state.profileDrawingCoordinates = action.payload;
        },
        setProfileDrawingSimpleCoordinates: (state, action) => {
            state.profileDrawingSimpleCoordinates = action.payload;
        },
        setUserProfiles: (state, action) => {
            state.userProfiles = action.payload;
        },
        setUserEQP: (state, action) => {
            state.userEQP = action.payload;
        },
        setEquipment: (state, action) => {
            state.equipment = action.payload;
        },
        setSystemComponents: (state, action) => {
            state.systemComponents = action.payload;
        },
        setSystemEquipment: (state, action) => {
            state.systemEquipment = action.payload;
        },
        setUserSystemComponents: (state, action) => {
            state.userSystemComponents = action.payload;
        },
        setUserSystemEquipment: (state, action) => {
            state.userSystemEquipment = action.payload;
        },
        setDiffusorLengths: (state, action) => {
            state.diffusorLengths = action.payload;
        },
        setUserDiffusors: (state, action) => {
            state.userDiffusors = action.payload;
        },
        setUserSelectedPureliteSlimLighting: (state, action) => {
            state.userSelectedPureliteSlimLighting = action.payload;
        },
        setShowConfigLoadingModal: (state, action) => {
            state.showConfigLoadingModal = action.payload;
        },
        setShowDownloadModal: (state, action) => {
            state.showDownloadModal = action.payload;
        },
        setShowAXModal: (state, action) => {
            state.showAXModal = action.payload;
        },
        setMaxLengthConfiguration: (state, action) => {
            state.maxLengthConfiguration = action.payload;
        },
        setSimplifiedDrawing: (state, action) => {
            state.isSimplifiedDrawing = action.payload;
        },
        setDrewSimplified: (state, action) => {
            state.drewSimplified = action.payload;
        },
        setCurrency: (state, action) => {
            state.currency = action.payload;
        },
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
        resetProductsSlice: (state) => {
            let refreshedState = JSON.parse(JSON.stringify(initialState));
            refreshedState.language = state.language;
            refreshedState.currency = state.currency;
            return refreshedState;
        },
    },
});

export const {
    setRuleset,
    setRulesetName,
    setShapes,
    setMountingOptions,
    setLineLengths,
    setUserLengths,
    setConstants,
    setLineElements,
    setLineValues,
    setLinesCount,
    setRulesetLightingSystemElements,
    setUserProducts,
    setProductPriceEur,
    setProductPriceChf,
    setDrawingCoordinates,
    setDrawingSimpleCoordinates,
    setColors,
    setProtections,
    setDiffusors,
    setPowers,
    setUserWirings,
    setUserSelectedEmergency,
    setUserSelectedColor,
    setUserSelectedPower,
    setUserSelectedProtection,
    setOperatingDevices,
    setOverallLumen,
    setOpenConfiguratorPage2,
    setOverallWatt,
    setResultSVG,
    setProfileSVG,
    setResultSimpleSVG,
    setProfileSimpleSVG,
    setUserSelectedInstallation,
    setUserSelectedForm,
    setSelectedVisibleLayer,
    setUserSelectedDiffusor,
    setProductCombinations,
    setShowSavingModal,
    setShowRequestForm,
    setShowLoadingModal,
    setLoadingFinished,
    setSimplifiedDrawing,
    setDrewSimplified,
    setProfiles,
    setProfileDrawingCoordinates,
    setProfileDrawingSimpleCoordinates,
    setProfileLengths,
    setUserProfiles,
    setUserEQP,
    setSystemEquipment,
    setUserSystemEquipment,
    setUserSystemComponents,
    setEquipment,
    setSystemComponents,
    setDiffusorLengths,
    setUserSelectedPendelLength,
    setUserDiffusors,
    setUserSelectedPureliteSlimLighting,
    setLineLengthsBoost,
    setlineLengthsAsymmetric,
    setShowConfigLoadingModal,
    setShowDownloadModal,
    setShowAXModal,
    setMaxLengthConfiguration,
    resetProductsSlice,
    setCurrency,
    setLanguage,
} = productsSlice.actions;

export const selectProductsState = (state) => state.products;
export const selectRuleset = (state) => state.products.ruleset;
export const selectRulesetName = (state) => state.products.rulesetName;
export const selectShapes = (state) => state.products.shapes;
export const selectMountingOptions = (state) => state.products.mountingOptions;
export const selectLineLengths = (state) => state.products.lineLengths;
export const selectLineLengthsBoost = (state) => state.products.lineLengthsBoost;
export const selectlineLengthsAsymmetric = (state) => state.products.lineLengthsAsymmetric;
export const selectUserLength = (state) => state.products.userLengths;
export const selectConstants = (state) => state.products.constants;
export const selectLineElements = (state) => state.products.lineElements;
export const selectLineValues = (state) => state.products.lineValues;
export const selectLinesCount = (state) => state.products.linesCount;
export const selectRulesetLightingSystemElements = (state) => state.products.rulesetLightingSystemElements;
export const selectUserProducts = (state) => state.products.userProducts;
export const selectProductPriceEur = (state) => state.products.productPriceEur;
export const selectProductPriceChf = (state) => state.products.productPriceChf;
export const selectColors = (state) => state.products.colors;
export const selectProtections = (state) => state.products.protections;
export const selectDiffusors = (state) => state.products.diffusors;
export const selectPowers = (state) => state.products.powers;
export const selectUserSelectedColor = (state) => state.products.userSelectedColor;
export const selectUserSelectedPower = (state) => state.products.userSelectedPower;
export const selectUserSelectedProtection = (state) => state.products.userSelectedProtection;
export const selectOperatingDevices = (state) => state.products.operatingDevices;
export const selectOverallLumen = (state) => state.products.overallLumen;
export const selectOverallWatt = (state) => state.products.overallWatt;
export const selectOpenConfiguratorPage2 = (state) => state.products.openConfiguratorPage2;

export const selectUserSelectedInstallation = (state) => state.products.userSelectedInstallation;
export const selectUserSelectedForm = (state) => state.products.userSelectedForm;
export const selecSelectedVisibleLayer = (state) => state.products.selectedVisibleLayer;
export const selectUserSelectedDiffusor = (state) => state.products.userSelectedDiffusor;
export const selectProductCombinations = (state) => state.products.productCombinations;
export const selectShowSavingModal = (state) => state.products.showSavingModal;
export const selectShowAXModal = (state) => state.products.showAXModal;
export const selectMaxLengthConfiguration = (state) => state.products.maxLengthConfiguration;

// Request Form Values
export const selectShowRequestForm = (state) => state.products.showRequestForm;
export const selectProjectAuthor = (state) => state.products.projectAuthor;
export const selectProjectName = (state) => state.products.projectName;

// Loading Modal
export const selectShowLoadingModal = (state) => state.products.showLoadingModal;
export const selectLoadingFinished = (state) => state.products.isLoadingFinished;

// Tracker to check on state of drawing simplified or none simplified coordinates
export const selectSimplifiedDrawing = (state) => state.products.isSimplifiedDrawing;
export const selectDrewSimplified = (state) => state.products.drewSimplified;

export const selectResultSVG = (state) => state.products.resultSVG;
export const selectProfileSVG = (state) => state.products.profileSVG;
export const selectResultSimpleSVG = (state) => state.products.resultSimpleSVG;
export const selectProfileSimpleSVG = (state) => state.products.profileSimpleSVG;

// Profile
export const selectProfiles = (state) => state.products.profiles;
export const selectProfileLengths = (state) => state.products.profileLengths;

// Coordinate
export const selectDrawingCoordinates = (state) => state.products.drawingCoordinates;
export const selectProfileDrawingCoordinates = (state) => state.products.profileDrawingCoordinates;

export const selectDrawingSimpleCoordinates = (state) => state.products.drawingSimpleCoordinates;
export const selectProfileDrawingSimpleCoordinates = (state) => state.products.profileDrawingSimpleCoordinates;

export const selectUserProfiles = (state) => state.products.userProfiles;
export const selectUserEQP = (state) => state.products.userEQP;
export const selectEquipment = (state) => state.products.equipment;
export const selectSystemComponents = (state) => state.products.systemComponents;
export const selectSystemEquipment = (state) => state.products.systemEquipment;
export const selectUserSystemComponents = (state) => state.products.userSystemComponents;
export const selectUserSystemEquipment = (state) => state.products.userSystemEquipment;
export const selectDiffusorLengths = (state) => state.products.diffusorLengths;
export const selectUserSelectedPendelLength = (state) => state.products.userSelectedPendelLength;
export const selectUserDiffusors = (state) => state.products.userDiffusors;
export const selectUserSelectedPureliteSlimLighting = (state) => state.products.userSelectedPureliteSlimLighting;
export const selectShowConfigLoadingModal = (state) => state.products.showConfigLoadingModal;
export const selectShowDownloadModal = (state) => state.products.showDownloadModal;
export const selectCurrency = (state) => state.products.currency;
export const selectLanguage = (state) => state.products.language;
export const selectIsCHF = (state) => state.products.currency === 'chf';
export const selectWirings = (state) => state.products.wirings;

export const SIMPLE_ELEMENT_LENGTH = 300;

export const LIGHT_CORNER_LEG = 100;
export const RESIZE_FACTOR = 3;

export default productsSlice.reducer;

/**
 * Selects correct Calculation method
 * @param {WritableDraft} state
 */
export function chooseCorrectCalculation(state) {
    if (state.rulesetName === 'Channel S') {
        doCalculationChannelS(state);
    } else if (state.rulesetName === 'Purelite Slim') {
        doCalculationPureliteSlim(state);
    } else if (state.rulesetName === 'Purelite') {
        doCalculationPurelite(state);
    } else if (state.rulesetName === 'Flow') {
        doCalculationFlow(state);
    }

    return new Promise((resolve) => {
        if (state.isSimplifiedDrawing !== state.drewSimplified) {
            state.drewSimplified = state.isSimplifiedDrawing;
            console.log('Finished Calculating!' + '\nCalculated Simplified: ' + state.isSimplifiedDrawing + '\nDrew Simplified: ' + state.drewSimplified);
        }
    });
}

/**
 * Produces display value out for sidebar and set state profile label
 * @param lineName Displayed in the canvas as LineNumber on the profile view used in LineLabel
 * @param userLength
 * @param recess
 * @param profileLength
 * @param luminousSurface
 * @param diffusorLength
 * @param lightLength
 * @param state
 * @returns {*[]}
 */
export function displayValues(lineName, userLength, recess, profileLength, luminousSurface, diffusorLength, lightLength, state) {
    let lineCount = 1;
    let lineValues = [];
    let installation = current(state).userSelectedInstallation;
    let form = current(state).userSelectedForm;
    let rulesetConstants = current(state).constants;

    // INTERNATIONALIZATION
    const cache = createIntlCache();
    const messages = {
        de: german,
        en: english,
        it: italian,
        fr: french,
    };

    const locale = state.language;

    const intl = createIntl({
        locale: locale,
        messages: messages[locale],
    });

    if (form === 'O' && (lineName === 'L3' || lineName === 'L4')) {
        return lineValues;
    }

    lineValues.push({ id: lineCount++, label: 'Name', value: lineName, suffix: '' });

    // Total length of this line
    // No Corner case: L:I => two ends and therefore two front face endings
    if (form === 'I') {
        if (state.userSelectedPureliteSlimLighting === 'direktstrahlend') {
            lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.total.title'), value: userLength, suffix: 'mm' });
        } else {
            lineValues.push({
                id: lineCount++,
                label: imperativeTranslateMessage(intl, 'canvas.right.length.total.title'),
                value: profileLength + rulesetConstants.endwallthickness * 2,
                suffix: 'mm',
            });
        }
        lineValues.push({
            id: lineCount++,
            label: imperativeTranslateMessage(intl, 'canvas.right.length.diffusor.title'),
            value: diffusorLength,
            suffix: 'mm',
        });
    }
    // 1 Corner cases : L1:L,S,Z,U; L2:L; L3:S,Z,U => one end and therefore one front face endings
    else if (
        form === 'L' ||
        (form === 'S' && (lineName === 'L1' || lineName === 'L3')) ||
        (form === 'Z' && (lineName === 'L1' || lineName === 'L3')) ||
        (form === 'U' && (lineName === 'L1' || lineName === 'L3'))
    ) {
        lineValues.push({
            id: lineCount++,
            label: imperativeTranslateMessage(intl, 'canvas.right.length.total.title'),
            value: profileLength + rulesetConstants.endwallthickness * 1 + (installation === 'recessed' ? CS_RECESSED_CORNER_LEG : CS_STANDARD_CORNER_LEG),
            suffix: 'mm',
        });
        lineValues.push({
            id: lineCount++,
            label: imperativeTranslateMessage(intl, 'canvas.right.length.diffusor.title'),
            value: diffusorLength + 0,
            suffix: 'mm',
        });
    }
    // 2 Corner cases : L:O;  L2:S,Z,U => zero ends and therefore no front face endings
    else if (form === 'O' || (form === 'S' && lineName === 'L2') || (form === 'Z' && lineName === 'L2') || (form === 'U' && lineName === 'L2')) {
        lineValues.push({
            id: lineCount++,
            label: imperativeTranslateMessage(intl, 'canvas.right.length.total.title'),
            value: profileLength + rulesetConstants.endwallthickness * 0 + 2 * (installation === 'recessed' ? CS_RECESSED_CORNER_LEG : CS_STANDARD_CORNER_LEG),
            suffix: 'mm',
        });
        lineValues.push({
            id: lineCount++,
            label: imperativeTranslateMessage(intl, 'canvas.right.length.diffusor.title'),
            value: diffusorLength + 2 * 0,
            suffix: 'mm',
        });
    }
    lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.user.title'), value: userLength, suffix: 'mm' });
    if (false) {
        // DEBUG
        lineValues.push({ id: lineCount++, label: 'Luminous', value: luminousSurface, suffix: 'mm' });
        lineValues.push({ id: lineCount++, label: '[RAW] - Light', value: lightLength, suffix: 'mm' });
    }
    if (installation === 'recessed') {
        lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.recess'), value: recess, suffix: 'mm' });
    }

    // Differentiate between lines without corner, with one corner and 2 corners
    // Standard assumption is that each corner has a light length of 100m -> 1 corner : 100m; 2 corner : 200mm
    if (form === 'I') {
        lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.profile.title'), value: profileLength, suffix: 'mm' });
    } else if (
        form === 'L' ||
        (form === 'S' && (lineName === 'L1' || lineName === 'L3')) ||
        (form === 'Z' && (lineName === 'L1' || lineName === 'L3')) ||
        (form === 'U' && (lineName === 'L1' || lineName === 'L3'))
    ) {
        lineValues.push({
            id: lineCount++,
            label: imperativeTranslateMessage(intl, 'canvas.right.length.profile.title'),
            value: profileLength + (installation === 'recessed' ? CS_RECESSED_CORNER_LEG : CS_STANDARD_CORNER_LEG),
            suffix: 'mm',
        });
    } else if (form === 'O' || (form === 'S' && lineName === 'L2') || (form === 'Z' && lineName === 'L2') || (form === 'U' && lineName === 'L2')) {
        lineValues.push({
            id: lineCount++,
            label: imperativeTranslateMessage(intl, 'canvas.right.length.profile.title'),
            value: profileLength + 2 * (installation === 'recessed' ? CS_RECESSED_CORNER_LEG : CS_STANDARD_CORNER_LEG),
            suffix: 'mm',
        });
    }

    if (form === 'I') {
        // lineValues.push({id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.light.title'), value: lightLength, suffix: 'mm'});
        lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.light.title'), value: lightLength, suffix: 'mm' });
    } else if (
        form === 'L' ||
        (form === 'S' && (lineName === 'L1' || lineName === 'L3')) ||
        (form === 'Z' && (lineName === 'L1' || lineName === 'L3')) ||
        (form === 'U' && (lineName === 'L1' || lineName === 'L3'))
    ) {
        // lineValues.push({id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.light.title'), value: lightLength + 100, suffix: 'mm'});
        lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.light.title'), value: lightLength, suffix: 'mm' });
    } else if (form === 'O' || (form === 'S' && lineName === 'L2') || (form === 'Z' && lineName === 'L2') || (form === 'U' && lineName === 'L2')) {
        // lineValues.push({id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.light.title'), value: lightLength + 200, suffix: 'mm'});
        lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.light.title'), value: lightLength, suffix: 'mm' });
    }
    //Hidden bc not necessary
    //lineValues.push({id:lineCount++, label:'Leuchtende Fläche (<strong>L<sub>LL</sub></strong>)', value:luminousSurface, suffix:'mm'});
    //lineValues.push({id:lineCount++, label:'Diffusorlänge (<strong>L<sub>Dif</sub></strong>)', value:diffusorLength, suffix:'mm'});

    // Set profile label
    //adding profileLength to array
    let profileLengths = JSON.parse(state.profileLengths) || [];
    profileLengths.push({ line: lineName, profile: profileLength });
    state.profileLengths = JSON.stringify(profileLengths);

    //adding diffusorLength to array
    let diffusorLengths = JSON.parse(state.diffusorLengths) || [];
    diffusorLengths.push({ line: lineName, length: diffusorLength });
    state.diffusorLengths = JSON.stringify(diffusorLengths);

    return lineValues;
}

/**
 * Maps the lighting elements array to real products
 * @param {WritableDraft} state
 * @param {array} elements Array of every lighting product of the chosen family
 */
export function mapElementsToProducts(state, elements) {
    const rulesetLightingSystemElements = current(state).rulesetLightingSystemElements;
    let choosenProducts = [];

    let product_line = current(state).rulesetName;
    let current_diffusor = current(state).userSelectedDiffusor;

    let power = state.userSelectedPower ? state.userSelectedPower.name : 'xHE';
    let color = state.userSelectedColor ? state.userSelectedColor.kelvin : 4000;

    if (current_diffusor === 'boost') {
        product_line += ' Boost Office';
    }
    if (current_diffusor === 'asymmetric') {
        if (color === 'TW') {
            power = 'HPE';
        } else {
            power = 'HO';
        }
    }

    for (let i = 0; i < elements.length; i++) {
        let itemLength = elements[i].length;

        let product;
        if (elements[i].type === 'master') {
            if (color === 'TW') {
                // eslint-disable-next-line eqeqeq
                product = rulesetLightingSystemElements.filter(
                    (item) =>
                        item.type === elements[i].type &&
                        item.length === itemLength &&
                        item.color == color &&
                        item.power === power &&
                        item.product_line === product_line
                );
            } else {
                // eslint-disable-next-line eqeqeq
                product = rulesetLightingSystemElements.filter(
                    (item) =>
                        item.type === elements[i].type &&
                        item.length === itemLength &&
                        !!item.expandable === elements[i].expandable &&
                        item.color == color &&
                        item.power === power &&
                        item.product_line === product_line
                );
            }
        } else if (elements[i].type === 'slave') {
            // eslint-disable-next-line eqeqeq
            product = rulesetLightingSystemElements.filter(
                (item) =>
                    item.type === elements[i].type &&
                    item.length === itemLength &&
                    !!item.expandable === elements[i].expandable &&
                    item.color == color &&
                    item.product_line == product_line
            );
        } else if (elements[i].type === 'corner') {
            itemLength *= 2; // Must double length of Channel S corner here because it is registered as 200 in the database
            // eslint-disable-next-line eqeqeq
            product = rulesetLightingSystemElements.filter(
                (item) =>
                    item.type === elements[i].type &&
                    item.length === itemLength &&
                    !!item.expandable === elements[i].expandable &&
                    item.color == color &&
                    item.product_line == product_line &&
                    item.orientation === 'Orientation ' + elements[i].rotation
            );
        } else {
            console.log('kein gültiges Element');
        }

        if (product.length > 1) {
            //if more than 1 product, we have to filter if diffusor is asymmetric
            if (current_diffusor === 'asymmetric') {
                product = product.filter((item) => item.article_code.includes('AB') && !item.article_code.includes('E3h'));
            } else {
                product = product.filter((item) => !item.article_code.includes('AB') && !item.article_code.includes('E3h'));
            }
        }

        choosenProducts.push(product[0]);
    }

    state.userProducts = choosenProducts;
    calculatePrices(state, choosenProducts);
}

/**
 * Calculates drawing coordinates and stores it in an array
 * @param state
 * @param elements
 * @param isRectangle
 */
export function calculateDrawingCoordinates(state, elements, isRectangle) {
    let coordinates = [];
    let height = 10;
    let cornersMet = 0;
    let form = current(state).userSelectedForm;

    let rotation = 0; //deg
    let x = 0;
    let y = 40;

    let border = '#000000';
    let masterColor = '#FFFFFF';
    let masterExpandableColor = 'url(#hatching)';
    let slaveExpandableColor = 'url(#hatching-with-bg)';
    let slaveColor = '#c1c1c1';
    let cornerColor = '#666666';

    if (current(state).rulesetName === 'Purelite Slim') {
        slaveExpandableColor = slaveColor;
    }

    let activeLayer = current(state).selectedVisibleLayer;
    if (activeLayer === 'electric') {
        masterColor = '#FFF303';
        masterExpandableColor = '#F63F3F';
        slaveExpandableColor = '#ABE444';
        slaveColor = '#FEECC0';
        cornerColor = '#449BE4';

        if (state.rulesetName === 'Flow') {
            masterColor = '#F63F3F';
            masterExpandableColor = '#F63F3F';
            slaveExpandableColor = '#ABE444';
            slaveColor = '#ABE444';
        } else if (state.rulesetName === 'Purelite') {
            masterColor = '#FFF303';
            masterExpandableColor = '#F63F3F';
            slaveColor = '#ABE444';
            slaveExpandableColor = '#ABE444';
            cornerColor = '#449BE4';
        }
    }

    const resizeFactor = RESIZE_FACTOR;

    if (form === 'L' || form === 'U') {
        rotation = 90;
    }

    if (isRectangle) {
        x = 200 / resizeFactor;
    }

    let lineNumber = 1;
    let lightsLines = current(state).userProducts;

    for (let i = 0; i < lightsLines.length; i++) {
        let type = lightsLines[i].type;
        let expandable = lightsLines[i].expandable === 0 || lightsLines[i].expandable === false ? false : true;
        let length = lightsLines[i].length;
        let color = cornerColor;

        let cornerRotation = ROTATION_OF_LINE_ELEMENTS[form][cornersMet];
        let rotationOfCorner = cornerRotation === 'L' ? -1 : 1;

        if (type === 'master' && expandable === true) {
            color = masterExpandableColor;
        } else if (type === 'master') {
            color = masterColor;
        } else if (type === 'slave' && expandable === true) {
            color = slaveExpandableColor;
        } else if (type === 'slave') {
            color = slaveColor;
        } else if (type === 'corner') {
            // Corners length was 200 until now to match its real product with a length of 200
            // But in the further calculations only 100 should be used
            length = length / 2;
        }

        // Simplify shape of each element for the installation map svg
        if (current(state).isSimplifiedDrawing) {
            length = SIMPLE_ELEMENT_LENGTH;
        }

        coordinates.push({
            actualLength: lightsLines[i],
            article: lightsLines[i].article_nr,
            border: border,
            color: color,
            x: x,
            y: y,
            w: length / resizeFactor,
            h: height,
            type: type,
            rotation: rotation,
            elementLength: length,
            cornerOrientation: cornerRotation,
            lineNumber: lineNumber,
        });

        if (rotation === 0) {
            x += length / resizeFactor;

            if (type === 'corner') {
                y += (length / resizeFactor) * rotationOfCorner;
                if (rotationOfCorner === -1) {
                    x -= height;
                    y += height;
                }
                cornersMet++;
            }
        } else if (rotation === 90) {
            y += length / resizeFactor;

            if (type === 'corner') {
                x -= (length / resizeFactor) * rotationOfCorner;
                if (rotationOfCorner === -1) {
                    y -= height;
                    x -= height;
                }
                cornersMet++;
            }
        } else if (rotation === 180) {
            x -= length / resizeFactor;
            if (type === 'corner') {
                y -= (length / resizeFactor) * rotationOfCorner;
                cornersMet++;
            }
        } else if (rotation === 270) {
            y -= length / resizeFactor;
            if (type === 'corner') {
                x += (length / resizeFactor) * rotationOfCorner;
                cornersMet++;
            }
        }

        if (type === 'corner') {
            if (ROTATION_OF_LINE_ELEMENTS[form][cornersMet - 1] === 'L') {
                rotation -= 90;
            } else {
                rotation += 90;
            }
            rotation = (rotation + 360) % 360;
            lineNumber++;
        }
    }

    // Special Case S must be moved down because of the draw order of the elements
    if (form === 'S' || form === 'U') {
        if (current(state).isSimplifiedDrawing) {
            let currentBiggest = 0;
            for (let i = 0; i < coordinates.length; i++) {
                if (Math.abs(coordinates[i].y) > currentBiggest) {
                    currentBiggest = Math.abs(coordinates[i].y);
                }
            }

            for (let i = 0; i < coordinates.length; i++) {
                coordinates[i].y += currentBiggest;
            }
        }
    }

    if (current(state).isSimplifiedDrawing) {
        state.drawingSimpleCoordinates = coordinates;
    } else {
        state.drawingCoordinates = coordinates;
    }
}

/**
 * Calculates drawing coordinates and stores it in an array
 * !CAUTION!: Also sets the profileDrawingCoordinates state!
 * @param state
 * @param isRectangle
 */
export function calculateProfileDrawingCoordinates(state, isRectangle) {
    let coordinates = [];
    let height = 10;
    let cornersMet = 0;
    let form = current(state).userSelectedForm;

    let profiles = JSON.parse(current(state).userProfiles);

    let rotation = 0; //deg
    let x = 0;
    let y = 40;

    let border = '#000000';
    let profileWithHatching = 'url(#hatching)';
    let profileColor = '#ffffff';

    const resizeFactor = RESIZE_FACTOR;

    if (form === 'L' || form === 'U') {
        rotation = 90;
    }

    let profileLengths = getCorrectProfileLengths(state);

    if (isRectangle) {
        x = 200 / resizeFactor;
    }

    let cornerLength = state.userSelectedInstallation === 'recessed' ? CS_RECESSED_CORNER_LEG : CS_STANDARD_CORNER_LEG;
    if (current(state).isSimplifiedDrawing) {
        cornerLength = SIMPLE_ELEMENT_LENGTH;
    }
    let cornerOffset = cornerLength / resizeFactor;

    let colorIndex = 0;

    let cornersSetup = [];

    for (let i = 0; i < profileLengths.length; i++) {
        let type = 'profile';

        let cornerRotation = ROTATION_OF_LINE_ELEMENTS[form][cornersMet];

        //get all profiles for current line
        let profilesForLength = profiles.filter((item) => item.line === profileLengths[i].line);

        //mapping threw all profiles for line and adding them
        // eslint-disable-next-line array-callback-return,no-loop-func
        profilesForLength.map((item, index, arr) => {
            type = 'profile';
            let itemLength = item.product.length;
            let cut = item.cut;
            let color = colorIndex++ % 2 === 0 ? profileColor : profileWithHatching;
            let nextIsCorner = index === arr.length - 1; // Additional Offset to move corner out of the last element

            if (cut) {
                itemLength = cut;
            }
            // Simplify shape of each element for the installation map svg
            if (current(state).isSimplifiedDrawing) {
                itemLength = SIMPLE_ELEMENT_LENGTH;
                // Push Elements with coordinates
                coordinates.push({
                    actualLength: item.product.length,
                    article: item.product.article_nr,
                    border: border,
                    color: color,
                    x: x,
                    y: y,
                    w: itemLength / resizeFactor,
                    h: height,
                    type: type,
                    rotation: rotation,
                    elementLength: itemLength,
                    cornerOrientation: cornerRotation,
                    line: profileLengths[i].line,
                });
            } else {
                // Push Elements with coordinates
                coordinates.push({
                    actualLength: item.product.length,
                    border: border,
                    color: color,
                    x: x,
                    y: y,
                    w: itemLength / resizeFactor,
                    h: height,
                    type: type,
                    rotation: rotation,
                    elementLength: itemLength,
                    cornerOrientation: cornerRotation,
                    line: profileLengths[i].line,
                });
            }
            // Adds the offset to line each element after the last one; corner offset is only added if next element is a corner (end of current line)
            if (ROTATION_OF_LINE_ELEMENTS[form][cornersMet] === 'L') {
                if (rotation === 0) {
                    x += itemLength / resizeFactor + (nextIsCorner ? cornerOffset : 0);
                } else if (rotation === 90) {
                    y += itemLength / resizeFactor + (nextIsCorner ? cornerOffset : 0); // DN-Left
                } else if (rotation === 180) {
                    x -= itemLength / resizeFactor + (nextIsCorner ? cornerOffset : 0);
                } else if (rotation === 270) {
                    y -= itemLength / resizeFactor + (nextIsCorner ? cornerOffset : 0);
                }
            } else {
                if (rotation === 0) {
                    x += itemLength / resizeFactor + (nextIsCorner ? cornerOffset : 0);
                } else if (rotation === 90) {
                    y += itemLength / resizeFactor + (nextIsCorner ? cornerOffset : 0); // DN-RIGHT
                } else if (rotation === 180) {
                    x -= itemLength / resizeFactor + (nextIsCorner ? cornerOffset : 0);
                } else if (rotation === 270) {
                    y -= itemLength / resizeFactor + (nextIsCorner ? cornerOffset : 0);
                }
            }
        });

        // Add a corner if there is more than one line and its not the last line or it is the O-Form (Corners everywhere)
        if ((i !== profileLengths.length - 1 && profileLengths.length > 1) || form === 'O') {
            type = 'corner';
            // CW: Ceiling & pendulum: Corners profile length is always 135mm for Channel S see article nr 2004.7098
            // CR: Recessed: Corners profile length is always 145mm for Channel S see article nr 2004.7100

            let cornerLength = state.userSelectedInstallation === 'recessed' ? CS_RECESSED_CORNER_LEG : CS_STANDARD_CORNER_LEG;
            if (current(state).isSimplifiedDrawing) {
                cornerLength = SIMPLE_ELEMENT_LENGTH;
            }
            let color = 'red';
            let border = 'black'; // Corners seem to only react to border color settings
            if (rotation === 90) {
                coordinates.push({
                    article: state.userSelectedInstallation === 'recessed' ? '2004.7100' : '2004.7098',
                    border: border,
                    color: color,
                    x: x,
                    y: y - cornerOffset,
                    w: cornerOffset,
                    h: height,
                    type: type,
                    rotation: rotation,
                    elementLength: cornerLength,
                    cornerOrientation: cornerRotation,
                    line: profileLengths[i].line,
                });
                // cornersSetup.push({article: state.userSelectedInstallation === "recessed" ? "2004.7100" : "2004.7098", border: border, color: color, x: x, y: y - (cornerOffset), w: cornerOffset, h: height, type: type, rotation: rotation, elementLength: cornerLength, cornerOrientation: cornerRotation, line: profileLengths[i].line})
            } else if (rotation === 180) {
                coordinates.push({
                    article: state.userSelectedInstallation === 'recessed' ? '2004.7100' : '2004.7098',
                    border: border,
                    color: color,
                    x: x + cornerOffset,
                    y: y,
                    w: cornerOffset,
                    h: height,
                    type: type,
                    rotation: rotation,
                    elementLength: cornerLength,
                    cornerOrientation: cornerRotation,
                    line: profileLengths[i].line,
                });
                // cornersSetup.push({article: state.userSelectedInstallation === "recessed" ? "2004.7100" : "2004.7098", border: border, color: color, x: x + (cornerOffset), y: y, w: cornerOffset, h: height, type: type, rotation: rotation, elementLength: cornerLength, cornerOrientation: cornerRotation, line: profileLengths[i].line})
            } else if (rotation === 270) {
                coordinates.push({
                    article: state.userSelectedInstallation === 'recessed' ? '2004.7100' : '2004.7098',
                    border: border,
                    color: color,
                    x: x,
                    y: y + cornerOffset,
                    w: cornerOffset,
                    h: height,
                    type: type,
                    rotation: rotation,
                    elementLength: cornerLength,
                    cornerOrientation: cornerRotation,
                    line: profileLengths[i].line,
                });
                // cornersSetup.push({article: state.userSelectedInstallation === "recessed" ? "2004.7100" : "2004.7098", border: border, color: color, x: x, y: y + (cornerOffset), w: cornerOffset, h: height, type: type, rotation: rotation, elementLength: cornerLength, cornerOrientation: cornerRotation, line: profileLengths[i].line})
            } else if (rotation === 360 || rotation === 0) {
                coordinates.push({
                    article: state.userSelectedInstallation === 'recessed' ? '2004.7100' : '2004.7098',
                    border: border,
                    color: color,
                    x: x - cornerOffset,
                    y: y,
                    w: cornerOffset,
                    h: height,
                    type: type,
                    rotation: rotation,
                    elementLength: cornerLength,
                    cornerOrientation: cornerRotation,
                    line: profileLengths[i].line,
                });
                // cornersSetup.push({article: state.userSelectedInstallation === "recessed" ? "2004.7100" : "2004.7098", border: border, color: color, x: x - (cornerOffset), y: y, w: cornerOffset, h: height, type: type, rotation: rotation, elementLength: cornerLength, cornerOrientation: cornerRotation, line: profileLengths[i].line})
            }
        }

        // Offset line movement
        if (ROTATION_OF_LINE_ELEMENTS[form][cornersMet] === 'L') {
            if (rotation === 0) {
                y -= cornerOffset; // FIXED CORNER RT-L
            } else if (rotation === 90) {
                x += cornerOffset; // FIXED CORNER DN-L
            } else if (rotation === 180) {
                y += cornerOffset; // FIXED CORNER LT-R
            } else if (rotation === 270) {
                x -= cornerOffset; // FIXED CORNER UP-L
            }
        } else {
            if (rotation === 0) {
                y += cornerOffset; // FIXED CORNER RT-R
            } else if (rotation === 90) {
                x -= cornerOffset; // FIXED CORNER DN-R
            } else if (rotation === 180) {
                y -= cornerOffset; // FIXED CORNER LT-R
            } else if (rotation === 270) {
                x += cornerOffset; // FIXED CORNER UP-R
            }
        }

        if (ROTATION_OF_LINE_ELEMENTS[form][cornersMet] === 'L') {
            if (rotation === 0) {
                x -= height;
                y += height;
            } else if (rotation === 90) {
                x -= height;
                y -= height;
            } else if (rotation === 180) {
                x += height;
                y += height;
            } else if (rotation === 270) {
                x += height;
                y -= height;
            }

            rotation -= 90;
        } else {
            rotation += 90;
        }
        rotation = (rotation + 360) % 360;
        cornersMet++;
    }

    // Add corners now to make sure they are the first according to the drawing order
    // cornersSetup.forEach( corner => {
    // 	coordinates.push(corner);
    // })

    // Special Case S must be moved down because of the draw order of the elements
    if (form === 'S' || form === 'U') {
        if (current(state).isSimplifiedDrawing) {
            let currentBiggest = 0;
            for (let i = 0; i < coordinates.length; i++) {
                if (Math.abs(coordinates[i].y) > currentBiggest) {
                    currentBiggest = Math.abs(coordinates[i].y);
                }
            }

            for (let i = 0; i < coordinates.length; i++) {
                coordinates[i].y += currentBiggest;
            }
        }
    }

    if (current(state).isSimplifiedDrawing) {
        state.profileDrawingSimpleCoordinates = coordinates;
    } else {
        state.profileDrawingCoordinates = coordinates;
    }
}

/**
 * Reverses line and checks for integrity of expandable master elements
 * @param line the line to reverse as array
 * @returns {*[]} the reversed line as array
 */
export function reverseLine(line) {
    let reversedLine = [];
    let neededExpandLength = 0;

    //go through all elements
    line.forEach((element) => {
        //if expandable master check how much we need as minimum
        if (element.type === 'master' && element.expandable === true) {
            neededExpandLength = element.expandableMin;
            reversedLine.push(element);
        } else {
            //if we have a expandable master that neets a minimum amount of expansion add the slave
            if (neededExpandLength > 0) {
                reversedLine.push(element);
                neededExpandLength -= element.length;

                //if we have the minimum amount after adding the slave just reverse the array (so the last reverse won't destroy this!)
                if (neededExpandLength <= 0) {
                    reversedLine.reverse();
                }
            } else {
                //otherwise add the element
                reversedLine.push(element);
            }
        }
    });
    //reverse the line to get all slaves in front
    reversedLine.reverse();

    return reversedLine;
}

/**
 * Splits line in same elements like slaves, masterExpandable, masterNotExpandable and corner
 * @param line
 * @returns {*[]}
 */
export function splitLineInSameElements(line) {
    let splittedLine = [];

    let sameElements = [];
    let lastType = '';
    let lastExpandable = '';

    //go through all elements
    for (let i = 0; i < line.length; i++) {
        let element = line[i];

        if ((lastType !== element.type && i !== 0) || (lastType === element.type && lastExpandable !== element.expandable && i !== 0)) {
            lastType = element.type;
            lastExpandable = element.expandable;

            splittedLine.push(sameElements);
            sameElements = [];
        } else if (i === 0) {
            lastType = element.type;
            lastExpandable = element.expandable;
        }

        sameElements.push(element);
    }

    splittedLine.push(sameElements);

    return splittedLine;
}

/**
 * Calculates count of operating devices
 * @param state
 * @param elements
 */
export function calculateOperatingDevices(state, elements) {
    let operatingDevices = 0;
    elements.forEach((el) => {
        if (el.type === 'master') operatingDevices++;
    });

    state.operatingDevices = operatingDevices;
}

/**
 * Calculates overall lumen and watt
 * @param state
 */
export function calculateOverallLumenAndWatt(state) {
    let products = state.userProducts;
    let combinations = JSON.parse(state.productCombinations);
    let currentDiffusor = state.userSelectedDiffusor.toUpperCase();
    let currentPower = state.userSelectedPower ? state.userSelectedPower.name : 'xHE';

    let lumen = 0;
    let watt = 0;

    products.forEach((el) => {
        let foundCombination = combinations.filter(
            (item) =>
                item.diffusor.toUpperCase() === currentDiffusor &&
                item.power.toUpperCase() === currentPower.toUpperCase() &&
                item.article_code === el.article_code
        )[0];
        if (foundCombination) {
            lumen += foundCombination.lm;
            watt += foundCombination.system_watt;
        } else {
            if (el != undefined) {
                if (currentDiffusor === 'ASYMMETRIC' || currentDiffusor === 'BOOST') {
                    lumen += el.lm;
                    watt += el.system_watt;
                } else {
                    console.log('Artikel ' + el.article_code + ' wurde nicht in der Kombinationsdatenbank gefunden!');
                }
            }
        }
    });

    state.overallLumen = lumen;
    state.overallWatt = watt.toFixed(2);
}

/**
 * Fixes calculated profile lengths by adding corner lengths and adding 2 lines to form O
 * @param state
 * @returns {any}
 */
export function getCorrectProfileLengths(state) {
    let profileLengths = JSON.parse(state.profileLengths);
    let form = state.userSelectedForm;
    let installation = state.userSelectedInstallation;

    //adding the last 2 lines to form O
    if (form === 'O') {
        profileLengths.push({ line: 'L3', profile: profileLengths[0].profile });
        profileLengths.push({ line: 'L4', profile: profileLengths[1].profile });
    }

    let cornerProfile = CS_STANDARD_CORNER_LEG;
    if (installation === 'recessed') {
        cornerProfile = CS_RECESSED_CORNER_LEG;
    }

    //adding cornerLengths to the profiles
    if (form === 'L') {
        profileLengths[0].profile += cornerProfile;
        profileLengths[1].profile += cornerProfile;
    } else if (form === 'S' || form === 'Z' || form === 'U') {
        profileLengths[0].profile += cornerProfile;
        profileLengths[1].profile += 2 * cornerProfile;
        profileLengths[2].profile += cornerProfile;
    } else if (form === 'O') {
        profileLengths[0].profile += 2 * cornerProfile;
        profileLengths[1].profile += 2 * cornerProfile;
        profileLengths[2].profile += 2 * cornerProfile;
        profileLengths[3].profile += 2 * cornerProfile;
    }

    return profileLengths;
}

/**
 * Calculates Price of all products
 * @param state
 * @param products
 */
export function calculatePrices(state, products) {
    let productPriceEur = state.productPriceEur;
    let productPriceChf = state.productPriceChf;
    // eslint-disable-next-line array-callback-return
    products.map((item) => {
        if (item.product) {
            productPriceEur += item.product.price_eur;
            productPriceChf += item.product.price_chf;
        } else {
            productPriceEur += item.price_eur;
            productPriceChf += item.price_chf;
        }
    });

    state.productPriceEur = productPriceEur;
    state.productPriceChf = productPriceChf;
}

/**
 * Add additional power wiring each time a slave is surrounded by masters without a corner between them
 *
 * @export
 * @param {*} equipment contains equipment product list
 * @return {*}
 */
export function addAdditionalPowerWiring(lineElements, equipment) {
    let userEquipment = [];
    let metMaster = false;
    let isSlaveAfterMaster = false;
    //Netzverdrahtung für Eckverbinder Channel S no. 2005.2409
    JSON.parse(lineElements).forEach((element) => {
        if (element.type === 'master') {
            if (isSlaveAfterMaster) {
                // console.log("Added one additional power cord.");
                userEquipment.push(equipment.filter((item) => item.article_code === 'CHAN-S EQP L-CON POWWIRING-6Px1500')[0]);
                isSlaveAfterMaster = false;
            }
            metMaster = true;
        } else if (element.type === 'slave') {
            if (metMaster) {
                isSlaveAfterMaster = true;
            }
        } else {
            metMaster = false;
            isSlaveAfterMaster = false;
        }
    });
    // Neigbouring expandable masters also need additional wiring
    let metMasterExpandable = false;
    JSON.parse(lineElements).forEach((element) => {
        if (element.type === 'master' && element.expandable === true) {
            if (metMasterExpandable) {
                userEquipment.push(equipment.filter((item) => item.article_code === 'CHAN-S EQP L-CON POWWIRING-6Px1500')[0]);
                metMasterExpandable = false;
            } else {
                metMasterExpandable = true;
            }
        } else {
            metMasterExpandable = false;
        }
    });
    return userEquipment;
}
