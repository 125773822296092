import Input from "./Input";
import Button from "./Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
	setShowRequestForm,
	setShowLoadingModal,
	selectProjectAuthor,
	selectProjectName,
} from "../features/products/productsSlice";
import {
	selectConfigurationID,
} from "../features/configuration/configurationSlice";
import {sendMail} from "../services/service";
import Translator, { imperativeTranslateMessage } from "./Translator";
import { useIntl } from "react-intl";

export default function RequestForm({isResultPage}) {
	const [projectNameInput, setProjectNameInput] = useState('');
	const [projectAuthorInput, setProjectAuthorInput] = useState('');
	const projectName = useSelector(selectProjectName);
	const projectAuthor = useSelector(selectProjectAuthor);
	
	const configurationID = useSelector(selectConfigurationID);

	const [salutationInput, setSalutationInput] = useState('');
	const [firstNameInput, setFirstNameInput] = useState('');
	const [lastNameInput, setLastNameInput] = useState('');
	const [companyInput, setCompanyInput] = useState('');
	const [streetInput, setStreetInput] = useState('');
	const [townInput, setTownInput] = useState('');
	const [countryInput, setCountryInput] = useState('');
	const [emailInput, setEmailInput] = useState('');
	const [phoneInput, setPhoneInput] = useState('');
	const [messageInput, setMessageInput] = useState('');
	const [checkbox1Input, setCheckbox1Input] = useState('');
	const [checkbox2Input, setCheckbox2Input] = useState('');
	const [checkbox3Input, setCheckbox3Input] = useState('');
	const dispatch = useDispatch();
	const intl = useIntl();

	
	useEffect( () => {
		if (projectName && projectName !== projectNameInput) {
			setProjectNameInput(projectName);
		}
		if (projectAuthor && projectAuthor !== projectAuthorInput) {
			setProjectAuthorInput(projectAuthor);
		}
	}, [])
	
	const handleSubmit = (e) => {
		e.preventDefault();
		
		let data = new FormData(e.target);
		
		let formInputs = [];
		
		for(const pair of data.entries()) {
			formInputs.push({label: pair[0], value: pair[1]});
		}
		
		// Add configuration code
		formInputs.push({label: "configurationID", value: configurationID});

		dispatch(setShowLoadingModal(true));
		sendMail(JSON.stringify(formInputs)).then(r =>
			dispatch(setShowRequestForm(false))
		).catch(error => console.log(error));
	}
	
	return (
		<div className="fixed inset-0 z-40 flex flex-wrap justify-center content-center">
		
				<div className={"py-7 px-10 z-50 relative shadow-line-number w-[880px] " + (isResultPage ? 'bg-white text-black' : 'bg-black text-white ')}>
					<FontAwesomeIcon className="cursor-pointer text-22 absolute right-3 top-2" icon={['fal', 'fa-times']} onClick={() => dispatch(setShowRequestForm(false))}/>
					<div className="font-medium text-nav-border-grey text-20">
						<Translator id="popup.request.configuration.header" />
					</div>
					<div className="font-medium text-36">{projectName}
					</div>
					{!isResultPage && <div><Translator id="popup.request.configuration.header.additional" />Bitte ergänzen Sie Ihre Konfiguration mit folgenden Infos:</div>}
					<form onSubmit={handleSubmit}>
						<div className="mt-5">
							<div className={"font-medium text-nav-border-grey text-12 " + (isResultPage ? 'text-black' : 'text-layer-grey')}><Translator id="popup.request.configuration.person.title" />*</div>

							<div className="flex gap-2">
								<input required type="radio" id="male" name="title" value="male" onChange={(e) => setSalutationInput(e.target.value)}/>
								<label className="pt-[2px]" htmlFor="male"><Translator id="popup.request.configuration.person.title.male" /></label>
								<input required type="radio" id="female" name="title" value="female" onChange={(e) => setSalutationInput(e.target.value)}/>
								<label className="pt-[2px]" htmlFor="female"><Translator id="popup.request.configuration.person.title.female" /></label>
							</div>
						</div>

						<div className="grid grid-cols-2 gap-x-5">
							<div className="mt-5">
								<div className={"font-medium text-nav-border-grey text-12 " + (isResultPage ? 'text-black' : 'text-layer-grey')}><Translator id="popup.request.configuration.forename" />*</div>
								<Input name="firstname" id="firstname" required onChange={(e) => setFirstNameInput(e.target.value)} className={"bg-transparent my-2 placeholder-disabled-grey outline-none border-b w-full " + (isResultPage ? 'border-b-black' : 'border-b-white')} placeholder={imperativeTranslateMessage(intl, "popup.request.configuration.forename.placeholder")} value={firstNameInput} />
							</div>
							<div className="my-5">
								<div className={"font-medium text-nav-border-grey text-12 " + (isResultPage ? 'text-black' : 'text-layer-grey')}><Translator id="popup.request.configuration.surname" />*</div>
								<Input name="lastname" id="lastname" required onChange={(e) => setLastNameInput(e.target.value)} className={"bg-transparent my-2 placeholder-disabled-grey outline-none border-b w-full " + (isResultPage ? 'border-b-black' : 'border-b-white')} placeholder={imperativeTranslateMessage(intl, "popup.request.configuration.surname.placeholder")} value={lastNameInput} />
							</div>
							<div className="my-5">
								<div className={"font-medium text-nav-border-grey text-12 " + (isResultPage ? 'text-black' : 'text-layer-grey')}><Translator id="popup.request.configuration.company" />*</div>
								<Input name="company" id="company" onChange={(e) => setCompanyInput(e.target.value)} className={"bg-transparent my-2 placeholder-disabled-grey outline-none border-b w-full " + (isResultPage ? 'border-b-black' : 'border-b-white')} placeholder={imperativeTranslateMessage(intl, "popup.request.configuration.company.placeholder")} value={companyInput} />
							</div>
							<div className="my-5">
								<div className={"font-medium text-nav-border-grey text-12 " + (isResultPage ? 'text-black' : 'text-layer-grey')}><Translator id="popup.request.configuration.street" />.*</div>
								<Input name="street" id="street" required onChange={(e) => setStreetInput(e.target.value)} className={"bg-transparent my-2 placeholder-disabled-grey outline-none border-b w-full " + (isResultPage ? 'border-b-black' : 'border-b-white')} placeholder={imperativeTranslateMessage(intl, "popup.request.configuration.street.placeholder")} value={streetInput} />
							</div>

							<div className="mt-5">
								<div className={"font-medium text-nav-border-grey text-12 " + (isResultPage ? 'text-black' : 'text-layer-grey')}><Translator id="popup.request.configuration.zipcode" />*</div>
								<Input name="zip" id="zip" required onChange={(e) => setTownInput(e.target.value)} className={"bg-transparent my-2 placeholder-disabled-grey outline-none border-b w-full " + (isResultPage ? 'border-b-black' : 'border-b-white')} placeholder={imperativeTranslateMessage(intl, "popup.request.configuration.zipcode.placeholder")} value={townInput} />
							</div>
							<div className="my-5">
								<div className={"font-medium text-nav-border-grey text-12 " + (isResultPage ? 'text-black' : 'text-layer-grey')}><Translator id="popup.request.configuration.country"/>*</div>
								<Input name="country" id="country" required onChange={(e) => setCountryInput(e.target.value)} className={"bg-transparent my-2 placeholder-disabled-grey outline-none border-b w-full " + (isResultPage ? 'border-b-black' : 'border-b-white')} placeholder={imperativeTranslateMessage(intl, "popup.request.configuration.country.placeholder")} value={countryInput} />
							</div>
							<div className="my-5">
								<div className={"font-medium text-nav-border-grey text-12 " + (isResultPage ? 'text-black' : 'text-layer-grey')}><Translator id="popup.request.configuration.email" />*</div>
								<Input name="mail" id="mail" required onChange={(e) => setEmailInput(e.target.value)} className={"bg-transparent my-2 placeholder-disabled-grey outline-none border-b w-full " + (isResultPage ? 'border-b-black' : 'border-b-white')} placeholder={imperativeTranslateMessage(intl, "popup.request.configuration.email.placeholder")} value={emailInput} />
							</div>
							<div className="my-5">
								<div className={"font-medium text-nav-border-grey text-12 " + (isResultPage ? 'text-black' : 'text-layer-grey')}><Translator id="popup.request.configuration.phone" />*</div>
								<Input name="phone" required id="phone" onChange={(e) => setPhoneInput(e.target.value)} className={"bg-transparent my-2 placeholder-disabled-grey outline-none border-b w-full " + (isResultPage ? 'border-b-black' : 'border-b-white')} placeholder={imperativeTranslateMessage(intl, "popup.request.configuration.phone.placeholder")} value={phoneInput} />
							</div>
						</div>
						<div className="mt-5">
							<div className={"font-medium text-nav-border-grey text-12 " + (isResultPage ? 'text-black' : 'text-layer-grey')}><Translator id="popup.request.configuration.type.title"/>*</div>
							<div className="flex gap-2">
								<input className="" type="checkbox" id="call" name="call" value="true" onChange={(e) => setCheckbox1Input(e.target.value)}/>
								<label className="pt-[3px]" htmlFor="call"><Translator id="popup.request.configuration.request.callback" /></label>
								<input className="" type="checkbox" id="meeting" name="meeting" value="true" onChange={(e) => setCheckbox2Input(e.target.value)}/>
								<label className="pt-[3px]" htmlFor="meeting"><Translator id="popup.request.configuration.request.appointment" /></label>
								<input className="" type="checkbox" id="offer" name="offer" value="true" onChange={(e) => setCheckbox3Input(e.target.value)}/>
								<label className="pt-[3px]" htmlFor="offer"><Translator id="popup.request.configuration.request.offer" /></label>
							</div>
						</div>
						<div className="mt-5">
							<div className={"font-medium text-nav-border-grey text-12 " + (isResultPage ? 'text-black' : 'text-layer-grey')}><Translator id="popup.request.configuration.request.message.title" /></div>
							<div className="gap-2 font-regular">
								<textarea className="w-full h-[108px] resize-none outline-none" id="text" name="message" placeholder={imperativeTranslateMessage(intl, "popup.request.configuration.request.message.placeholder")} onChange={(e) => setMessageInput(e.target.value)}/>
							</div>
						</div>
						<hr className="border-black"/>
						<div className="mt-10">
							<div className="flex gap-2">
								<input required className="" type="checkbox" id="privacy" name="privacy"/>
								<label className="pt-[3px]" htmlFor="privacy"><Translator id="popup.request.configuration.request.policy.accept" /><a href="https://www.regent.ch/datenschutz/"><u><Translator id="popup.request.configuration.request.policy.title" /></u></a>.*</label><br/>
							</div>
						</div>
						<div className="text-right pt-11">
							<Button type="submit" className={"text-20 bg-black text-white border inline-block py-2 px-9 cursor-pointer transition-all duration-300 hover:brightness-75 border-black"}>
								<Translator id="popup.request.configuration.request.button" />
							</Button>
						</div>
					</form>
				</div>
			<div className="popup z-30 fixed inset-0 flex-wrap justify-center bg-black bg-opacity-50" id="popup-darken" onClick={() => dispatch(setShowRequestForm(false))}/>
		</div>
	);
}