import { createSlice } from '@reduxjs/toolkit';
import { SWISSCODE } from '../../services/service';
import { DEBUG } from '../../services/domainService';

const initialState = {
    configurationID: null,
    ruleset_id: null,
    form: null,
    installation: null,
    configurationLineLengths: null,
    color: null,
    protection: null,
    diffusor: null,
    power: null,
    configurationProductPriceEur: null,
    configurationProductPriceChf: null,
    configurationProducts: null,
    countryCode: null,
    startingRotation: 0,
    projectName: null,
    projectAuthor: null,
    pureliteSlimLighting: null,
    configurationPendelLength: 1500,
    feedPointPosition: 'start',
    projectBuilding: null,
    projectFloor: null,
    projectRoom: null,
    projectLine: null,
    addWiring: false,
    useEmergency: false,
};

export const configurationSlice = createSlice({
    name: 'configuration',
    initialState,
    reducers: {
        setConfigurationID: (state, action) => {
            state.configurationID = action.payload;
        },
        setRulesetID: (state, action) => {
            state.ruleset_id = action.payload;
        },
        setForm: (state, action) => {
            state.form = action.payload;
        },
        setInstallation: (state, action) => {
            state.installation = action.payload;
        },
        setConfigurationLineLengths: (state, action) => {
            state.configurationLineLengths = action.payload;
        },
        setConfigurationColor: (state, action) => {
            state.color = action.payload;
        },
        setConfigurationProtection: (state, action) => {
            state.protection = action.payload;
        },
        setConfigurationDiffusor: (state, action) => {
            state.diffusor = action.payload;
        },
        setConfigurationPower: (state, action) => {
            state.power = action.payload;
        },
        setConfigurationProductPriceEur: (state, action) => {
            state.configurationProductPriceEur = action.payload;
        },
        setConfigurationProductPriceChf: (state, action) => {
            state.configurationProductPriceChf = action.payload;
        },
        setConfigurationProducts: (state, action) => {
            state.configurationProducts = action.payload;
        },
        setCountryCode: (state, action) => {
            // state.countryCode = "DE"; // TODO: This is only done to make the server run for now because there is a bug related to the Swiss style
            state.countryCode = action.payload;
        },
        setStartingRotation: (state, action) => {
            state.startingRotation = action.payload;
        },
        setProjectName: (state, action) => {
            state.projectName = action.payload;
        },
        setProjectAuthor: (state, action) => {
            state.projectAuthor = action.payload;
        },
        setPureliteSlimLighting: (state, action) => {
            state.pureliteSlimLighting = action.payload;
        },
        setConfigurationPendelLength: (state, action) => {
            state.configurationPendelLength = action.payload;
        },
        setFeedPointPosition: (state, action) => {
            state.feedPointPosition = action.payload;
        },
        setProjectBuilding: (state, action) => {
            state.projectBuilding = action.payload;
        },
        setProjectFloor: (state, action) => {
            state.projectFloor = action.payload;
        },
        setProjectRoom: (state, action) => {
            state.projectRoom = action.payload;
        },
        setProjectLine: (state, action) => {
            state.projectLine = action.payload;
        },
        setAddWiring: (state, action) => {
            state.addWiring = action.payload;
        },
        setEmergency: (state, action) => {
            state.useEmergency = action.payload;
        },
        resetConfiguration: () => initialState,
    },
});

export const {
    setConfigurationID,
    setRulesetID,
    setForm,
    setInstallation,
    setConfigurationLineLengths,
    setConfigurationColor,
    setConfigurationProtection,
    setConfigurationDiffusor,
    setConfigurationPower,
    setConfigurationProductPriceEur,
    setConfigurationProductPriceChf,
    setConfigurationProducts,
    resetConfiguration,
    setCountryCode,
    setStartingRotation,
    setProjectAuthor,
    setProjectName,
    setPureliteSlimLighting,
    setConfigurationPendelLength,
    setFeedPointPosition,
    setProjectLine,
    setProjectFloor,
    setProjectBuilding,
    setProjectRoom,
    setAddWiring,
    setEmergency,
} = configurationSlice.actions;

export const selectConfigurationID = (state) => state.configuration.configurationID;
export const selectRulesetID = (state) => state.configuration.ruleset_id;
export const selectForm = (state) => state.configuration.form;
export const selectInstallation = (state) => state.configuration.installation;
export const selectConfigurationLineLengths = (state) => state.configuration.configurationLineLengths;
export const selectConfigurationColor = (state) => state.configuration.color;
export const selectConfigurationProtection = (state) => state.configuration.protection;
export const selectConfigurationDiffusor = (state) => state.configuration.diffusor;
export const selectConfigurationPower = (state) => state.configuration.power;
export const selectConfigurationProductPriceEur = (state) => state.configuration.configurationProductPriceEur;
export const selectConfigurationProductPriceChf = (state) => state.configuration.configurationProductPriceChf;
export const selectConfigurationProducts = (state) => state.configuration.configurationProducts;
export const selectCountryCode = (state) => (DEBUG ? SWISSCODE : state.configuration.countryCode);
export const selectStartingRotation = (state) => state.configuration.startingRotation;
export const selectProjectName = (state) => state.configuration.projectName;
export const selectProjectAuthor = (state) => state.configuration.projectAuthor;
export const selectPureliteSlimLighting = (state) => state.configuration.pureliteSlimLighting;
export const selectConfigurationPendelLength = (state) => state.configuration.configurationPendelLength;
export const selectFeedPointPosition = (state) => state.configuration.feedPointPosition;
export const selectProjectBuilding = (state) => state.configuration.projectBuilding;
export const selectProjectFloor = (state) => state.configuration.projectFloor;
export const selectProjectRoom = (state) => state.configuration.projectRoom;
export const selectProjectLine = (state) => state.configuration.projectLine;
export const selectAddWiring = (state) => state.configuration.addWiring;
export const selectUseEmergency = (state) => state.configuration.useEmergency;

export default configurationSlice.reducer;
