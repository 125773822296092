import React from 'react';
import styled from 'styled-components';
import LineEdit from './LineEdit';
import LineNumber from './LineNumber';
import { useSelector } from 'react-redux';
import { selectCountryCode, selectForm } from '../../features/configuration/configurationSlice';
import { selectIsCHF, selectUserSelectedInstallation } from '../../features/products/productsSlice';
import { addThousandsSeparator, SWISSCODE } from '../../services/service';
import { CS_RECESSED_CORNER_LEG, CS_STANDARD_CORNER_LEG } from '../../features/products/channels';
import { DEBUG } from '../../services/domainService';

const Label = styled.text`
    fill: #000;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    text-anchor: middle;
    dominant-baseline: hanging;
`;

export default function LaneLabel({
    xStart,
    xEnd,
    yStart,
    yEnd,
    width,
    rotation,
    lineLength,
    lineNumber,
    dontShowLine = false,
    scaleFactor = 2.0,
    viewType = 'profile',
}) {
    let distanceBetweenLines = 60;
    const distanceTop = distanceBetweenLines + 10;
    const distanceBottom = distanceBetweenLines - 10;
    const labelMargin = distanceBetweenLines + 15;
    const lineOverflow = 8;
    const installation = useSelector(selectUserSelectedInstallation);

    const stroke = '#000000';
    const strokeWidth = 1;
    let fontSize = scaleFactor === 1.0 ? '25' : '12';

    const form = useSelector(selectForm);

    // Country specific formatting
    const countryCode = useSelector(selectCountryCode);
    let isLanguageCH = useSelector(selectIsCHF);
    let thousandSeparator = isLanguageCH ? "'" : '.';
    let decimalSeparator = isLanguageCH ? ',' : ',';

    function constructLaneLabel(length, installation, form, lineNumber, viewType) {
        let cornerCount = 0;
        // Only needed on profile views and forms containing corner
        // if(form === "I" || viewType !== "profile") {
        // 	cornerCount = 0;
        // }
        // // U2, O1, O2, S2, Z2
        // else if(
        // 		(form === "O" && (lineNumber === 1 || lineNumber === 2)) ||
        // 		((form === "U" || form === "Z" || form === "S") && lineNumber === 2)
        // 	) {
        // 	cornerCount = 2
        // }
        // else {
        // 	cornerCount = 1;
        // }

        let cornerLength = installation === 'recessed' ? CS_RECESSED_CORNER_LEG : CS_STANDARD_CORNER_LEG;
        return addThousandsSeparator(cornerCount * cornerLength + length, thousandSeparator);
    }

    return (
        <>
            <g>
                {/* eslint-disable-next-line eqeqeq */}
                {rotation % 360 === 0 &&
                    form !== 'O' &&
                    !(form === 'Z' && lineNumber == 1) &&
                    !(form === 'S' && lineNumber == 3) && ( // HORIZONTAL: I-1, L-2, U-2, S-1, Z-3  //Z1, S1, L2, U2
                        <>
                            {/* Gerade */}
                            {/* Abschlusslinien */}
                            {/* Label */}
                            {!dontShowLine && (
                                <Label x={(xStart + xEnd) / 2} y={yEnd + labelMargin + 10}>
                                    {DEBUG ? 'A-' : ''}
                                    {constructLaneLabel(lineLength, installation, form, lineNumber, viewType)} mm
                                </Label>
                            )}
                            <LineNumber x={(xStart + xEnd) / 2 + 55} y={yEnd + labelMargin - 5} lineNumber={lineNumber} />
                            <LineEdit x={(xStart + xEnd) / 2 + 30} y={yEnd + labelMargin + 1} lineNumber={lineNumber} />
                            {/* Rotierte Abschlusslinien */}
                        </>
                    )}
                {rotation % 360 === 0 &&
                    (form === 'O' || (form === 'Z' && lineNumber == 1) || (form === 'S' && lineNumber == 3)) && ( // HORIZONTAL: O-1, Z-1, S-3
                        <>
                            {/* Gerade */}
                            {/* Abschlusslinien */}
                            {/* Label */}
                            {!dontShowLine && (
                                <Label x={(xStart + xEnd) / 2} y={yStart - labelMargin}>
                                    {DEBUG ? 'B-' : ''}
                                    {constructLaneLabel(lineLength, installation, form, lineNumber, viewType)} mm
                                </Label>
                            )}
                            <LineNumber x={(xStart + xEnd) / 2 + 55} y={yStart - labelMargin - 20} lineNumber={lineNumber} />
                            <LineEdit x={(xStart + xEnd) / 2 + 30} y={yStart - labelMargin - 15} lineNumber={lineNumber} />
                            {/* Rotierte Abschlusslinien */}
                        </>
                    )}
                {rotation % 360 === 90 &&
                    form !== 'O' && ( // VERTICAL: L-1, U-1, Z-2
                        <>
                            {/* Gerade */}
                            {/* Abschlusslinien */}
                            {/* Label */}
                            {!dontShowLine && (
                                <Label transform={'translate(40)'} x={xStart} y={yStart + (yEnd - yStart) / 2}>
                                    {DEBUG ? 'C-' : ''}
                                    {constructLaneLabel(lineLength, installation, form, lineNumber, viewType)} mm
                                </Label>
                            )}
                            <LineNumber transform={'translate(40)'} x={xStart + 55} y={yStart + (yEnd - yStart) / 2 - 15} lineNumber={lineNumber} />
                            <LineEdit transform={'translate(40)'} x={xStart + 30} y={yStart + (yEnd - yStart) / 2 - 10} lineNumber={lineNumber} />
                            {/* Rotierte Abschlusslinien */}
                        </>
                    )}
                {rotation % 360 === 90 &&
                    form === 'O' && ( // VERTICAL O-2
                        <>
                            {/* Gerade */}
                            {/* Abschlusslinien */}
                            {/* Label */}
                            {!dontShowLine && (
                                <Label transform={'translate(40)'} x={xStart + distanceBetweenLines} y={yStart + (yEnd - yStart) / 2}>
                                    {DEBUG ? 'D-' : ''}
                                    {constructLaneLabel(lineLength, installation, form, lineNumber, viewType)} mm
                                </Label>
                            )}
                            <LineNumber
                                transform={'translate(40)'}
                                x={xStart + distanceBetweenLines + 55}
                                y={yStart + (yEnd - yStart) / 2 - 15}
                                lineNumber={lineNumber}
                            />
                            <LineEdit
                                transform={'translate(40)'}
                                x={xStart + distanceBetweenLines + 30}
                                y={yStart + (yEnd - yStart) / 2 - 10}
                                lineNumber={lineNumber}
                            />
                            {/* Rotierte Abschlusslinien */}
                        </>
                    )}
                {rotation % 360 === 180 && form !== 'O' && (
                    <>
                        {/* Gerade */}
                        {/* Abschlusslinien */}
                        {/* Label */}
                        {!dontShowLine && (
                            <Label x={xEnd + width * 0.5} y={yStart + labelMargin}>
                                {DEBUG ? 'E-' : ''}
                                {constructLaneLabel(lineLength, installation, form, lineNumber, viewType)} mm
                            </Label>
                        )}
                        <LineNumber x={xEnd + width * 0.5 + 55} y={yStart + labelMargin - 12} lineNumber={lineNumber} />
                        <LineEdit x={xEnd + width * 0.5 + 30} y={yStart + labelMargin - 8} lineNumber={lineNumber} />
                        {/* Rotierte Abschlusslinien */}
                    </>
                )}
                {rotation % 360 === 270 &&
                    form !== 'O' && ( // VERTICAL: U-3, S-2 => CORRECT POSITION √
                        <>
                            {/* Gerade */}
                            {/* Abschlusslinien */}
                            {/* Label */}
                            {!dontShowLine && (
                                <Label transform={'translate(-40)'} x={xEnd - 100 * 0.5} y={yStart + (yEnd - yStart) / 2}>
                                    {DEBUG ? 'F-' : ''}
                                    {constructLaneLabel(lineLength, installation, form, lineNumber, viewType)} mm
                                </Label>
                            )}
                            <LineNumber transform={'translate(-40)'} x={xEnd - 100 * 0.5 + 55} y={yStart + (yEnd - yStart) / 2 - 15} lineNumber={lineNumber} />
                            <LineEdit transform={'translate(-40)'} x={xEnd - 100 * 0.5 + 30} y={yStart + (yEnd - yStart) / 2 - 10} lineNumber={lineNumber} />
                            {/* Rotierte Abschlusslinien */}
                        </>
                    )}
            </g>
        </>
    );
}
