import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selecSelectedVisibleLayer, setSelectedVisibleLayer } from "../features/products/productsSlice";
import Translator from "./Translator";
import { DEBUG } from "../services/domainService";

export default function LayerSelect() {
	const dispatch = useDispatch();
	const activeLayer = useSelector(selecSelectedVisibleLayer);

	return (
		<div className="absolute left-7 top-4 z-10">
			<div>
				<div className={"cursor-pointer inline-block group bg-white border border-layer-grey" + (activeLayer === 'optic' ? ' !border-black' : '')} onClick={() => {
					dispatch(setSelectedVisibleLayer('optic'));
				}}>
					<span className="w-8 h-8 inline-block text-center">
						<FontAwesomeIcon className="align-middle mt-1.5" icon={['fas', 'eye']} />
					</span>
					<span className="align-sub pr-3 text-14 hidden group-hover:inline-block">
						<Translator id="canvas.layer.profile.button" />
					</span>
				</div>
			</div>
			<div>
				<div className={"mt-[1px] inline-block cursor-pointer group bg-yellow border border-layer-grey" + (activeLayer === 'electric' ? ' !border-black' : '')} onClick={() => {
					dispatch(setSelectedVisibleLayer('electric'));
				}}>
					<span className="w-8 h-8 inline-block text-center">
						<FontAwesomeIcon className="align-middle mt-1.5" icon={['fas', 'bolt-lightning']} />
					</span>
					<span className="align-sub pr-3 text-14 hidden group-hover:inline-block">
						<Translator id="canvas.layer.electrical.button" />
					</span>
				</div>
			</div>
			{DEBUG &&
				<>
					<div>
						<div className={"mt-[1px] inline-block cursor-pointer text-white group bg-black border border-layer-grey" + (activeLayer === 'opticSimple' ? ' !border-yellow' : '')} onClick={() => {
							dispatch(setSelectedVisibleLayer('opticSimple'));
						}}>
							<span className="w-8 h-8 inline-block text-center">
								<FontAwesomeIcon className="align-middle mt-1.5" icon={['fas', 'eye']} />
							</span>
							<span className="align-sub pr-3 text-14 text-white hidden group-hover:inline-block">
								<Translator id="canvas.layer.profile.simple.button" />
							</span>
						</div>
					</div>
					<div>
						<div className={"mt-[1px] inline-block cursor-pointer group bg-gradient-to-r from-white to-configuration-grey border border-layer-grey" + (activeLayer === 'electricSimple' ? ' !border-black' : '')} onClick={() => {
							dispatch(setSelectedVisibleLayer('electricSimple'));
						}}>
							<span className="w-8 h-8 inline-block text-center">
								<FontAwesomeIcon className="align-middle mt-1.5" icon={['fas', 'bolt-lightning']} />
							</span>
							<span className="align-sub pr-3 text-14 hidden group-hover:inline-block">
								<Translator id="canvas.layer.electrical.simple.button" />
							</span>
						</div>
					</div>
				</>
			}
		</div>
	);
}