import AutoSizer from "react-virtualized-auto-sizer";
import DrawingCanvas from "./DrawingCanvas";
import React from "react";

export default function DrawingContainer() {
	
	return (
		<>
			<AutoSizer>
				{({ height, width}) => (
					<DrawingCanvas height={height} width={width} />
				)}
			</AutoSizer>
		</>
	);
}