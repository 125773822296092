import React from "react";
import styled from "styled-components";

/**
 * Line Number icon next to the length of each line
 *
 * @export
 * @param {*} {transform, x, y, lineNumber}
 * @return {*} 
 */
export default function LineNumber({transform, x, y, lineNumber}) {
	return (
		<foreignObject transform={transform} x={x} y={y} height={34} width={34}><Indicator>L{lineNumber}</Indicator></foreignObject>
	);
}

const Indicator = styled.span`
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    background: white;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.3);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: block;
    font-weight: 500;
    margin: 5px;
`;