import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { selectIsCHF, selectMaxLengthConfiguration } from '../../features/products/productsSlice';
import { useSelector } from 'react-redux';
import { selectCountryCode } from '../../features/configuration/configurationSlice';
import { SWISSCODE } from '../../services/service';
import Translator, { translatedHint } from '../Translator';

export default function LinePopup({
    transform,
    setShowLine,
    lineNumber,
    smallerLine,
    lengthLine,
    biggerLine,
    setInputVal,
    setLineIsSet,
    lineIsSet,
    x,
    y,
    setStyle,
}) {
    // Handle the maximum line length which is different according to product line and optics
    let maxLength = useSelector(selectMaxLengthConfiguration);
    let maxLengthInMeter = maxLength / 1000;
    // If popup shows huge message use different y
    y = lengthLine > maxLength ? y : y + 15;

    // Country specific formatting
    const countryCode = useSelector(selectCountryCode);
    let isLanguageCH = useSelector(selectIsCHF);
    let thousandSeparator = isLanguageCH ? "'" : '.';
    let decimalSeparator = isLanguageCH ? ',' : ',';
    const invisiblePopupStyle = 'popup-invisible invisible text-red-100';

    return (
        <>
            <foreignObject transform={transform} x={x} y={y} width={344} height={130}>
                <div className="fixed w-full h-full">
                    <div className="bg-black text-white pt-2 p-4 relative">
                        <div className="text-right leading-none">
                            <FontAwesomeIcon
                                className="cursor-pointer"
                                icon={['far', 'fa-xmark']}
                                onClick={() => {
                                    setShowLine(false);
                                }}
                            />
                        </div>
                        <div className="flex justify-between items-center">
                            <span className={'text-11' + (smallerLine ? '' : ' invisible pointer-events-none')}>
                                <Translator id="canvas.length.popup.shorter" />
                            </span>
                            <span className="font-book">
                                <Translator id="popup.save.configuration.line" />
                                {' ' + lineNumber}
                            </span>
                            <span className={'text-11' + (biggerLine ? '' : ' invisible pointer-events-none')}>
                                <Translator id="canvas.length.popup.longer" />
                            </span>
                        </div>
                        {lengthLine > maxLength && (
                            <div className="mt-1">
                                <p>
                                    <Translator id="canvas.length.popup.maxhint" values={{ status: translatedHint, maxLengthInMeter: maxLengthInMeter }} />
                                </p>
                            </div>
                        )}
                        {lengthLine <= maxLength && (
                            <div className="mt-3 flex justify-between">
                                <NumberFormat
                                    className={
                                        'font-book w-24 text-center bg-white text-nav-border-grey inline-block text-13 py-1.5 cursor-pointer mr-3' +
                                        (smallerLine ? '' : ' invisible pointer-events-none')
                                    }
                                    value={smallerLine}
                                    displayType={'text'}
                                    thousandSeparator={thousandSeparator}
                                    decimalSeparator={decimalSeparator}
                                    decimalScale={0}
                                    suffix={' mm'}
                                    onClick={() => {
                                        setStyle(invisiblePopupStyle);
                                        setInputVal(smallerLine);
                                        setLineIsSet(true);
                                    }}
                                />
                                <NumberFormat
                                    className={
                                        'font-book w-24 text-center bg-white text-nav-border-grey inline-block text-13 py-1.5 cursor-pointer mr-3' +
                                        (lineIsSet ? ' bg-green-200' : '') +
                                        (lengthLine ? '' : ' invisible pointer-events-none')
                                    }
                                    value={lengthLine}
                                    displayType={'text'}
                                    thousandSeparator={thousandSeparator}
                                    decimalSeparator={decimalSeparator}
                                    decimalScale={0}
                                    suffix={' mm'}
                                    onClick={() => {
                                        setStyle(invisiblePopupStyle);
                                        setInputVal(lengthLine);
                                        setLineIsSet(true);
                                    }}
                                />
                                <NumberFormat
                                    className={
                                        'font-book w-24 text-center bg-white text-nav-border-grey inline-block text-13 py-1.5 cursor-pointer' +
                                        (biggerLine ? '' : ' invisible pointer-events-none')
                                    }
                                    value={biggerLine}
                                    displayType={'text'}
                                    thousandSeparator={thousandSeparator}
                                    decimalSeparator={decimalSeparator}
                                    decimalScale={0}
                                    suffix={' mm'}
                                    onClick={() => {
                                        setStyle(invisiblePopupStyle);
                                        setInputVal(biggerLine);
                                        setLineIsSet(true);
                                    }}
                                />
                            </div>
                        )}
                        <div className="bg-black absolute w-5 h-5 rotate-45 -z-10 left-1/2 -translate-x-1/2" />
                    </div>
                </div>
            </foreignObject>
        </>
    );
}
