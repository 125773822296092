import React, { useEffect, useState } from 'react';
import {
    calculateDiffusorsWithQuantity,
    calculateEquipmentWithQuantity,
    calculateProductList,
    calculateProfilesWithQuantity,
    calculateSystemComponentsWithQuantity,
    calculateSystemEquipmentWithQuantity,
    createAXDownload,
    createDialuxDownload,
    createInstallationMappe,
    createReluxDownload,
    downloadPDF,
} from '../services/service';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCurrency,
    selectLanguage,
    selectOverallLumen,
    selectOverallWatt,
    selectProductCombinations,
    selectProfileSimpleSVG,
    selectProfileSVG,
    selectResultSimpleSVG,
    selectResultSVG,
    selectRulesetName,
    selectUserDiffusors,
    selectUserEQP,
    selectUserProducts,
    selectUserProfiles,
    selectUserSelectedForm,
    selectUserSelectedInstallation,
    selectUserSelectedProtection,
    selectUserSystemComponents,
    selectUserSystemEquipment,
    setLoadingFinished,
    setShowDownloadModal,
} from '../features/products/productsSlice';
import {
    selectConfigurationColor,
    selectConfigurationDiffusor,
    selectConfigurationID,
    selectConfigurationLineLengths,
    selectConfigurationPower,
    selectProjectAuthor,
    selectProjectBuilding,
    selectProjectFloor,
    selectProjectLine,
    selectProjectName,
    selectProjectRoom,
    selectPureliteSlimLighting,
} from '../features/configuration/configurationSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import Button from './Button';
import Translator from './Translator';

export default function Downloads() {
    const dispatch = useDispatch();

    const [selectedInstallationsmappe, setSelectedInstallationsmappe] = useState(false);
    const [selectedReluxExport, setSelectedReluxExport] = useState(false);
    const [selectedDialuxExport, setSelectedDialuxExport] = useState(false);
    const [selectedAXExport, setSelectedAXExport] = useState(false);
    const [downloadAllActive, setDownloadAllActive] = useState(false);
    const [installationMapDownloadLink, setInstallationMapDownloadLink] = useState(false);
    const [waitingForDownload, setWaitingForDownload] = useState(false);

    const [reluxDownloadFinished, setReluxDownloadFinished] = useState(true);
    const [dialuxDownloadFinished, setDialuxDownloadFinished] = useState(true);
    const [instamapDownloadFinished, setInstamapDownloadFinished] = useState(true);
    const [axDownloadFinished, setAXDownloadFinished] = useState(true);

    const userProducts = useSelector(selectUserProducts);
    let productsWithQuantity = calculateProductList(userProducts);
    const currentForm = useSelector(selectUserSelectedForm);
    const currentInstallation = useSelector(selectUserSelectedInstallation);
    const currentDiffusor = useSelector(selectConfigurationDiffusor);
    const combinationProducts = useSelector(selectProductCombinations);
    // TODO: Change simple SVG to real simple one for the installation map
    const electricalSimpleSVG = useSelector(selectResultSimpleSVG);
    const profileSimpleSVG = useSelector(selectProfileSimpleSVG);
    const configurationID = useSelector(selectConfigurationID);
    const projectName = useSelector(selectProjectName);
    const projectAuthor = useSelector(selectProjectAuthor);
    const currentColor = useSelector(selectConfigurationColor);
    const currentPower = useSelector(selectConfigurationPower);
    const lineLengths = useSelector(selectConfigurationLineLengths);
    const rulesetName = useSelector(selectRulesetName);
    const currentIP = useSelector(selectUserSelectedProtection);
    const configurationWatt = useSelector(selectOverallWatt);
    const configurationLumen = useSelector(selectOverallLumen);
    const projectBuilding = useSelector(selectProjectBuilding);
    const projectFloor = useSelector(selectProjectFloor);
    const projectRoom = useSelector(selectProjectRoom);
    const projectLine = useSelector(selectProjectLine);
    const lightingDesign = useSelector(selectPureliteSlimLighting);

    const equipment = useSelector(selectUserEQP);
    const systemEquipment = useSelector(selectUserSystemEquipment);
    const systemComponents = useSelector(selectUserSystemComponents);
    const profiles = useSelector(selectUserProfiles);
    const diffusors = useSelector(selectUserDiffusors);
    const language = useSelector(selectLanguage);
    const currency = useSelector(selectCurrency);

    let equipmentWithQuantity = equipment ? calculateEquipmentWithQuantity(JSON.parse(equipment)) : null;
    let systemEquipmentWithQuantity = systemEquipment ? calculateSystemEquipmentWithQuantity(JSON.parse(systemEquipment)) : null;
    let systemComponentsWithQuantity = systemComponents ? calculateSystemComponentsWithQuantity(JSON.parse(systemComponents)) : null;
    let profilesWithQuantity = profiles ? calculateProfilesWithQuantity(JSON.parse(profiles)) : null;
    let diffusorsWithQuantity = diffusors ? calculateDiffusorsWithQuantity(JSON.parse(diffusors)) : null;

    useEffect(() => {
        if (selectedInstallationsmappe || selectedReluxExport || selectedDialuxExport || selectedAXExport) {
            setDownloadAllActive(true);
        } else {
            setDownloadAllActive(false);
        }
    }, [selectedInstallationsmappe, selectedReluxExport, selectedDialuxExport, selectedAXExport]);

    useEffect(() => {
        createInstallationMappe(
            productsWithQuantity,
            currentForm,
            currentInstallation,
            currentDiffusor,
            electricalSimpleSVG,
            configurationID,
            projectName,
            projectAuthor,
            currentColor,
            currentPower,
            lineLengths,
            rulesetName,
            profileSimpleSVG,
            currentIP,
            equipmentWithQuantity,
            systemEquipmentWithQuantity,
            systemComponentsWithQuantity,
            profilesWithQuantity,
            diffusorsWithQuantity,
            configurationWatt,
            configurationLumen,
            projectBuilding,
            projectFloor,
            projectRoom,
            projectLine,
            lightingDesign,
            language,
            currency
        ).then((r) => {
            setInstallationMapDownloadLink(r.data);
            dispatch(setLoadingFinished(true));
        });
    }, []);

    useEffect(() => {
        if (installationMapDownloadLink && waitingForDownload) {
            downloadPDF(installationMapDownloadLink, configurationID);
            dispatch(setShowDownloadModal(false));
            setInstamapDownloadFinished(true);
        }
    }, [installationMapDownloadLink, waitingForDownload]);

    useEffect(() => {
        if (instamapDownloadFinished && reluxDownloadFinished && dialuxDownloadFinished && axDownloadFinished) {
            dispatch(setShowDownloadModal(false));
        }
    }, [instamapDownloadFinished, reluxDownloadFinished, dialuxDownloadFinished, axDownloadFinished]);

    function downloadSelected() {
        dispatch(setShowDownloadModal(true));

        if (selectedInstallationsmappe) {
            setInstamapDownloadFinished(false);
            if (installationMapDownloadLink) {
                downloadPDF(installationMapDownloadLink, configurationID);
                setInstamapDownloadFinished(true);
            } else {
                setWaitingForDownload(true);
            }
        }
        if (selectedReluxExport) {
            setReluxDownloadFinished(false);
            createReluxDownload(userProducts, currentDiffusor, combinationProducts, configurationID, projectName, currentPower, currentForm).then((r) =>
                setReluxDownloadFinished(true)
            );
        }
        if (selectedDialuxExport) {
            setDialuxDownloadFinished(false);
            createDialuxDownload(
                userProducts,
                currentDiffusor,
                combinationProducts,
                currentInstallation,
                rulesetName,
                currentColor,
                currentPower,
                currentForm,
                configurationID
            ).then((r) => setDialuxDownloadFinished(true));
        }
        if (selectedAXExport) {
            dispatch(setShowDownloadModal(true));
            createAXDownload(
                productsWithQuantity,
                projectName,
                configurationID,
                projectBuilding,
                projectFloor,
                projectRoom,
                projectLine,
                equipmentWithQuantity,
                systemEquipmentWithQuantity,
                systemComponentsWithQuantity,
                profilesWithQuantity,
                diffusorsWithQuantity
            );
            dispatch(setShowDownloadModal(false));
        }
    }

    return (
        <>
            <table className="w-full text-15">
                <thead className="text-layer-grey">
                    <tr>
                        <td>
                            <Checkbox
                                active={downloadAllActive}
                                onClick={() => {
                                    if (downloadAllActive) {
                                        setSelectedInstallationsmappe(false);
                                        setSelectedReluxExport(false);
                                        setSelectedDialuxExport(false);
                                        setSelectedAXExport(false);
                                    } else {
                                        setSelectedInstallationsmappe(!selectedInstallationsmappe);
                                        setSelectedReluxExport(!selectedReluxExport);
                                        setSelectedDialuxExport(!selectedDialuxExport);
                                        setSelectedAXExport(!selectedAXExport);
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={['far', 'check']} className={downloadAllActive ? '' : 'invisible'} />
                            </Checkbox>
                        </td>
                        <td>
                            <Translator id="overview.downloads.table.header.information" />
                        </td>
                        <td>
                            <Translator id="overview.downloads.table.header.format" />
                        </td>
                        <td>
                            <Translator id="overview.downloads.table.header.file" />
                        </td>
                        <td />
                    </tr>
                </thead>
                <tbody className="font-book">
                    <tr>
                        <td onClick={() => setSelectedInstallationsmappe(!selectedInstallationsmappe)}>
                            <Checkbox active={selectedInstallationsmappe}>
                                <FontAwesomeIcon icon={['far', 'check']} className={selectedInstallationsmappe ? '' : 'invisible'} />
                            </Checkbox>
                        </td>
                        <td>
                            <Translator id="overview.downloads.table.information.setup" />
                        </td>
                        <td>PDF</td>
                        <td>
                            <FontAwesomeIcon icon={['far', 'file-pdf']} className="mr-2" />
                            regent-konfigurator-{configurationID}-installationsmappe.pdf
                        </td>
                        <td>
                            <FontAwesomeIcon
                                className="cursor-pointer"
                                icon={['far', 'download']}
                                onClick={() => {
                                    if (installationMapDownloadLink) {
                                        downloadPDF(installationMapDownloadLink, configurationID);
                                        dispatch(setShowDownloadModal(false));
                                    } else {
                                        setWaitingForDownload(true);
                                        dispatch(setShowDownloadModal(true));
                                    }
                                }}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td onClick={() => setSelectedReluxExport(!selectedReluxExport)}>
                            <Checkbox active={selectedReluxExport}>
                                <FontAwesomeIcon icon={['far', 'check']} className={selectedReluxExport ? '' : 'invisible'} />
                            </Checkbox>
                        </td>
                        <td>
                            <Translator id="overview.downloads.table.information.relux" />
                        </td>
                        <td>ROLFZ</td>
                        <td>
                            <FontAwesomeIcon icon={['far', 'file']} className="mr-2" />
                            regent-konfigurator-{configurationID}.rolfz
                        </td>
                        <td>
                            <FontAwesomeIcon
                                className="cursor-pointer"
                                icon={['far', 'download']}
                                onClick={() => {
                                    dispatch(setShowDownloadModal(true));
                                    createReluxDownload(
                                        userProducts,
                                        currentDiffusor,
                                        combinationProducts,
                                        configurationID,
                                        projectName,
                                        currentPower,
                                        currentForm
                                    ).then((r) => dispatch(setShowDownloadModal(false)));
                                }}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td onClick={() => setSelectedDialuxExport(!selectedDialuxExport)}>
                            <Checkbox active={selectedDialuxExport}>
                                <FontAwesomeIcon icon={['far', 'check']} className={selectedDialuxExport ? '' : 'invisible'} />
                            </Checkbox>
                        </td>
                        <td>
                            <Translator id="overview.downloads.table.information.dialux" />
                        </td>
                        <td>ULD</td>
                        <td>
                            <FontAwesomeIcon icon={['far', 'file']} className="mr-2" />
                            regent-konfigurator-{configurationID}.uld
                        </td>
                        <td>
                            <FontAwesomeIcon
                                className="cursor-pointer"
                                icon={['far', 'download']}
                                onClick={() => {
                                    dispatch(setShowDownloadModal(true));
                                    createDialuxDownload(
                                        userProducts,
                                        currentDiffusor,
                                        combinationProducts,
                                        currentInstallation,
                                        rulesetName,
                                        currentColor,
                                        currentPower,
                                        currentForm,
                                        configurationID
                                    ).then((r) => dispatch(setShowDownloadModal(false)));
                                }}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td onClick={() => setSelectedAXExport(!selectedAXExport)}>
                            <Checkbox active={selectedAXExport}>
                                <FontAwesomeIcon icon={['far', 'check']} className={selectedAXExport ? '' : 'invisible'} />
                            </Checkbox>
                        </td>
                        <td>
                            <Translator id="overview.downloads.table.information.ax" />
                        </td>
                        <td>XML</td>
                        <td>
                            <FontAwesomeIcon icon={['far', 'file']} className="mr-2" />
                            regent-konfigurator-{configurationID}.xml
                        </td>
                        <td>
                            <FontAwesomeIcon
                                className="cursor-pointer"
                                icon={['far', 'download']}
                                onClick={() => {
                                    dispatch(setShowDownloadModal(true));
                                    createAXDownload(
                                        productsWithQuantity,
                                        projectName,
                                        configurationID,
                                        projectBuilding,
                                        projectFloor,
                                        projectRoom,
                                        projectLine,
                                        equipmentWithQuantity,
                                        systemEquipmentWithQuantity,
                                        systemComponentsWithQuantity,
                                        profilesWithQuantity,
                                        diffusorsWithQuantity
                                    );
                                    dispatch(setShowDownloadModal(false));
                                }}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="text-right">
                <Button
                    className={
                        'mt-4 text-white py-2 px-5 text-xl border-white border hover:brightness-75 duration-300 ' +
                        (downloadAllActive ? '' : 'pointer-events-none opacity-50')
                    }
                    onClick={() => {
                        downloadSelected();
                    }}
                >
                    <FontAwesomeIcon icon={['far', 'file-archive']} className="mr-2" />
                    <Translator id="overview.downloads.button" />
                </Button>
            </div>
        </>
    );
}

const Checkbox = styled.div`
    display: inline-block;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    width: 18px;
    height: 18px;
    color: ${(props) => (props.active ? 'white' : '#A6A6A6')};
    background-color: 'transparent';
    border: 1px solid;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    padding: 2px 0 0;
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};
    box-sizing: border-box;
`;
