import { current } from '@reduxjs/toolkit';
import {
    calculateProfileDrawingCoordinates,
    calculateOverallLumenAndWatt,
    calculateOperatingDevices,
    calculateDrawingCoordinates,
    getCorrectProfileLengths,
    calculatePrices,
    selectLanguage,
} from './productsSlice';
import { createIntl, createIntlCache } from 'react-intl';
import { imperativeTranslateMessage } from '../../components/Translator';

import german from '../../resources/de.json';
import english from '../../resources/en.json';
import french from '../../resources/fr.json';
import italian from '../../resources/it.json';
import { DEBUG } from '../../services/domainService';
import { useSelector } from 'react-redux';

/**
 * Calculates Flow
 * @param state
 */
export function doCalculationFlow(state) {
    let userLength = state.userLengths.line1;
    let rulesetConstants = state.constants;
    let lineLengths = JSON.parse(current(state).lineLengths);

    //defining arrays for calculating elements
    let elements = [];
    let master = lineLengths.filter((item) => item.type === 'master');
    let slave = lineLengths.filter((item) => item.type === 'slave');
    //sort arrays after line length desc
    master.sort((a, b) => b.length - a.length);
    slave.sort((a, b) => b.length - a.length);

    //resetting profileLengths & diffusorLengths
    state.profileLengths = null;
    state.diffusorLengths = null;

    calculateFlow(state, userLength, rulesetConstants, master, slave, elements);

    //save calculated elements to state
    state.lineElements = JSON.stringify(elements);

    //resetting price
    state.productPriceEur = 0;
    state.productPriceChf = 0;

    mapElementsToProducts(state, elements, lineLengths);
    mapProfilesToProductsFlow(state);

    mapEQPToProductsFlow(state);
    mapSystemEquipmentToProductsFlow(state);

    state.isSimplifiedDrawing = true;
    if (current(state).isSimplifiedDrawing) {
        calculateProfileDrawingCoordinates(state, false);
    }
    state.isSimplifiedDrawing = false;
    calculateProfileDrawingCoordinates(state, false);
    calculateOverallLumenAndWatt(state);
    calculateOperatingDevices(state, elements);
    state.isSimplifiedDrawing = true;
    if (current(state).isSimplifiedDrawing) {
        calculateDrawingCoordinates(state, elements, false);
    }
    state.isSimplifiedDrawing = false;
    calculateDrawingCoordinates(state, elements, false);
}

function mapElementsToProducts(state, elements, lineLengths) {
    const rulesetLightingSystemElements = current(state).rulesetLightingSystemElements;
    let choosenProducts = [];

    let product_line = current(state).rulesetName;
    let current_diffusor = current(state).userSelectedDiffusor;

    let power = state.userSelectedPower ? state.userSelectedPower.name : 'xHE';
    let color = state.userSelectedColor ? state.userSelectedColor.kelvin : 4000;
    //needed(not & emergency) for Not-Lichtleise
    let needsEmergency = state.userSelectedEmergency ? state.userSelectedEmergency : false;
    let emergency = '0';
    if (current_diffusor === 'asymmetric') {
        if (color === 'TW') {
            power = 'HPE';
        } else {
            power = 'HO';
        }
    }
    for (let i = 0; i < elements.length; i++) {
        let itemLength = elements[i].length;
        let product;
        if (elements[i].type === 'master') {
            if (color === 'TW') {
                // eslint-disable-next-line eqeqeq
                product = rulesetLightingSystemElements.filter(
                    (item) =>
                        item.type === elements[i].type &&
                        item.length === itemLength &&
                        item.color == color &&
                        item.power === power &&
                        item.product_line === product_line
                );
            } else {
                // eslint-disable-next-line eqeqeq
                product = rulesetLightingSystemElements.filter(
                    (item) =>
                        item.type === elements[i].type &&
                        item.length === itemLength &&
                        !!item.expandable === elements[i].expandable &&
                        item.color == color &&
                        item.power === power &&
                        item.product_line === product_line
                );
            }
        } else if (elements[i].type === 'slave') {
            // eslint-disable-next-line eqeqeq
            product = rulesetLightingSystemElements.filter(
                (item) =>
                    item.type === elements[i].type &&
                    item.length === itemLength &&
                    !!item.expandable === elements[i].expandable &&
                    item.color == color &&
                    item.product_line == product_line
            );
        } else {
            console.log('kein gültiges Element');
        }

        if (product.length > 1) {
            //if more than 1 product, we have to filter if diffusor is asymmetric
            if (current_diffusor === 'asymmetric') {
                product = product.filter((item) => item.article_code.includes('AB') && !item.article_code.includes('E3h'));
            } else {
                product = product.filter((item) => !item.article_code.includes('AB') && !item.article_code.includes('E3h'));
            }
        }
        choosenProducts.push(product[0]);
    }

    if (needsEmergency) {
        emergency = '3';
        let needNewMasters = true;
        for (let i = 0; i < choosenProducts.length; i++) {
            let productHelper = choosenProducts[i];
            if (productHelper.type == 'master' && productHelper.length == 1468) {
                needNewMasters = false;
                let newProduct = rulesetLightingSystemElements.filter(
                    (item) =>
                        item.type === elements[i].type &&
                        item.length === 1468 &&
                        item.color == color &&
                        item.power === power &&
                        item.product_line === product_line &&
                        item.emergency === emergency
                );
                choosenProducts.splice(i, 1, newProduct[0]);
                break;
            }
        }
        if (needNewMasters) {
            let index = choosenProducts.length - 1;
            let productHelper = choosenProducts[index];
            if (productHelper.type == 'master' && productHelper.length == 2938) {
                let newProduct = rulesetLightingSystemElements.filter(
                    (item) =>
                        item.type === elements[index].type &&
                        item.length === 1468 &&
                        item.color == color &&
                        item.power === power &&
                        item.product_line === product_line &&
                        item.emergency === emergency
                );
                choosenProducts.splice(index, 1, newProduct[0]);
                changeElementsForNot(state, elements, lineLengths);
                newProduct = rulesetLightingSystemElements.filter((item) => !item.article_code.includes('AB') && !item.article_code.includes('E3h'));
                choosenProducts.push(newProduct[0]);
            }
        }
    }
    state.userProducts = choosenProducts;
    calculatePrices(state, choosenProducts);
}
function mapProfilesToProductsFlow(state) {
    let profilesDB = JSON.parse(state.profiles);
    let profiles = [];
    //2006.6090 id for expandable profiles
    let profilesExpandable = [];
    let profileProducts = [];
    let profileLengths = getCorrectProfileLengths(state);

    if (typeof profilesDB === 'object') {
        let profileIndexes = [];
        Object.keys(profilesDB).forEach((key, index) => {
            profileIndexes.push(key);
        });
        let temp = [];
        for (let i = 0; i < profileIndexes.length; i++) {
            temp.push(profilesDB[profileIndexes[i]]);
        }
        profilesDB = temp;
    }
    profilesDB.sort((a, b) => b.length - a.length);
    profiles = profilesDB.filter((item) => !item.article_code.includes('.V'));
    profilesExpandable = profilesDB.filter((item) => item.article_code.includes('.V'));
    profilesExpandable.sort((a, b) => a.length - b.length);
    let rest = profileLengths[0].profile;
    let counter = 0;
    //test lenght: 18.111
    for (let i = 0; i < profiles.length; i++) {
        let profileLenghtHelper = profiles[i].length;
        while (rest > profileLenghtHelper) {
            let restHelper;
            restHelper = rest - profileLenghtHelper;
            if (restHelper > 500) {
                profileProducts.push({
                    product: profiles[i],
                    cut: null,
                    line: profileLengths[0].line,
                });
                rest = rest - profileLenghtHelper;
            } else {
                break;
            }
            counter++;
        }
    }
    for (let i = 0; profilesExpandable.length; i++) {
        if (rest < profilesExpandable[i].length) {
            profileProducts.push({
                product: profilesExpandable[i],
                cut: rest,
                line: profileLengths[0].line,
            });
            break;
        }
    }
    state.userProfiles = JSON.stringify(profileProducts);
    calculatePrices(state, profileProducts);
}

function mapEQPToProductsFlow(state) {
    //products from ruleset
    let equipment = JSON.parse(state.equipment);
    let systemEquipment = JSON.parse(state.systemEquipment);
    /** @type {"pendulum" | "recessed" | "ceiling" | null} */
    let installation = state.userSelectedInstallation;
    let profileLengths = getCorrectProfileLengths(state);
    let totalProfileLength = 0;
    let wiring = JSON.parse(state.userWirings);

    //convert object into array to use filter method
    if (typeof systemEquipment === 'object') {
        let systemEquipmentIndexes = [];
        Object.keys(systemEquipment).forEach((key, index) => {
            systemEquipmentIndexes.push(key);
        });

        let temp = [];
        for (let i = 0; i < systemEquipmentIndexes.length; i++) {
            temp.push(systemEquipment[systemEquipmentIndexes[i]]);
        }
        systemEquipment = temp;
    }
    //calculating total profile length
    profileLengths.map((profile) => (totalProfileLength += profile.profile));

    //calculated products for user
    let userEquipment = [];

    // need in flow ?
    // -------------------------------------------------------------------------------------
    //Befestigungs-Set Purelite Slim D, FASTEN-KIT alle 1.5m
    /* if(installation === "recessed")
	{
		for (let i = 0; i < Math.floor(totalProfileLength / 1500); i++) {
			// IF selection uses CT // TODO CT/TL selection
			if (installation === 'recessed') {
				userEquipment.push(equipment.filter(item => item.article_nr === '2004.4082')[0]); // PURLT S EQP FASTEN-KIT CT
			}
			// If selection uses TL
			else if (installation === 'ceiling' || installation === 'pendulum') { // TODO selection
				userEquipment.push(equipment.filter(item => item.article_nr === '2004.4083')[0]); // PURLT S EQP FASTEN-KIT TL
			}
		}
	}
	*/
    // -------------------------------------------------------------------------------------
    // Schlitzscheiben-Set - Everything but pendulum adds the slotted disc sets
    let neededSuspensions = Math.ceil(totalProfileLength / 1500);
    if (installation === 'recessed' || installation === 'ceiling') {
        while (neededSuspensions > 0) {
            if (neededSuspensions >= 5) {
                userEquipment.push(equipment.filter((item) => item.article_code === 'PURLT EQP WSH-SLO-KIT 5PCS')[0]);
                neededSuspensions = neededSuspensions - 5;
            } else {
                userEquipment.push(equipment.filter((item) => item.article_code === 'PURLT EQP WSH-SLO-KIT 2PCS')[0]);
                neededSuspensions = neededSuspensions - 2;
            }
        }
    }
    // Stirnseiten-Set
    // Always include Flow
    userEquipment.push(equipment.filter((item) => item.article_nr === '2006.9455')[0]);

    // if(daliPlaceholder) {
    // userEquipment.push(-- dali set --)
    // } else {
    // userEquipment.push(-- on / off set --)
    //}

    //Drahthalter-Set
    let neededHolder = Math.ceil(totalProfileLength / 10000) - (wiring ? 1 : 0);
    while (neededHolder > 0) {
        userEquipment.push(equipment.filter((item) => item.article_nr === '1005.8373')[0]);
        neededHolder = neededHolder - 1;
    }

    //Aufhängungs-Set (Pendel)
    if (installation === 'pendulum') {
        userEquipment.push(equipment.filter((item) => item.article_nr === '2006.5841')[0]);
        let neededSuspensions = Math.ceil(totalProfileLength / 2000) - 2;
        while (neededSuspensions > 0) {
            userEquipment.push(equipment.filter((item) => item.article_nr === '2004.4907')[0]);
            neededSuspensions = neededSuspensions - 1;
        }
    } else {
        console.log('Keine Pendelvariante!');
    }

    state.userEQP = JSON.stringify(userEquipment);
    calculatePrices(state, userEquipment);
}
function mapSystemEquipmentToProductsFlow(state) {
    let systemEquipment = JSON.parse(state.systemEquipment);
    let profiles = JSON.parse(state.userProfiles);
    let wiring = JSON.parse(state.userWirings);
    let needsEmergency = state.userSelectedEmergency ? state.userSelectedEmergency : false;
    let userDiffusors = [];

    //convert object into array to use filter method
    if (typeof systemEquipment === 'object') {
        let systemEquipmentIndexes = [];
        Object.keys(systemEquipment).forEach((key, index) => {
            systemEquipmentIndexes.push(key);
        });

        let temp = [];
        for (let i = 0; i < systemEquipmentIndexes.length; i++) {
            temp.push(systemEquipment[systemEquipmentIndexes[i]]);
        }
        systemEquipment = temp;
    }
    let userSystemEquipment = [];
    //Profilverbinder-Set
    for (let i = 0; i < profiles.length - 1; i++) {
        userSystemEquipment.push(systemEquipment.filter((item) => item.article_nr === '1005.8372')[0]);
    }

    //Durchgangsverdrahtung
    if (wiring === true) {
        userSystemEquipment.push(systemEquipment.filter((item) => item.article_nr === '1005.7449')[0]);
    }

    // Diffusor
    let diffusorFlow = [];
    diffusorFlow.push(systemEquipment.filter((item) => item.article_nr === '2008.0754')[0]);
    diffusorFlow.push(systemEquipment.filter((item) => item.article_nr === '2006.2414')[0]);
    diffusorFlow.push(systemEquipment.filter((item) => item.article_nr === '2008.0755')[0]);
    diffusorFlow.push(systemEquipment.filter((item) => item.article_nr === '2006.3548')[0]);
    diffusorFlow.sort((a, b) => b.length - a.length);

    let diffusorLengths = JSON.parse(state.diffusorLengths);
    let rest = diffusorLengths[0].length;

    for (let i = 0; i < diffusorFlow.length; i++) {
        let diffusorLenghtHelper = diffusorFlow[i].length;
        while (rest > diffusorLenghtHelper) {
            let restHelper;
            restHelper = rest - diffusorLenghtHelper;
            if (restHelper > 500) {
                if (rest - diffusorLenghtHelper > 500) {
                    userDiffusors.push(diffusorFlow[i]);
                    rest = rest - diffusorLenghtHelper;
                }
            } else {
                break;
            }
        }
    }
    if (rest > 500 && rest < diffusorFlow[diffusorFlow.length - 1].length) {
        userDiffusors.push(diffusorFlow[diffusorFlow.length - 1]);
        rest = 0;
    }
    state.userSystemEquipment = JSON.stringify(userSystemEquipment);
    calculatePrices(state, userSystemEquipment);

    state.userDiffusors = JSON.stringify(userDiffusors);
    calculatePrices(state, userDiffusors);
}

function calculateFlow(state, userLength, rulesetConstants, master, slave, elements, lightLength = null) {
    //if we have a calculated lightLength, don't calculate it again
    if (lightLength === null) {
        //Calculate lightLength and saving debugging values
        lightLength = calculateValuesFlow(state, userLength, rulesetConstants, elements, master, slave);
    }
}

/**
 * Calculates all needed values for Flow
 * @param state
 * @param userLength
 * @param rulesetConstants
 * @returns {number}
 */
function calculateValuesFlow(state, userLength, rulesetConstants, elements, master, slave) {
    let profileLength = userLength - 8;
    let gewuenschteProfillaenge = profileLength - 2 * 3;
    let gewuenschteLichtleistenlaenge = gewuenschteProfillaenge - 10;

    let lengthBigMasterFlow = 2938;
    let lengthSmallMasterFlow = 1468;

    let countBigMasterFLow = 0;
    let countSmallMasterFLow = 0;

    if (gewuenschteLichtleistenlaenge - Math.floor(gewuenschteLichtleistenlaenge / lengthBigMasterFlow) * lengthBigMasterFlow >= lengthSmallMasterFlow) {
        countBigMasterFLow = Math.floor(gewuenschteLichtleistenlaenge / lengthBigMasterFlow);
    } else {
        countBigMasterFLow = Math.floor(gewuenschteLichtleistenlaenge / lengthBigMasterFlow) - 1;
    }

    if (gewuenschteLichtleistenlaenge - countBigMasterFLow * lengthBigMasterFlow >= lengthSmallMasterFlow) {
        countSmallMasterFLow = Math.floor((gewuenschteLichtleistenlaenge - countBigMasterFLow * lengthBigMasterFlow) / lengthSmallMasterFlow);
    } else {
        countSmallMasterFLow = 0;
    }
    checkSlaveCombinations(
        state,
        userLength,
        gewuenschteLichtleistenlaenge,
        countBigMasterFLow,
        countSmallMasterFLow,
        lengthBigMasterFlow,
        lengthSmallMasterFlow,
        elements,
        master,
        slave
    );
}

function checkSlaveCombinations(
    state,
    userLength,
    gewuenschteLichtleistenlaenge,
    countBigMasterFLow,
    countSmallMasterFLow,
    lengthBigMasterFlow,
    lengthSmallMasterFlow,
    elements,
    master,
    slave
) {
    let valueToCheckWithTable = gewuenschteLichtleistenlaenge - (countBigMasterFLow * lengthBigMasterFlow + countSmallMasterFLow * lengthSmallMasterFlow);
    let smallerSummary;
    let biggerSummary;
    let optionSmaller;
    let optionBigger;
    let amountBigMasterFlow = 0;
    let amountSmallMasterFlow = 0;
    let amountBigSlaveFlow = 0;
    let amountSmallSlaveFlow = 0;
    let gesamtlaenge = 0;
    let diffusorlaenge = 0;
    let wunschlaenge = 0;
    let aussparung = 0;
    let profillaenge = 0;
    let lichtleistenlaenge = 0;
    let kabellaenge = 0;

    if (valueToCheckWithTable >= 0 && valueToCheckWithTable < 2) {
    } else if (valueToCheckWithTable >= 2 && valueToCheckWithTable < 300) {
        smallerSummary = 2;
        biggerSummary = 300;
    } else if (valueToCheckWithTable >= 300 && valueToCheckWithTable < 585) {
        smallerSummary = 300;
        biggerSummary = 585;
        amountBigSlaveFlow = 0;
        amountSmallSlaveFlow = 1;
    } else if (valueToCheckWithTable >= 585 && valueToCheckWithTable < 600) {
        smallerSummary = 585;
        biggerSummary = 600;
        amountBigSlaveFlow = 1;
        amountSmallSlaveFlow = 0;
    } else if (valueToCheckWithTable >= 600 && valueToCheckWithTable < 885) {
        smallerSummary = 600;
        biggerSummary = 885;
        amountBigSlaveFlow = 0;
        amountSmallSlaveFlow = 2;
    } else if (valueToCheckWithTable >= 885 && valueToCheckWithTable < 1170) {
        smallerSummary = 885;
        biggerSummary = 1170;
        amountBigSlaveFlow = 1;
        amountSmallSlaveFlow = 1;
    } else if (valueToCheckWithTable >= 1170 && valueToCheckWithTable < 1468) {
        smallerSummary = 1170;
        biggerSummary = 1468;
        amountBigSlaveFlow = 2;
        amountSmallSlaveFlow = 0;
    } else {
        //DO STUFF
    }
    optionSmaller = smallerSummary + countBigMasterFLow * lengthBigMasterFlow + countSmallMasterFLow * lengthSmallMasterFlow + 10 + 2 * 3 + 8;
    optionBigger = biggerSummary + countBigMasterFLow * lengthBigMasterFlow + countSmallMasterFLow * lengthSmallMasterFlow + 10 + 2 * 3 + 8;
    gesamtlaenge = optionSmaller;
    diffusorlaenge = gesamtlaenge;
    wunschlaenge = optionSmaller;
    aussparung = gesamtlaenge + Math.ceil(gesamtlaenge / 1000 + 8);
    profillaenge = gesamtlaenge - 8 - 2 * 3;
    lichtleistenlaenge = profillaenge - 10;
    kabellaenge = lichtleistenlaenge + 100;
    if (lichtleistenlaenge - countBigMasterFLow * lengthBigMasterFlow > lengthSmallMasterFlow) {
        amountSmallMasterFlow = Math.floor((lichtleistenlaenge - countBigMasterFLow * lengthBigMasterFlow) / lengthSmallMasterFlow);
    } else {
    }

    if (
        lichtleistenlaenge - Math.floor(lichtleistenlaenge / lengthBigMasterFlow) * lengthBigMasterFlow >= lengthSmallMasterFlow ||
        lichtleistenlaenge - Math.floor(lichtleistenlaenge / lengthBigMasterFlow) * lengthBigMasterFlow == 0
    ) {
        amountBigMasterFlow = Math.floor(lichtleistenlaenge / lengthBigMasterFlow);
    } else {
        amountBigMasterFlow = Math.floor(lichtleistenlaenge / lengthBigMasterFlow) - 1;
    }

    if (lichtleistenlaenge - amountBigMasterFlow * lengthBigMasterFlow >= lengthSmallMasterFlow) {
        amountSmallMasterFlow = Math.floor((lichtleistenlaenge - amountBigMasterFlow * lengthBigMasterFlow) / lengthSmallMasterFlow);
    } else {
        amountSmallMasterFlow = 0;
    }
    for (let i = 0; i < amountBigMasterFlow; i++) {
        elements.push(master[0]);
    }
    for (let i = 0; i < amountSmallMasterFlow; i++) {
        elements.push(master[1]);
    }
    for (let i = 0; i < amountBigSlaveFlow; i++) {
        elements.push(slave[0]);
    }
    for (let i = 0; i < amountSmallSlaveFlow; i++) {
        elements.push(slave[1]);
    }
    let sidebarValues = displayValues('L1', userLength, aussparung, profillaenge, '', lichtleistenlaenge, diffusorlaenge, gewuenschteLichtleistenlaenge, state);
    let currentLineValues = JSON.parse(state.lineValues) || [];
    currentLineValues.push(JSON.stringify(sidebarValues));
    state.lineValues = JSON.stringify(currentLineValues);
}

function changeElementsForNot(state, elements, lineLengths) {
    let master = lineLengths.filter((item) => item.type === 'master');
    elements.splice(elements.length - 1, 1, master[1]);
    elements.push(master[1]);
}

function displayValues(lineName, userLength, recess, profileLength, luminousSurface, lichtleistenlaenge, diffusorLength, lightLength, state) {
    let lineCount = 1;
    let lineValues = [];
    let installation = current(state).userSelectedInstallation;
    let form = current(state).userSelectedForm;
    let rulesetConstants = current(state).constants;

    // INTERNATIONALIZATION
    const cache = createIntlCache();
    const messages = {
        de: german,
        en: english,
        it: italian,
        fr: french,
    };

    const locale = state.language;

    const intl = createIntl({
        locale: locale,
        messages: messages[locale],
    });

    if (form === 'O' && (lineName === 'L3' || lineName === 'L4')) {
        return lineValues;
    }

    lineValues.push({ id: lineCount++, label: 'Name', value: lineName, suffix: '' });

    // Total length of this line
    // No Corner case: L:I => two ends and therefore two front face endings

    lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.total.title'), value: diffusorLength, suffix: 'mm' });
    lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.diffusor.title'), value: diffusorLength, suffix: 'mm' });
    lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.user.title'), value: diffusorLength, suffix: 'mm' });
    if (false) {
        // DEBUG
        lineValues.push({ id: lineCount++, label: 'Luminous', value: luminousSurface, suffix: 'mm' });
        lineValues.push({ id: lineCount++, label: '[RAW] - Light', value: lightLength, suffix: 'mm' });
    }
    if (installation === 'recessed') {
        lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.recess'), value: recess, suffix: 'mm' });
    }

    // Differentiate between lines without corner, with one corner and 2 corners
    // Standard assumption is that each corner has a light length of 100m -> 1 corner : 100m; 2 corner : 200mm

    lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.profile.title'), value: profileLength, suffix: 'mm' });
    lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.light.title'), value: lichtleistenlaenge, suffix: 'mm' });

    //Hidden bc not necessary
    //lineValues.push({id:lineCount++, label:'Diffusorlänge (<strong>L<sub>Dif</sub></strong>)', value:diffusorLength, suffix:'mm'});

    //adding profileLength to array
    let profileLengths = JSON.parse(state.profileLengths) || [];
    profileLengths.push({ line: lineName, profile: profileLength });
    state.profileLengths = JSON.stringify(profileLengths);

    //adding diffusorLength to array
    let diffusorLengths = JSON.parse(state.diffusorLengths) || [];
    diffusorLengths.push({ line: lineName, length: diffusorLength });
    state.diffusorLengths = JSON.stringify(diffusorLengths);

    return lineValues;
}
