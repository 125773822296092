import React, { useState } from 'react';
import logo from '../assets/logo_white.svg';
import diffusor from '../assets/icons/diffusors/icon-decken-diffusor-no-shadow.svg';
import { Link } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
    selectConfigurationDiffusor,
    selectForm,
    selectInstallation,
    selectPureliteSlimLighting,
    setConfigurationColor,
    setConfigurationDiffusor,
    setConfigurationID,
    setConfigurationLineLengths,
    setConfigurationPower,
    setConfigurationProtection,
    setFeedPointPosition,
    setForm,
    setInstallation,
    setProjectAuthor,
    setProjectBuilding,
    setProjectFloor,
    setProjectLine,
    setProjectName,
    setProjectRoom,
    setPureliteSlimLighting,
    setRulesetID,
} from '../features/configuration/configurationSlice';
import {
    renderDiffusorText,
    renderFormIcon,
    renderInstallationIcon,
    renderInstallationText,
    renderPureliteSlimLightingIcon,
    renderPureliteSlimLightingText,
} from '../services/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    selectRulesetName,
    setDiffusorLengths,
    setDrawingCoordinates,
    setDrawingSimpleCoordinates,
    setDrewSimplified,
    setLineElements,
    setLinesCount,
    setLineValues,
    setLoadingFinished,
    setOpenConfiguratorPage2,
    setOperatingDevices,
    setOverallLumen,
    setOverallWatt,
    setProductPriceChf,
    setProductPriceEur,
    setProfileDrawingCoordinates,
    setProfileDrawingSimpleCoordinates,
    setProfileLengths,
    setProfileSimpleSVG,
    setProfileSVG,
    setResultSimpleSVG,
    setResultSVG,
    setRulesetName,
    setSelectedVisibleLayer,
    setShowDownloadModal,
    setSimplifiedDrawing,
    setUserDiffusors,
    setUserEQP,
    setUserLengths,
    setUserProducts,
    setUserProfiles,
    setUserSelectedColor,
    setUserSelectedDiffusor,
    setUserSelectedForm,
    setUserSelectedInstallation,
    setUserSelectedPendelLength,
    setUserSelectedPower,
    setUserSelectedProtection,
    setUserSelectedPureliteSlimLighting,
    setUserSystemComponents,
    setUserSystemEquipment,
} from '../features/products/productsSlice';
import Button from './Button';
import Translator, { translatedHint } from './Translator';
import { LanguagePopup } from './LanguagePopup';
import { BASE_URL } from '../services/domainService';

export default function Header() {
    const dispatch = useDispatch();

    const [openRestartModal, setOpenRestartModal] = useState(null);

    const currentForm = useSelector(selectForm);
    const currentInstallation = useSelector(selectInstallation);
    const currentDiffusor = useSelector(selectConfigurationDiffusor);
    const currentPureliteSlimLighting = useSelector(selectPureliteSlimLighting);
    const currentRulesetName = useSelector(selectRulesetName);

    return (
        <div className="bg-black text-right py-4 h-[84px]">
            <div className="flex justify-between px-4">
                <div className="text-15 w-header inline-block text-white text-left">
                    <div
                        className={'grid divide-x divide-nav-border-grey items-center ' + (currentRulesetName === 'Channel S' ? 'grid-cols-5' : 'grid-cols-6')}
                    >
                        {currentRulesetName && (
                            <span className="pl-6 py-3 text-xl font-medium">
                                <Link
                                    to="/"
                                    onClick={() => {
                                        // Batch reduces rerenders for each dispatch to a single one (needed till React 17)
                                        batch(() => {
                                            dispatch(setOpenConfiguratorPage2(false));
                                            dispatch(setLineValues(null));
                                            dispatch(setLineElements(null));
                                            dispatch(setProductPriceEur(null));
                                            dispatch(setProductPriceChf(null));
                                            dispatch(setUserProducts(null));

                                            // Coordinates for drawing reset
                                            dispatch(setDrawingCoordinates(null));
                                            dispatch(setProfileDrawingCoordinates(null));
                                            dispatch(setDrawingSimpleCoordinates(null));
                                            dispatch(setProfileDrawingSimpleCoordinates(null));

                                            dispatch(setUserSelectedPower(null));
                                            dispatch(setConfigurationPower(null));
                                            dispatch(setUserSelectedProtection(null));
                                            dispatch(setConfigurationProtection(null));
                                            dispatch(setUserSelectedColor(null));
                                            dispatch(setConfigurationColor(null));
                                            dispatch(setOperatingDevices(null));
                                            dispatch(setOverallLumen(null));
                                            dispatch(setOverallWatt(null));
                                            dispatch(setConfigurationLineLengths(null));
                                            dispatch(setUserLengths(null));
                                            dispatch(setSelectedVisibleLayer('optic'));

                                            dispatch(setLinesCount(null));

                                            // SVGs for installation map reset
                                            dispatch(setResultSVG(null));
                                            dispatch(setProfileSVG(null));
                                            dispatch(setResultSimpleSVG(null));
                                            dispatch(setProfileSimpleSVG(null));

                                            dispatch(setDiffusorLengths(null));
                                            dispatch(setProfileLengths(null));
                                            dispatch(setUserSelectedPendelLength(1500));

                                            dispatch(setUserProducts(null));
                                            dispatch(setUserEQP(null));
                                            dispatch(setUserSystemEquipment(null));
                                            dispatch(setUserSystemComponents(null));
                                            dispatch(setUserProfiles(null));
                                            dispatch(setUserDiffusors(null));

                                            dispatch(setProjectName(null));
                                            dispatch(setProjectAuthor(null));
                                            dispatch(setProjectBuilding(null));
                                            dispatch(setProjectFloor(null));
                                            dispatch(setProjectRoom(null));
                                            dispatch(setProjectLine(null));

                                            dispatch(setFeedPointPosition('start'));

                                            dispatch(setLoadingFinished(false));
                                            dispatch(setShowDownloadModal(false));

                                            dispatch(setSimplifiedDrawing(false));
                                            dispatch(setDrewSimplified(false));
                                        });
                                    }}
                                >
                                    {currentRulesetName}
                                    <Translator id="lights.header.family" />
                                </Link>
                            </span>
                        )}

                        {currentForm && (
                            <span className="pl-6 py-1.5">
                                <img className="inline-block" width={40} height={40} src={renderFormIcon(currentForm)} alt="" />
                                <span>
                                    <Translator id={'intro.form.suffix'} values={{ status: translatedHint, form: currentForm }} />
                                </span>
                            </span>
                        )}

                        {currentPureliteSlimLighting && (
                            <span className="pl-6 py-1.5">
                                <img
                                    className="inline-block w-10 h-10"
                                    width={40}
                                    height={40}
                                    src={renderPureliteSlimLightingIcon(currentPureliteSlimLighting)}
                                    alt=""
                                />
                                <span>
                                    <Translator id={renderPureliteSlimLightingText(currentPureliteSlimLighting)} />
                                </span>
                            </span>
                        )}

                        {currentInstallation && (
                            <span className="pl-6 py-1.5">
                                <img className="inline-block" width={40} height={40} src={renderInstallationIcon(currentInstallation)} alt="" />
                                <span>
                                    <Translator id={renderInstallationText(currentInstallation)} />
                                </span>
                            </span>
                        )}

                        {currentDiffusor && (
                            <span className="pl-6 py-1.5">
                                <img className="inline-block" width={40} height={40} src={diffusor} alt="" />
                                <span>
                                    <Translator id={renderDiffusorText(currentDiffusor)} />
                                </span>
                            </span>
                        )}

                        {currentRulesetName && currentInstallation && currentForm && currentDiffusor && (
                            <span className="pl-6 py-1.5">
                                <Button
                                    onClick={() => {
                                        setOpenRestartModal(true);
                                    }}
                                    className="text-left"
                                >
                                    <Translator id="header.restart.configuration" />
                                    <span className="bg-white inline-block text-center h-6 w-6 rounded-full text-black align-middle ml-2">
                                        <FontAwesomeIcon className="align-sub pt-1" icon={['far', 'rotate-right']} />
                                    </span>
                                </Button>
                            </span>
                        )}
                    </div>
                </div>
                <LanguagePopup />
                <a href="https://www.regent.ch">
                    <img src={logo} width={139} height={29} className="inline-block py-3" title="Regent" alt="Regent Logo" />
                </a>
            </div>

            {openRestartModal && (
                <div className="fixed inset-0 z-40 flex flex-wrap justify-center items-center bg-black bg-opacity-50">
                    <div className="bg-black text-white py-8 px-14 w-108 text-center relative text-xl border border-white">
                        <Button
                            className="absolute top-2 right-3 text-22"
                            onClick={() => {
                                setOpenRestartModal(false);
                            }}
                        >
                            <FontAwesomeIcon icon={['fal', 'times']} />
                        </Button>
                        <p className="font-medium mt-5 mb-10">
                            <Translator id="header.restart.configuration.description" />
                        </p>
                        <Link
                            to="/"
                            className="border border-white py-2 px-4 hover:bg-white hover:text-black transition-all duration-300"
                            onClick={() => {
                                // Batch reduces rerenders for each dispatch to a single one (needed till React 17)
                                batch(() => {
                                    dispatch(setLineValues(null));
                                    dispatch(setLineElements(null));
                                    dispatch(setProductPriceEur(null));
                                    dispatch(setProductPriceChf(null));
                                    dispatch(setUserProducts(null));

                                    // Coordinates for drawing reset
                                    dispatch(setDrawingCoordinates(null));
                                    dispatch(setProfileDrawingCoordinates(null));
                                    dispatch(setDrawingSimpleCoordinates(null));
                                    dispatch(setProfileDrawingSimpleCoordinates(null));
                                    dispatch(setRulesetID(null));
                                    dispatch(setForm(null));
                                    dispatch(setInstallation(null));
                                    dispatch(setConfigurationDiffusor(null));
                                    dispatch(setUserSelectedPower(null));
                                    dispatch(setConfigurationPower(null));
                                    dispatch(setUserSelectedProtection(null));
                                    dispatch(setConfigurationProtection(null));
                                    dispatch(setUserSelectedColor(null));
                                    dispatch(setConfigurationColor(null));
                                    dispatch(setConfigurationID(null));
                                    dispatch(setRulesetName(null));
                                    dispatch(setOpenConfiguratorPage2(false));
                                    dispatch(setOperatingDevices(null));
                                    dispatch(setOverallLumen(null));
                                    dispatch(setOverallWatt(null));
                                    dispatch(setConfigurationLineLengths(null));
                                    dispatch(setUserLengths(null));
                                    dispatch(setUserSelectedInstallation(null));
                                    dispatch(setUserSelectedForm(null));
                                    dispatch(setSelectedVisibleLayer('optic'));
                                    dispatch(setUserSelectedDiffusor(null));
                                    dispatch(setUserSelectedPendelLength(1500));
                                    dispatch(setUserSelectedPureliteSlimLighting(null));
                                    dispatch(setPureliteSlimLighting(null));

                                    dispatch(setLinesCount(null));

                                    // SVGs for installation map reset
                                    dispatch(setResultSVG(null));
                                    dispatch(setProfileSVG(null));
                                    dispatch(setResultSimpleSVG(null));
                                    dispatch(setProfileSimpleSVG(null));

                                    dispatch(setDiffusorLengths(null));
                                    dispatch(setProfileLengths(null));

                                    dispatch(setUserProducts(null));
                                    dispatch(setUserEQP(null));
                                    dispatch(setUserSystemEquipment(null));
                                    dispatch(setUserSystemComponents(null));
                                    dispatch(setUserProfiles(null));
                                    dispatch(setUserDiffusors(null));

                                    dispatch(setProjectName(null));
                                    dispatch(setProjectAuthor(null));
                                    dispatch(setProjectBuilding(null));
                                    dispatch(setProjectFloor(null));
                                    dispatch(setProjectRoom(null));
                                    dispatch(setProjectLine(null));

                                    dispatch(setFeedPointPosition('start'));

                                    // Reset loading modals
                                    dispatch(setLoadingFinished(false));
                                    dispatch(setShowDownloadModal(false));

                                    dispatch(setSimplifiedDrawing(false));
                                    dispatch(setDrewSimplified(false));

                                    setOpenRestartModal(false);
                                });
                            }}
                        >
                            <Translator id="header.restart.configuration.button" />
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
}
