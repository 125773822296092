import React, { useEffect, useRef, useState } from "react";
import Corner from "./Configuration/Corner";
import Line from "./Configuration/Line";
import { ReactSVGPanZoom, TOOL_AUTO, INITIAL_VALUE } from "react-svg-pan-zoom";
import CanvasMiniature from "./CanvasMiniature";
import CanvasToolbar from "./CanvasToolbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
	selecSelectedVisibleLayer,
	selectDrawingCoordinates,
	selectDrawingSimpleCoordinates,
	selectProfileDrawingCoordinates, selectProfileDrawingSimpleCoordinates,
	setProfileSVG, setProfileSimpleSVG,
	setResultSVG, setResultSimpleSVG, RESIZE_FACTOR,
} from "../features/products/productsSlice";
import { selectFeedPointPosition, selectForm, selectInstallation } from "../features/configuration/configurationSlice";
import { handleCenterCanvas, renderLineInput, ROTATION_OF_LINE_ELEMENTS } from "../services/service";
import LaneLabel from "./Configuration/LaneLabel";
import FeedPoint from "./Configuration/FeedPoint";
import { CS_RECESSED_CORNER_LEG, CS_STANDARD_CORNER_LEG } from "../features/products/channels";
import { DEBUG } from "../services/domainService";

export default function DrawingCanvas({ width, height, ...props }) {
	const dispatch = useDispatch();

	const Viewer = useRef(null);
	const groupComplete = useRef(null)
	const groupElectric = useRef(null)
	const groupProfile = useRef(null)
	const groupSimplifiedElectric = useRef(null)
	const groupSimplifiedProfile = useRef(null)

	const [tool, setTool] = useState(TOOL_AUTO);
	const [value, setValue] = useState(INITIAL_VALUE);
	const drawingCoordinates = useSelector(selectDrawingCoordinates);
	const profileDrawingCoordinates = useSelector(selectProfileDrawingCoordinates);

	const drawingSimpleCoordinates = useSelector(selectDrawingSimpleCoordinates);
	const profileDrawingSimpleCoordinates = useSelector(selectProfileDrawingSimpleCoordinates);

	const currentForm = useSelector(selectForm);	// Tracks configuration form type
	const currentLayer = useSelector(selecSelectedVisibleLayer); // Tracks selected layer type simple layers are never shown aside from in the installation map

	const feedPosition = useSelector(selectFeedPointPosition); // Tracks selected position of FeedPoint (Values: start/end or iterator of light element it is attached to)
	const installation = useSelector(selectInstallation); // Tracks installation type (to know corner offset)
	let key = 1;

	let profileLabelStart = { x: 0, y: 0 }; // Point that marks the start position of a line label

	useEffect(() => {
		setTimeout(() => {
			handleCenterCanvas(groupComplete, Viewer, 100, currentForm);
		}, 10);

		if (groupElectric && groupProfile && groupSimplifiedElectric && groupSimplifiedProfile) {
			if (drawingCoordinates) {
				let s = new XMLSerializer();
				groupElectric.current.classList.remove('hidden');
				groupProfile.current.classList.add('hidden');
				dispatch(setResultSVG(s.serializeToString(groupElectric.current)));
			}
			if (profileDrawingCoordinates) {
				let s = new XMLSerializer();
				groupElectric.current.classList.add('hidden');
				groupProfile.current.classList.remove('hidden');
				dispatch(setProfileSVG(s.serializeToString(groupProfile.current)));
			}
			// Save hidden simplified coordinates in store
			if (drawingSimpleCoordinates) {
				let s = new XMLSerializer();
				groupSimplifiedElectric.current.classList.add('hidden');
				dispatch(setResultSimpleSVG(s.serializeToString(groupSimplifiedElectric.current)));
			}
			if (profileDrawingSimpleCoordinates) {
				let s = new XMLSerializer();
				groupSimplifiedProfile.current.classList.add('hidden');
				dispatch(setProfileSimpleSVG(s.serializeToString(groupSimplifiedProfile.current)));
			}

			if (currentLayer === 'optic') {
				groupElectric.current.classList.add('hidden');
				groupProfile.current.classList.remove('hidden');
				groupSimplifiedElectric.current.classList.add('hidden');
				groupSimplifiedProfile.current.classList.add('hidden');
			}
			else if (currentLayer === 'electric') {
				groupElectric.current.classList.remove('hidden');
				groupProfile.current.classList.add('hidden');
				groupSimplifiedElectric.current.classList.add('hidden');
				groupSimplifiedProfile.current.classList.add('hidden');
			}
			// Simplified layers are only shown in the installation map or if the DEBUG constant is set to true and one is selected in the UI
			else if (currentLayer === 'opticSimple') {
				groupElectric.current.classList.add('hidden');
				groupProfile.current.classList.add('hidden');
				groupSimplifiedElectric.current.classList.add('hidden');
				groupSimplifiedProfile.current.classList.remove('hidden');
			}
			else {
				groupElectric.current.classList.add('hidden');
				groupProfile.current.classList.add('hidden');
				groupSimplifiedElectric.current.classList.remove('hidden');
				groupSimplifiedProfile.current.classList.add('hidden');
			}
		}

	}, [drawingCoordinates, profileDrawingCoordinates, drawingSimpleCoordinates, profileDrawingSimpleCoordinates]);

	const _fitSelection = () => handleCenterCanvas(groupComplete, Viewer, 25, currentForm);
	const _zoomInOnViewerCenter = () => {
		Viewer.current.zoomOnViewerCenter(1.1);
		// scaleFactor = Viewer.current.props.scaleFactor;
	};
	const _zoomOutOnViewerCenter = () => {
		Viewer.current.zoomOnViewerCenter(0.9);
		// scaleFactor = Viewer.current.props.scaleFactor;
	};

	let scaleFactor = null;

	/** @type {*} */
	let labelXStart = 0;
	/** @type {*} */
	let labelXEnd = 0;
	/** @type {*} */
	let labelYStart = 0;
	/** @type {*} */
	let labelYEnd = 0;
	/** @type {float} Holds the current line length of the label resized by the RESIZE_FACTOR */
	let labelWidth = 0;
	let labels = []; // Holds all completed lane labels
	let combinedProfileLabels = [];
	/** @type {int} Holds the current length of the label line - value is used to display the length */
	let lineLength = 0;
	let lineNumber = 1;

	return (
		<>
			<ReactSVGPanZoom
				ref={Viewer}
				height={height}
				width={width}
				tool={tool}
				onChangeTool={tool => setTool(tool)}
				value={value}
				onChangeValue={value => setValue(value)}
				background={"transparent"}
				SVGBackground={"transparent"}
				detectAutoPan={false}
				customMiniature={CanvasMiniature}
				customToolbar={CanvasToolbar}
				detectPinchGesture={false}
				disableDoubleClickZoomWithToolAuto={true}
				scaleFactorMin={0.05}
				scaleFactorMax={2}
				preventPanOutside={false}
			>
				<svg width={width} height={height}>
					<defs>
						<pattern id="hatching" patternUnits="userSpaceOnUse" width="5" height="1" patternTransform="rotate(45 0 0)">
							<path d="M 0 0 l 0 4" stroke="#000000" strokeWidth={1} />
						</pattern>
						<pattern id="hatching-with-bg" width="5" height="1" patternUnits="userSpaceOnUse" patternTransform="rotate(45 0 0)">
							<g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
								<rect id="Rectangle" fill="#C1C1C1" x="0" y="0" width="5" height="1" />
								<path d="M 0 0 l 0 4" stroke="#000000" strokeWidth={1} />
							</g>
						</pattern>
					</defs>

					<g type="groupContainer" ref={groupComplete}>
						<g fillOpacity="1" strokeWidth="1" type="groupElectric" ref={groupElectric} className={(currentLayer === 'electric' ? '' : 'hidden')}>
							{drawingCoordinates && drawingCoordinates.map((element, i, arr) =>
								<React.Fragment key={"lineElement" + key++}>
									{/* Cross showing start x/y of each element */}
									{DEBUG && <>
										<line x1={element.x - (10)} y1={element.y - (10)} x2={element.x + (10)} y2={element.y + (10)} stroke="blue" strokeWidth="1" />
										<line x1={element.x - (10)} y1={element.y + (10)} x2={element.x + (10)} y2={element.y - (10)} stroke="orange" strokeWidth="1" />
									</>}

									{element.type === 'corner' &&
										<Corner x={element.x} y={element.y} border={element.border} color={element.color} width={element.w} height={element.h} rotation={element.rotation} elementLength={element.elementLength} cornerOrientation={element.cornerOrientation} />
									}

									{element.type !== 'corner' &&
										<Line x={element.x} y={element.y} border={element.border} color={element.color} width={element.w} height={element.h} rotation={element.rotation} elementLength={element.elementLength} lineNumber={element.lineNumber} />
									}
									{/* Feedpoints */}
									{/* -- At start of each configuration */}
									{i === 0 && currentForm === "I" &&
										<FeedPoint isConfigurator={true} x={element.x} y={element.y} position={"start"} selected={true} />
									}
									{/* -- At end of each configuration */}
									{/* {i === arr.length -1 && currentForm !== 'O' &&
										<FeedPoint isConfigurator={true} x={element.x} y={element.y} position={"end"} width={element.w}/>
									} */}
									{/* -- Between two masters */}
									{/* {i > 0 && i !== arr.length -1 && arr[i-1].type === 'master' && 
										arr[i].type === 'master' &&
										<FeedPoint isConfigurator={true} x={arr[i].x} y={arr[i].y} position={i} width={arr[i].w} rotation={arr[i].rotation}/>
									} */}
									{i > 0 && i !== arr.length - 1 && arr[i - 1].type === 'master' &&
										arr[i].type === 'corner' && arr[i].lineNumber === 1 &&
										<FeedPoint isConfigurator={true} x={arr[i - 1].x} y={arr[i - 1].y} position={i - 1} width={arr[i - 1].w} rotation={arr[i - 1].rotation} selected={true} />
									}

								</React.Fragment>
							)}
							{/* eslint-disable-next-line array-callback-return */}
							{drawingCoordinates && drawingCoordinates.map((data, i, arr) => {
								labelWidth += data.w;
								lineLength += data.elementLength;
								// Additionally add first corner length to first line of all O-configurations (else it would only be added to the left (last) line of rectangle)
								if (currentForm === "O" && i === 0) {
									lineLength += 100;
								}

								if (i === 0 && arr.length > 1) {
									labelXStart = data.x;
									labelXEnd = data.x;
									labelYStart = data.y;
									labelYEnd = data.y;
								}
								// Only add data to labels array if you reached corner or last element in configuration or consisting only of one lonely master
								else if ((data.type === 'corner') || (i === arr.length - 1) || arr.length === 1) {
									if (arr.length === 1) {
										labelXStart = data.x;
										labelXEnd = data.x;
										labelYStart = data.y;
										labelYEnd = data.y;
									}

									// Set end point of the line
									if (data.rotation % 360 === 0) {
										labelXEnd = labelXStart + labelWidth;
										labelYEnd = labelYStart;
									}
									if (data.rotation % 360 === 90) {
										labelXEnd = labelXStart;
										labelYEnd = labelYStart + labelWidth;
									}
									if (data.rotation % 360 === 180) {
										labelXEnd = labelXStart - labelWidth;
										labelYEnd = labelYStart;
									}
									if (data.rotation % 360 === 270) {
										labelXEnd = labelXStart;
										labelYEnd = labelYStart - labelWidth;
									}
									// Add complete data of current line to labels
									labels.push({ xStart: labelXStart, xEnd: labelXEnd, yStart: labelYStart, yEnd: labelYEnd, width: labelWidth, rotation: data.rotation, lineLength: lineLength, lineNumber: lineNumber++ });

									// If now at corner (not at end of configuration)
									if (data.type === 'corner' && i !== arr.length - 1) {
										/*
											linenumber 1 does not need to be fixed
											linenumber 2 has ROTATION_OF_LINE_ELEMENTS index 0, so we need to subtract 2
											linenumber 3 has ROTATION_OF_LINE_ELEMENTS index 1, so we need to subtract 2
											linenumber 4 has ROTATION_OF_LINE_ELEMENTS index 2, so we need to subtract 2
										 */
										/* To correctly put the start of the next line at the correct position
										It is necessary to offset by the thickness of the line (saved as h in each element)
										*/
										if (ROTATION_OF_LINE_ELEMENTS[currentForm][lineNumber - 2] === 'L') {
											if (data.rotation % 360 === 90 || data.rotation % 360 === 270) {
												labelXStart = labelXEnd - data.h;
												labelYStart = labelYEnd;
											}
											else {
												labelXStart = labelXEnd;
												labelYStart = labelYEnd;
												if (ROTATION_OF_LINE_ELEMENTS[currentForm][lineNumber - 3] !== 'L') {
													labelYStart = labelYEnd + data.h;
												}
											}
										}
										else if (ROTATION_OF_LINE_ELEMENTS[currentForm][lineNumber - 2] === 'R' && ROTATION_OF_LINE_ELEMENTS[currentForm][lineNumber - 3] === 'L') {
											labelXStart = labelXEnd - data.h;
											labelYStart = labelYEnd;
										}
										else {
											labelXStart = labelXEnd;
											labelYStart = labelYEnd;
										}
										labelWidth = data.w;
										lineLength = data.elementLength;
									}
									// If at the end of the configuration
									else {
										if (labels.length === 5) {
											labels[0].xStart = labels[4].xStart
											labels[0].width += labels[4].width
											labels[0].lineLength += labels[4].lineLength
											labels.pop();
										}

										return labels.map(element =>
											<React.Fragment key={"collection-line-" + i + key++}>
												{DEBUG && <>
													<line x1={element.xStart - (10)} y1={element.yStart - (10)} x2={element.xStart + (10)} y2={element.yStart + (10)} stroke="red" strokeWidth="4" />
													<line x1={element.xStart - (10)} y1={element.yStart + (10)} x2={element.xStart + (10)} y2={element.yStart - (10)} stroke="green" strokeWidth="4" />
												</>}
												<LaneLabel key={element.lineNumber} scaleFactor={scaleFactor} xStart={element.xStart} xEnd={element.xEnd} yStart={element.yStart} yEnd={element.yEnd} width={element.width} rotation={element.rotation} lineLength={element.lineLength} lineNumber={element.lineNumber} viewType={"electric"} />
											</React.Fragment>
										);
									}
								}
							})}
						</g>

						<g fillOpacity="1" strokeWidth="1" type="groupProfile" ref={groupProfile} className={(currentLayer === 'optic' ? '' : 'hidden')}>
							{profileDrawingCoordinates && profileDrawingCoordinates.map((element, i, arr) =>
								<React.Fragment key={"lineElement" + key++}>
									{/* Cross showing start x/y of each element */}
									{DEBUG && <>
										<line x1={element.x - (10)} y1={element.y - (10)} x2={element.x + (10)} y2={element.y + (10)} stroke="blue" strokeWidth="1" />
										<line x1={element.x - (10)} y1={element.y + (10)} x2={element.x + (10)} y2={element.y - (10)} stroke="orange" strokeWidth="1" />
									</>}
									{element.type === 'corner' &&
										<Corner x={element.x} y={element.y} border={element.border} color={element.border} width={element.w} height={element.h} rotation={element.rotation} elementLength={element.elementLength} cornerOrientation={element.cornerOrientation} />
									}
									{element.type !== 'corner' &&
										<Line actualElementLength={false} article={element.article} x={element.x} y={element.y} border={element.border} color={element.color} width={element.w} height={element.h} rotation={element.rotation} elementLength={element.elementLength} lineNumber={parseInt(element.line.replace('L', ''))} />
									}
								</React.Fragment>
							)}
							{/* eslint-disable-next-line array-callback-return */}
							{/* // Calculate the position of label for each lane line which positions also the lineEdit element with icon */}
							{profileDrawingCoordinates && profileDrawingCoordinates.map((data, i, arr) => {
								let cornerOffset = (installation === "recessed" ? CS_RECESSED_CORNER_LEG : CS_STANDARD_CORNER_LEG) / RESIZE_FACTOR;

								if (i === 0) {
									labels = []; // Clean labels array (else electric labels would still be there)
									labelWidth = 0;
									lineLength = 0; // Holds nummerical label used to display the length
									if (currentForm === "O") {
										lineLength = cornerOffset * RESIZE_FACTOR; // O-Form start with a corner
									}
									// Start line
									profileLabelStart.x = data.x; // Start of the label line X (Set as first element of line)
									profileLabelStart.y = data.y; // Start of the label line Y
								}

								// Accumulate all profiles of one line
								labelWidth += data.w;
								lineLength += data.elementLength;
								let xEnd = 0;
								let yEnd = 0;

								// Push the line labels on corner encounter and at the last element of the configuration
								if (data.type === "corner" || i === arr.length - 1) {
									// End Point of label is just set to the start point of the corner (marks end of line)
									xEnd = data.x;
									yEnd = data.y;

									// Corners push last line immediately to prepare start points of next line with offset based on corner
									if (data.type === "corner") {
										combinedProfileLabels.push({ xStart: profileLabelStart.x, xEnd: xEnd, yStart: profileLabelStart.y, yEnd: yEnd, width: labelWidth, rotation: data.rotation, lineLength: lineLength, lineNumber: parseInt(data.line.replace('L', '')), line: data.line });
									}

									let cornerDirection = ROTATION_OF_LINE_ELEMENTS[currentForm][parseInt(data.line.replace('L', '')) - 1]

									// After corner reset start position to the position after the corner
									// Direction of the corner following the way the line runs.
									// ex. |_ would be a line running down with a left corner at the curve
									// First check if element is last element in configuration then calculate end point manually 
									if (i === arr.length - 1) {
										if (data.rotation % 360 === 0) {// Line Direction: RIGHT
											xEnd = data.x + data.w;
										}
										else if (data.rotation % 360 === 90) { // Line Direction: DOWN
											yEnd = data.y + data.w;
										}
										else if (data.rotation % 360 === 180) { // Line Direction: LEFT
											xEnd = data.x - data.w;
										}
										else if (data.rotation % 360 === 270) { // Line Direction: UP
											yEnd = data.y - data.w;
										}
										// Last element pushes label at the end 
										combinedProfileLabels.push({ xStart: profileLabelStart.x, xEnd: xEnd, yStart: profileLabelStart.y, yEnd: yEnd, width: labelWidth, rotation: data.rotation, lineLength: lineLength, lineNumber: parseInt(data.line.replace('L', '')), line: data.line });

										// Render the profile line label (on reaching the last element)
										return combinedProfileLabels.map(element =>

											<React.Fragment key={"collection-line-" + i + (key++)}>
												{DEBUG && <>
													<line x1={element.xStart - (10)} y1={element.yStart - (10)} x2={element.xStart + (10)} y2={element.yStart + (10)} stroke="red" strokeWidth="4" />
													<line x1={element.xStart - (10)} y1={element.yStart + (10)} x2={element.xStart + (10)} y2={element.yStart - (10)} stroke="green" strokeWidth="4" />
													<line x1={element.xEnd - (10)} y1={element.yEnd - (10)} x2={element.xEnd + (10)} y2={element.yEnd + (10)} stroke="violet" strokeWidth="6" />
													<line x1={element.xEnd - (10)} y1={element.yEnd + (10)} x2={element.xEnd + (10)} y2={element.yEnd - (10)} stroke="yellow" strokeWidth="2" />
												</>}
												<LaneLabel key={"profile-" + element.lineNumber} xStart={element.xStart} xEnd={element.xEnd}
													yStart={element.yStart} yEnd={element.yEnd} width={element.width}
													rotation={element.rotation} lineLength={element.lineLength}
													lineNumber={element.lineNumber} viewType={"profile"} />
											</React.Fragment>
										);
									}
									else if (data.rotation % 360 === 0) {// Line Direction: RIGHT
										profileLabelStart.x = data.x + cornerOffset;
										profileLabelStart.y = data.y + (cornerDirection === "L" ? -cornerOffset : cornerOffset);
									}
									else if (data.rotation % 360 === 90) { // Line Direction: DOWN
										profileLabelStart.x = data.x + (cornerDirection === "L" ? cornerOffset - data.h : -cornerOffset);
										profileLabelStart.y = data.y + cornerOffset - data.h;
									}
									else if (data.rotation % 360 === 180) { // Line Direction: LEFT
										profileLabelStart.x = data.x - cornerOffset;
										profileLabelStart.y = data.y + (cornerDirection === "L" ? cornerOffset : -cornerOffset);
									}
									else if (data.rotation % 360 === 270) { // Line Direction: UP
										profileLabelStart.x = data.x + (cornerDirection === "L" ? -cornerOffset : cornerOffset);
										profileLabelStart.y = data.y - cornerOffset;
									}
									// RESET all lengths to prepare for for next line
									labelWidth = 0;
									lineLength = cornerOffset * RESIZE_FACTOR; // For label value: Keep previous corner length saved because next calculation starts after corner
								}
							})}
							{!drawingCoordinates && !profileDrawingCoordinates &&
								renderLineInput(currentForm)
							}
						</g>

						{/* Simplified coordinates clone */}
						<g fillOpacity="1" strokeWidth="1" type="groupSimplifiedElectric" ref={groupSimplifiedElectric} className={(currentLayer === 'electricSimple' ? '' : 'hidden')}>
							{/* TODO: Set the simplified coordinates in the next line and in the label loop after the TAGS */}
							{drawingSimpleCoordinates && drawingSimpleCoordinates.map((element, i, arr) =>
								<React.Fragment key={"lineElement" + key++}>
									{element.type === 'corner' &&
										<Corner actualElementLength={element.actualLength} article={element.article} x={element.x} y={element.y} border={element.border} color={element.color} width={element.w} height={element.h} rotation={element.rotation} elementLength={element.elementLength} cornerOrientation={element.cornerOrientation} isSimple={true} showArticleNumber={true} />
									}

									{element.type !== 'corner' &&
										<Line actualElementLength={element.actualLength} article={element.article} x={element.x} y={element.y} border={element.border} color={element.color} width={element.w} height={element.h} rotation={element.rotation} elementLength={element.elementLength} lineNumber={element.lineNumber} isSimple={true} />
									}
									{/* Feedpoints : Shows the one selected electricity feedpoint */}
									{((i === 0 && feedPosition === "start") || (i === arr.length - 1 && feedPosition === "end") || feedPosition === i)
										&&
										<FeedPoint isConfigurator={false} x={element.x} y={element.y} width={arr[i].w} position={feedPosition} rotation={element.rotation} />
									}
								</React.Fragment>
							)}
							{/* eslint-disable-next-line array-callback-return */}
							{drawingSimpleCoordinates && drawingSimpleCoordinates.map((data, i, arr) => {
								combinedProfileLabels = [];  // Empty the list to use for simple drawing
								labelWidth += data.w;
								lineLength += data.elementLength;

								if (i === 0) {
									labelXStart = data.x;
									labelXEnd = data.x;
									labelYStart = data.y;
									labelYEnd = data.y;
								}
								else if ((data.type === 'corner') || (i === arr.length - 1)) {

									if (data.rotation % 360 === 0) {
										labelXEnd = labelXStart + labelWidth;
										labelYEnd = labelYStart;
									}
									if (data.rotation % 360 === 90) {
										labelXEnd = labelXStart;
										labelYEnd = labelYStart + labelWidth;
									}
									if (data.rotation % 360 === 180) {
										labelXEnd = labelXStart - labelWidth;
										labelYEnd = labelYStart;
									}
									if (data.rotation % 360 === 270) {
										labelXEnd = labelXStart;
										labelYEnd = labelYStart - labelWidth;
									}

									labels.push({ xStart: labelXStart, xEnd: labelXEnd, yStart: labelYStart, yEnd: labelYEnd, width: labelWidth, rotation: data.rotation, lineLength: lineLength, lineNumber: lineNumber++ });

									if (data.type === 'corner') {
										/*
											linenumber 1 does not need to be fixed
											linenumber 2 has ROTATION_OF_LINE_ELEMENTS index 0, so we need to subtract 2
											linenumber 3 has ROTATION_OF_LINE_ELEMENTS index 1, so we need to subtract 2
											linenumber 4 has ROTATION_OF_LINE_ELEMENTS index 2, so we need to subtract 2
										 */
										if (ROTATION_OF_LINE_ELEMENTS[currentForm][lineNumber - 2] === 'L') {
											if (data.rotation % 360 === 90 || data.rotation % 360 === 270) {
												labelXStart = labelXEnd - 10;
												labelYStart = labelYEnd;
											}
											else {
												labelXStart = labelXEnd;
												labelYStart = labelYEnd;
												if (ROTATION_OF_LINE_ELEMENTS[currentForm][lineNumber - 3] !== 'L') {
													labelYStart = labelYEnd + 10;
												}
											}
										}
										else if (ROTATION_OF_LINE_ELEMENTS[currentForm][lineNumber - 2] === 'R' && ROTATION_OF_LINE_ELEMENTS[currentForm][lineNumber - 3] === 'L') {
											labelXStart = labelXEnd - 10;
											labelYStart = labelYEnd;
										}
										else {
											labelXStart = labelXEnd;
											labelYStart = labelYEnd;
										}
										labelWidth = data.w;
										lineLength = data.elementLength;
									}
									else {
										if (labels.length === 5) {
											labels[0].xStart = labels[4].xStart
											labels[0].width += labels[4].width
											labels[0].lineLength += labels[4].lineLength
											labels.pop();
										}

										// return labels.map(element =>
										// <LaneLabel key={"simple-" +element.lineNumber} xStart={element.xStart} xEnd={element.xEnd} yStart={element.yStart} yEnd={element.yEnd} width={element.width} rotation={element.rotation} lineLength={element.lineLength} lineNumber={element.lineNumber}/>
										// );
									}
								}
							})}
						</g>

						<g fillOpacity="1" strokeWidth="1" type="groupSimplifiedProfile" ref={groupSimplifiedProfile} className={(currentLayer === 'opticSimple' ? '' : 'hidden')}>
							{profileDrawingSimpleCoordinates && profileDrawingSimpleCoordinates.map((element, i, arr) =>
								<React.Fragment key={"lineElement" + key++}>
									{element.type === 'corner' &&
										<Corner article={element.article} x={element.x} y={element.y} border={element.border} color={element.border} width={element.w} height={element.h} rotation={element.rotation} elementLength={element.elementLength} cornerOrientation={element.cornerOrientation} isSimple={true} showArticleNumber={true} />
									}
									{element.type !== 'corner' &&
										<Line actualElementLength={element.actualLength} article={element.article} x={element.x} y={element.y} border={element.border} color={element.color} width={element.w} height={element.h} rotation={element.rotation} elementLength={element.elementLength} isSimple={true} lineNumber={parseInt(element.line.replace('L', ''))} />
									}
								</React.Fragment>
							)}
							{/* eslint-disable-next-line array-callback-return */}
							{profileDrawingSimpleCoordinates && profileDrawingSimpleCoordinates.map((data, i, arr) => {
								combinedProfileLabels = [];  // Empty the list to use for simple drawing
								labelWidth = data.w;
								lineLength = data.elementLength;

								if (i === 0) {
									labelXStart = data.x;
									labelXEnd = data.x;
									labelYStart = data.y;
									labelYEnd = data.y;
								}

								if (data.rotation % 360 === 0) {
									labelXEnd = labelXStart + labelWidth;
									labelYEnd = labelYStart;
								}
								else if (data.rotation % 360 === 90) {
									labelXEnd = labelXStart;
									labelYEnd = labelYStart + labelWidth;
								}
								else if (data.rotation % 360 === 180) {
									labelXEnd = labelXStart - labelWidth;
									labelYEnd = labelYStart;
								}
								else if (data.rotation % 360 === 270) {
									labelXEnd = labelXStart;
									labelYEnd = labelYStart - labelWidth;
								}

								// Only none corners receive profile label
								if (data.type !== "corner") {
									labels.push({ xStart: labelXStart, xEnd: labelXEnd, yStart: labelYStart, yEnd: labelYEnd, width: labelWidth, rotation: data.rotation, lineLength: lineLength, lineNumber: lineNumber++, line: data.line });
								}

								if (ROTATION_OF_LINE_ELEMENTS[currentForm][lineNumber - 2] === 'L') {
									if (data.rotation % 360 === 90 || data.rotation % 360 === 270) {
										labelXStart = labelXEnd - 10;
										labelYStart = labelYEnd;
									}
									else {
										labelXStart = labelXEnd;
										labelYStart = labelYEnd;
										if (ROTATION_OF_LINE_ELEMENTS[currentForm][lineNumber - 3] !== 'L') {
											labelYStart = labelYEnd + 10;
										}
									}
								}
								else if (ROTATION_OF_LINE_ELEMENTS[currentForm][lineNumber - 2] === 'R' && ROTATION_OF_LINE_ELEMENTS[currentForm][lineNumber - 3] === 'L') {
									labelXStart = labelXEnd - 10;
									labelYStart = labelYEnd;
								}
								else {
									labelXStart = labelXEnd;
									labelYStart = labelYEnd;
								}

								if ((i === arr.length - 1)) {
									let xStart = 0;
									let xEnd = 0;
									let yStart = 0;
									let yEnd = 0;
									let rotation = 0;
									let lineLength = 0;
									let width = 0;
									let lastProfileLine = null;

									// eslint-disable-next-line array-callback-return
									labels.map((data, i, arr) => {
										if (data.line) {
											if (!lastProfileLine) {
												xStart = data.xStart;
												xEnd = data.xEnd;
												yStart = data.yStart;
												yEnd = data.yEnd;
												lineLength += data.lineLength;
												width += data.width;
												rotation = data.rotation;
												lastProfileLine = data.line;
											}
											else if (lastProfileLine !== data.line) {
												combinedProfileLabels.push({ xStart: xStart, xEnd: xEnd, yStart: yStart, yEnd: yEnd, width: width, rotation: rotation, lineLength: lineLength, lineNumber: parseInt(lastProfileLine.replace('L', '')), line: lastProfileLine });
												xStart = xEnd;
												xEnd = data.xEnd;
												yStart = yEnd;
												yEnd = data.yEnd;
												lineLength = data.lineLength;
												width = data.width;
												rotation = data.rotation;
												lastProfileLine = data.line;

												//fixing bug where start position is shifted with width
												if (ROTATION_OF_LINE_ELEMENTS[currentForm][parseInt(lastProfileLine.replace('L', '')) - 2] === 'L') {
													if (rotation === 0) {
														xStart -= 10;
													}
													else if (rotation === 270) {
														yStart += 10;
													}
												}
												//fixing bug where start position is shifted with width
												if (ROTATION_OF_LINE_ELEMENTS[currentForm][parseInt(lastProfileLine.replace('L', '')) - 3] === 'L') {
													if (rotation === 0) {
														xStart -= 10;
													}
												}
											}
											else {
												if (xEnd !== data.xEnd) xEnd = data.xEnd;
												if (yEnd !== data.yEnd) yEnd = data.yEnd;
												width += data.width;
												lineLength += data.lineLength;
											}
											if ((i === arr.length - 1)) {
												combinedProfileLabels.push({ xStart: xStart, xEnd: xEnd, yStart: yStart, yEnd: yEnd, width: width, rotation: rotation, lineLength: lineLength, lineNumber: parseInt(lastProfileLine.replace('L', '')), line: lastProfileLine });
											}
										}
									});

									// return combinedProfileLabels.map((element, index) =>
									// 	<LaneLabel key={"profileSimple-" + element.lineNumber + "-" + index } xStart={element.xStart} xEnd={element.xEnd}
									// 				yStart={element.yStart} yEnd={element.yEnd} width={element.width}
									// 				rotation={element.rotation} lineLength={element.lineLength}
									// 				lineNumber={element.lineNumber} dontShowLine={true}/>
									// );
									// Remove length of leg and icon input from installation map
									return <React.Fragment key={"empty-" + i++}></React.Fragment>;
								}
							})}
						</g>

					</g>
				</svg>
			</ReactSVGPanZoom>

			{/* Zoom interface */}
			{drawingCoordinates &&
				<div className="absolute left-7 bottom-5">
					<div className="w-[30px] h-[30px] bg-black text-white flex flex-wrap justify-center items-center mb-[1px] cursor-pointer"
						onClick={() => _zoomInOnViewerCenter()}
					>
						<FontAwesomeIcon icon={["far", "plus"]} />
					</div>
					<div className="w-[30px] h-[30px] bg-black text-white flex flex-wrap justify-center items-center mb-[1px] cursor-pointer"
						onClick={() => _fitSelection()}
					>
						<FontAwesomeIcon icon={["far", "circle-dot"]} />
					</div>
					<div className="w-[30px] h-[30px] bg-black text-white flex flex-wrap justify-center items-center cursor-pointer"
						onClick={() => _zoomOutOnViewerCenter()}
					>
						<FontAwesomeIcon icon={["far", "pipe"]} />
					</div>
					{/*<div className="w-[30px] h-[30px] bg-black text-white flex flex-wrap justify-center mt-[1px] items-center cursor-pointer"
						 onClick={() =>{
							 dispatch(setStartingRotation((currentRotation + 90) % 360));
						 }}
					>
						R
					</div>*/}
				</div>
			}
		</>
	);
}