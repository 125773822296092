export function test2400(v, s, m, e) {
    let value = 1500;
    let slaveCount = 1;
    let masters = {
        1500: 0,
        1350: 0,
        1200: 1,
        750: 0,
    };
    let ends = {
        1500: 0,
        1200: 1,
        750: 0,
    };
    if(JSON.stringify(value) !== JSON.stringify(v)) {
        console.log(
            "#X Value is: " + JSON.stringify(v)+" - Should be:" + JSON.stringify(value)
        )
    }
        if(JSON.stringify(slaveCount) !== JSON.stringify(s)) {
        console.log(
            "#X Slaves are: " + JSON.stringify(s)+" - Should be:" + JSON.stringify(slaveCount)
        )
    }
        if(JSON.stringify(masters) !== JSON.stringify(m)) {
        console.log(
            "#X Master is: " + JSON.stringify(m)+" - Should be:" + JSON.stringify(masters)
        )
    }
        if(JSON.stringify(ends) !== JSON.stringify(e)) {
        console.log(
            "#X Ends is: " + JSON.stringify(e)+" - Should be:" + JSON.stringify(ends)
        )
    }
}

export function test3450(v, s, m, e) {
    let value = 2550;
    let slaveCount = 1;
    let masters = {
        1500: 0,
        1350: 1,
        1200: 0,
        750: 0,
    };
    let ends = {
        1500: 1,
        1200: 0,
        750: 0,
    };
    if(JSON.stringify(value) !== JSON.stringify(v)) {
        console.log(
            "#X Value is: " + JSON.stringify(v)+" - Should be:" + JSON.stringify(value)
        )
    }
        if(JSON.stringify(slaveCount) !== JSON.stringify(s)) {
        console.log(
            "#X Slaves are: " + JSON.stringify(s)+" - Should be:" + JSON.stringify(slaveCount)
        )
    }
        if(JSON.stringify(masters) !== JSON.stringify(m)) {
        console.log(
            "#X Master is: " + JSON.stringify(m)+" - Should be:" + JSON.stringify(masters)
        )
    }
        if(JSON.stringify(ends) !== JSON.stringify(e)) {
        console.log(
            "#X Ends is: " + JSON.stringify(e)+" - Should be:" + JSON.stringify(ends)
        )
    }
}

export function test4350(v, s, m, e) {
    let value = 1350;
    let slaveCount = 2;
    let masters = {
        1500: 0,
        1350: 0,
        1200: 1,
        750: 1,
    };
    let ends = {
        1500: 0,
        1200: 1,
        750: 0,
    };
    if(JSON.stringify(value) !== JSON.stringify(v)) {
        console.log(
            "#X Value is: " + JSON.stringify(v)+" - Should be:" + JSON.stringify(value)
        )
    }
        if(JSON.stringify(slaveCount) !== JSON.stringify(s)) {
        console.log(
            "#X Slaves are: " + JSON.stringify(s)+" - Should be:" + JSON.stringify(slaveCount)
        )
    }
        if(JSON.stringify(masters) !== JSON.stringify(m)) {
        console.log(
            "#X Master is: " + JSON.stringify(m)+" - Should be:" + JSON.stringify(masters)
        )
    }
        if(JSON.stringify(ends) !== JSON.stringify(e)) {
        console.log(
            "#X Ends is: " + JSON.stringify(e)+" - Should be:" + JSON.stringify(ends)
        )
    }
}

export function test9450(v, s, m, e) {
    let value = 150;
    let slaveCount = 5;
    let masters = {
        1500: 2,
        1350: 0,
        1200: 1,
        750: 1,
    };
    let ends = {
        1500: 1,
        1200: 0,
        750: 0,
    };
}