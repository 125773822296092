import Legend from "./Legend";
import DrawingContainer from "./DrawingContainer";
import React from "react";
import canvasSVG from "../assets/canvas-raster-bg.svg";
import LayerSelect from "./LayerSelect";
import {useSelector} from "react-redux";
import {selectDrawingCoordinates} from "../features/products/productsSlice";

export default function InputCanvas({className, ...props}) {
	
	const backgroundStyle = {
		backgroundImage: `url(${canvasSVG}`,
	}
	
	const drawingCoordinates = useSelector(selectDrawingCoordinates);
	
	return (
		<div className={"w-input-screen inline-block relative" + className} style={backgroundStyle}>
			{drawingCoordinates &&
				<>
					<LayerSelect/>
					<Legend/>
				</>
			}
			<DrawingContainer />
		</div>
	);
}